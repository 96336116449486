import cloneDeep from "lodash/cloneDeep"
import { nanoid } from "nanoid"

import { asInt, asFloat, isNumeric } from "./stringUtils"
import {
  DiscountType,
  Contact,
  TaxRateGroup,
  OrganizationItemGroupComponent,
  TransactionLineItemFormInput,
  WorkOrderLineItemDetail,
  ItemType,
  TransactionLineItemDetail,
  TransactionLineItemInput,
  TransactionLineItemDetailFormInput,
  TaxRateAmount,
  TaxSummary,
} from "~/types"

function asCurrency(val: number | string): number {
  return Math.round(asFloat(val) * 100) / 100
}

function calculateLineTotal(quantity?: number | string, unitPrice?: number | string): number {
  const qty = asInt(quantity ?? 0) ?? 0
  const price = unitPrice ? asCurrency(unitPrice) : 0
  return asCurrency(qty * price)
}

function calculateSubtotal(lineItems: TransactionLineItemFormInput[]) {
  const subtotal = lineItems?.reduce((acc, cur) => {
    return acc + calculateLineTotal(cur.quantity ?? 0, cur.unitPrice ?? 0)
  }, 0.0)

  return asCurrency(subtotal)
}

function getDiscountAsPercentage(
  lineItems: TransactionLineItemFormInput[],
  discount: string | number,
  discountType: DiscountType
): number {
  const discountAsFloat = asFloat(discount)

  if (discountType == DiscountType.AMOUNT) {
    if (lineItems?.length == 0) {
      return 0
    }
    const subTotal = Math.max(asFloat(calculateSubtotal(lineItems)), 1)
    return 1.0 - (subTotal - discountAsFloat) / subTotal
  } else {
    return Math.max(asFloat(discountAsFloat / 100), 0) || 0.0
  }
}

function calculateTaxSummary(
  lineItems: TransactionLineItemFormInput[] = [],
  discount: string | number,
  discountType: DiscountType
): TaxSummary {
  const discountPercentage = getDiscountAsPercentage(lineItems, discount, discountType)

  let totalTax = 0
  let taxRateAmounts = [] as TaxRateAmount[]
  lineItems
    ?.filter((li: TransactionLineItemFormInput) => li.quantity && asInt(li.quantity) > 0 && li.taxRateGroup)
    ?.forEach((lineItem: TransactionLineItemFormInput) => {
      lineItem.taxRateGroup?.taxRates?.filter((tr) => tr.rate > 0)?.forEach((tr) => {
        let taxRateAmount = taxRateAmounts.find((t) => t.taxRate?.id === tr.id)
        if (!taxRateAmount) {
          taxRateAmount = {
            amount: 0.0,
            taxRate: tr,
          }
          taxRateAmounts.push(taxRateAmount)
        }

        const lineSubTotal = asInt(lineItem.quantity ?? 0) * asFloat(lineItem.unitPrice ?? 0)
        const discountedLineTotal = lineSubTotal - (lineSubTotal * discountPercentage)
        taxRateAmount.amount += discountedLineTotal * tr.rate // don't round yet
      })
    })

  // now round all the taxRateAmount.amounts to 2 decimal places (convert them to currency)
  taxRateAmounts.forEach((taxRateAmount) => {
    taxRateAmount.amount = asCurrency(taxRateAmount.amount)
    totalTax += taxRateAmount.amount
  })

  // Now round the totalTax to 2 decimal places
  totalTax = asCurrency(totalTax)

  // Convert amounts to currency and sort the by amount in descending order
  taxRateAmounts = taxRateAmounts.sort((a: TaxRateAmount, b: TaxRateAmount) => b.amount - a.amount)

  const taxRateAmountSum = taxRateAmounts.reduce((acc, curr) => asCurrency(acc + curr.amount), 0)
  const error = taxRateAmountSum - totalTax

  if (error != 0) {
    console.error(`***** THERE IS AN ERROR IN THE TAX RATE AMOUNTS - there is a delta of ${error} *****`)
    // const largest = taxRateAmounts[0] // THIS ASSUMES THE LIST IS SORTED BY AMOUNT, DESCENDING
    // largest.amount = asCurrency(largest.amount - error)
  }

  return {
    total: totalTax,
    taxRateAmounts,
  } as TaxSummary
}

function calculateTotal(
  lineItems: TransactionLineItemFormInput[] = [],
  discount: string | number,
  discountType: DiscountType
): number {
  const discountPercentage = getDiscountAsPercentage(lineItems, discount, discountType)
  const subtotal = asFloat(calculateSubtotal(lineItems))
  const totalTax = calculateTaxSummary(lineItems, discount, discountType).total
  return asCurrency(subtotal - subtotal * discountPercentage + totalTax)
}

function updateTransactionLineItem(
  reason: string,
  payload: any,
  lineItems: TransactionLineItemFormInput[],
): TransactionLineItemFormInput[] {
  const lineItem = cloneDeep(payload.lineItem) //clone it because we're mutating it
  const idx = lineItems.findIndex((li) => li.number === lineItem.number)

  if (reason === "CHANGE_ITEM") {
    if (payload.selectedItem.type === ItemType.BUNDLE) {
      const qtyMultiplier = asInt(lineItem.quantity) || 1
      lineItem.lineItemDetails = payload.selectedItem.components.map(
        (c: OrganizationItemGroupComponent, index: number) => ({
          organizationItemId: c.organizationItem.id,
          organizationItem: c.organizationItem,
          quantity: Math.ceil((asInt(c.quantity) ?? 0) * qtyMultiplier),
          unitPrice: c.unitSalePrice,
          number: c.number > 0 ? c.number : index + 1,
        })
      )
      lineItem.showDetails = payload.selectedItem.showComponentsOnTransactions
    } else {
      lineItem.lineItemDetails = null
      lineItem.showDetails = false
    }
    lineItem.organizationItemId = payload.selectedItem.id
    lineItem.organizationItem = payload.selectedItem
    lineItem.unitPrice = payload.selectedItem.unitSalePrice
    lineItem.description = payload.selectedItem.description ?? payload.selectedItem.name ?? ""
  } else if (reason === "CLEAR_ITEM") {
    lineItem.organizationItemId = null
    lineItem.lineItemDetails = null
    lineItem.unitPrice = ""
    lineItem.showDetails = false
    lineItem.organizationItem = {
      id: "",
      code: "",
      name: "",
    }
    lineItem.description = ""
  } else if (reason === "CHANGE_QUANTITY") {
    const qty = asInt(payload.quantity)
    if (lineItem.organizationItem?.type === ItemType.BUNDLE) {
      const originalQty = asInt(lineItem.quantity) || 1
      const multiplier = (qty || 1) / originalQty
      lineItem.lineItemDetails = lineItem.lineItemDetails.map((d: WorkOrderLineItemDetail, index: number) => {
        return {
          ...d,
          number: (d.number && d.number > 0) ? d.number : index + 1,
          quantity: Math.ceil((asInt(d.quantity ?? 0)) * multiplier),
        }
      })
    }
    lineItem.quantity = isNumeric(payload.quantity) ? qty : ''
  } else if (reason === "CHANGE_DESCRIPTION") {
    lineItem.description = payload.description
  } else if (reason === "CHANGE_UNIT_PRICE") {
    lineItem.unitPrice = payload.unitPrice
  } else if (reason === "CHANGE_TAX_RATE") {
    lineItem.taxRateGroup = payload.taxRateGroup
  } else if (reason === "CHANGE_SHOW_DETAILS") {
    lineItem.showDetails = payload.showDetails
  } else if (reason === "CHANGE_COMPONENT_QUANTITY") {
    if (lineItem.organizationItem?.type === ItemType.BUNDLE) {
      const detailIdx = lineItem.lineItemDetails.findIndex(
        (d: TransactionLineItemDetail) => d.organizationItem.id === payload.componentItemId
      )
      const currentLineItemDetail = lineItem.lineItemDetails[detailIdx]
      lineItem.lineItemDetails.splice(detailIdx, 1, {
        ...currentLineItemDetail,
        quantity: Math.max(asInt(payload.quantity) ?? 0, lineItem.quantity),
      })
    }
  } else if (reason === "CHANGE_COMPONENT_PRICE") {
    if (lineItem.organizationItem?.type === ItemType.BUNDLE) {
      const detailIdx = lineItem.lineItemDetails.findIndex(
        (d: TransactionLineItemDetail) => d.organizationItem.id === payload.componentItemId
      )
      const currentLineItemDetail = lineItem.lineItemDetails[detailIdx]
      lineItem.lineItemDetails.splice(detailIdx, 1, {
        ...currentLineItemDetail,
        unitPrice: payload.unitPrice,
      })
    }
  } else if (reason === "DELETE_COMPONENT") {
    if (lineItem.organizationItem?.type === ItemType.BUNDLE) {
      lineItem.lineItemDetails = lineItem.lineItemDetails
        .filter((d: TransactionLineItemDetail) => d.organizationItem.id !== payload.componentItemId)
        .sort((a: TransactionLineItemDetail, b: TransactionLineItemDetail) => a.number - b.number)
        .map((d: TransactionLineItemDetail, index: number) => ({ ...d, number: index + 1 }))
    }
  }

  if (lineItem.organizationItem?.type === ItemType.BUNDLE) {
    lineItem.unitPrice = lineItem.lineItemDetails.reduce((acc: number, curr: TransactionLineItemDetail) => {
      const lineQty = isNumeric(lineItem.quantity) ? lineItem.quantity : 1
      return acc + (curr.quantity / lineQty) * curr.unitPrice
    }, 0)
  }

  const updatedLineItems = [...lineItems] // shallow clone should be fine here
  if (lineItem.organizationItem?.type === ItemType.BUNDLE && !lineItem.lineItemDetails?.length) {
    updatedLineItems.splice(idx, 1) // remove the line item if it's a BUNDLE item with no components
  } else {
    updatedLineItems.splice(idx, 1, lineItem)
  }

  lineItem.total = calculateLineTotal(lineItem.quantity, lineItem.unitPrice)
  lineItem.errors = {
    ...lineItem.errors,
    organizationItemId: !lineItem.organizationItemId ? "required" : null,
    quantity: null,
    unitPrice: !lineItem.unitPrice || asFloat(lineItem.unitPrice) < 0 ? "required" : null,
    taxRateGroup: !lineItem.taxRateGroup?.id ? "required" : null,
  }

  return updatedLineItems
}

/**
 * This function converts an array of TransactionLineItemFormInput to an array of TransactionLineItemInput.
 */
function convertToTransactionLineItemInput(
  lineItemFormInputs: TransactionLineItemFormInput[],
  defaultQtyToZero = true,
  defaultUnitPriceToZero = true): TransactionLineItemInput[] {
  return lineItemFormInputs.map((li: TransactionLineItemFormInput) => {
    const lineItem = {
      id: li.id ? li.id : undefined,
      number: li.number,
      organizationItemId: li.organizationItem?.id,
      description: li.description,
      quantity: li.quantity && isNumeric(li.quantity) ? asInt(li.quantity) : defaultQtyToZero ? 0 : null,
      unitPrice: li.unitPrice && isNumeric(li.unitPrice) ? asFloat(li.unitPrice) : defaultUnitPriceToZero ? 0 : null,
      taxRateGroupId: li.taxRateGroup?.id,
      showDetails: li.showDetails,
      lineItemDetails: li.lineItemDetails?.map((d: TransactionLineItemDetailFormInput, index: number) => ({
        id: d.id,
        number: (d.number && d.number > 0) ? d.number : index + 1,
        organizationItemId: d.organizationItemId ?? d.organizationItem?.id,
        quantity: d.quantity && isNumeric(d.quantity) ? asInt(d.quantity) : defaultQtyToZero ? 0 : null,
        unitPrice: d.unitPrice && isNumeric(d.unitPrice) ? asFloat(d.unitPrice) : defaultUnitPriceToZero ? 0 : null,
      })),
    }
    return lineItem as TransactionLineItemInput
  })
}

function createTransactionLineItemFormInput(
  lineItemNumber: number,
  taxRateGroup?: TaxRateGroup,
  defaultQuantity?: number
): TransactionLineItemFormInput {
  return {
    number: lineItemNumber,
    key: nanoid(),
    organizationItem: {
      id: "",
      code: "",
      name: "",
    },
    description: "",
    quantity: defaultQuantity ? `${defaultQuantity}` : "",
    unitPrice: "",
    showDetails: false,
    taxRateGroup,
    errors: {
      showErrors: false,
    },
  }
}

function sanitizeBillingContact(billingContact: Contact): Contact | null {
  const bc = billingContact
    ? {
      id: billingContact.id ? billingContact.id : undefined,
      firstName: billingContact.firstName,
      lastName: billingContact.lastName,
      phoneNumber: billingContact.phoneNumber,
      email: billingContact.email,
      address: {
        addressString: billingContact.address?.addressString,
        streetNumber: billingContact.address?.streetNumber,
        route: billingContact.address?.route,
        locality: billingContact.address?.locality,
        administrativeAreaLevel1: billingContact.address?.administrativeAreaLevel1,
        administrativeAreaLevel2: billingContact.address?.administrativeAreaLevel2,
        postalCode: billingContact.address?.postalCode,
        country: billingContact.address?.country,
        latitude: billingContact.address?.latitude,
        longitude: billingContact.address?.longitude,
      },
    }
    : null

  return bc
}

export {
  asCurrency,
  calculateLineTotal,
  calculateSubtotal,
  createTransactionLineItemFormInput,
  convertToTransactionLineItemInput,
  getDiscountAsPercentage,
  calculateTaxSummary,
  calculateTotal,
  sanitizeBillingContact,
  updateTransactionLineItem,
}
