/* eslint-disable no-undef */
import { ApolloLink, ApolloClient, HttpLink } from "@apollo/client"
import { InMemoryCache } from "@apollo/client/cache"
import { onError } from "@apollo/client/link/error"
import fetch from "isomorphic-fetch"
import Cookies from "js-cookie"
import { relayStylePagination } from "@apollo/client/utilities"
import DebounceLink from "./DebounceLink"

const DEFAULT_DEBOUNCE_TIMEOUT = 500

const setHeadersLink = new ApolloLink((operation, forward) => {
  const authToken = Cookies.get("authToken")

  const defaultHeaders = {
    "client-name": "Fielder Web (Gatsby)",
    "client-version": `${process.env.GATSBY_APP_VERSION}`,
  }
  if (authToken) {
    operation.setContext({
      headers: {
        ...defaultHeaders,
        authorization: `Bearer ${authToken}`,
      },
    })
  } else {
    operation.setContext({
      headers: defaultHeaders,
    })
  }

  return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ extensions }) => {
      if (extensions?.code) {
        if (
          extensions.code.includes("authentication.token.invalid") ||
          extensions.code.includes("authentication.token.expired")
        ) {
          if ("undefined" !== typeof window) {
            Cookies.remove("authToken") // make sure to clear the cookie in order to prevent an infinite loop
            window.location.assign("/")
          }
        } else if (extensions.code.includes("not-authorized") && window.location.pathname !== "/app/unauthorized") {
          // Note that this destroys any in-memory state held in React Contexts (for example)
          window.location.assign("/app/unauthorized")
        }
      }
    })
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
  }

  forward(operation)
})

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        allCustomers: relayStylePagination(),
        allItems: relayStylePagination(),
        allJobs: relayStylePagination(),
        allOrganizationItems: relayStylePagination(),
        allOrganizations: relayStylePagination(),
        allProductOrders: relayStylePagination(),
        allRoles: relayStylePagination(),
        allEstimateRequests: relayStylePagination(),
        allTasks: relayStylePagination(),
        allUsers: relayStylePagination(),
        allInboxConversations: relayStylePagination(),
      },
    },
    Address: {
      keyFields: ["addressString"],
    },
  },
})

export const client = new ApolloClient({
  link: ApolloLink.from([
    new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
    setHeadersLink,
    errorLink,
    new HttpLink({
      uri: `${process.env.GATSBY_API_SERVER_URL}/graphql`,
      fetch,
      credentials: "same-origin",
    }),
  ]),
  cache,
})
