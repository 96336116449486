export interface Tag {
  id: string
  name: string
  createdAt?: string
  createdBy?: User
  updatedAt?: string
  updatedBy?: User
}

export enum MessageDirection {
  INCOMING = "INCOMING",
  CUSTOMER_REPLY = "CUSTOMER_REPLY", // TODO: remove this
  OUTGOING = "OUTGOING",
}

export interface MessageParticipant {
  id: string
  name: string
  messageAddress: string
  contact?: Contact
  user?: User
}

export enum MessageEventType {
  COMPLAINED = "COMPLAINED",
  DELIVERED = "DELIVERED",
  FAILED = "FAILED",
  OPENED = "OPENED",
  SENT = "SENT",
}

interface MessageEvent {
  id: string
  type: MessageEventType
  messageId: string
  externalEventId?: string
  timestamp: string
  details: string
  messageAddress: string
}

export interface InboxMessage {
  id: string
  sender: MessageParticipant
  toRecipients?: MessageParticipant[]
  ccRecipients?: MessageParticipant[]
  subject?: string | null
  dateSent: string
  bodyHtml?: string | null
  bodyPlain?: string
  channel: MessageChannel
  direction: MessageDirection
  events?: MessageEvent[]
  attachments?: Attachment[]
}

export enum InboxConversationStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}

export interface InboxConversation {
  id: string
  isRead: boolean
  tags?: Tag[]
  job?: Job
  customer?: Customer
  latestMessage: InboxMessage
  messages?: InboxMessage[]
  status: InboxConversationStatus
}

export enum SearchResultType {
  JOB = "JOB",
  CUSTOMER = "CUSTOMER",
  ESTIMATE = "ESTIMATE",
  INVOICE = "INVOICE",
  TASK = "TASK",
  ITEM = "ITEM",
  PRODUCT_ORDER = "PRODUCT_ORDER",
}

export enum MessageChannel {
  EMAIL = "EMAIL",
  SMS = "SMS",
  INBOX = "INBOX",
}

export enum NotificationCategory {
  GENERAL = "GENERAL",
  INVENTORY = "INVENTORY",
  JOBS = "JOBS",
  ORDERS = "ORDERS",
}

export enum DefaultNotificationType {
  MENTIONS_LEVEL_1 = "MENTIONS_LEVEL_1",
  MENTIONS_LEVEL_2 = "MENTIONS_LEVEL_2",
  FIELDER_UPDATES_LEVEL_1 = "FIELDER_UPDATES_LEVEL_1",
  FIELDER_UPDATES_LEVEL_2 = "FIELDER_UPDATES_LEVEL_2",
  ESTIMATE_REQUEST_SUBMITTED = "ESTIMATE_REQUEST_SUBMITTED",
  JOB_ASSIGNMENT_CREATED = "JOB_ASSIGNMENT_CREATED",
  JOB_ASSIGNMENT_UPDATED = "JOB_ASSIGNMENT_UPDATED",
  QOH_AT_REORDER_POINT = "QOH_AT_REORDER_POINT",
  QTY_COMMITTED_EXCEEDS_QOH = "QTY_COMMITTED_EXCEEDS_QOH",
  WHOLESALE_PRICE_CHANGED = "WHOLESALE_PRICE_CHANGED",
  PRODUCT_ORDER_SUBMITTED_LEVEL1 = "PRODUCT_ORDER_SUBMITTED_LEVEL1",
  PRODUCT_ORDER_SUBMITTED_LEVEL2 = "PRODUCT_ORDER_SUBMITTED_LEVEL2",
  PRODUCT_ORDER_STATUS_CHANGED_LEVEL1 = "PRODUCT_ORDER_STATUS_CHANGED_LEVEL1",
  PRODUCT_ORDER_STATUS_CHANGED_LEVEL2 = "PRODUCT_ORDER_STATUS_CHANGED_LEVEL2",
}

export interface NotificationType {
  id: string
  notificationCategory: NotificationCategory
  name: string
  allowEmail: boolean
  allowSms: boolean
  organizationLevel: number
}

export interface NotificationSetting {
  id: string
  notificationType: NotificationType
  emailEnabled: boolean
  smsEnabled: boolean
  inboxEnabled: boolean
}

export interface EditNotificationSettingInput {
  notificationTypeId: string
  emailEnabled: boolean
  smsEnabled: boolean
  inboxEnabled: boolean
}

export interface Notification {
  id: string
  type: NotificationType
  channel: MessageChannel
  user: User
  dateRead?: string | null
  title: string
  message: string
  callToActionText?: string | null
  callToActionUri?: string | null
  createdAt: string
}

export interface Searchable {
  id: string
}

export interface SearchResult {
  id?: string
  type: SearchResultType
  record: Job | Customer | Estimate | Invoice | Task | OrganizationItem | ProductOrder
}

export interface Permission {
  id?: string
  name?: string
  group: PermissionGroup
}

export enum PermissionGroup {
  ANY = "ANY",
  CHECKLIST = "CHECKLIST",
  CUSTOMER = "CUSTOMER",
  ITEM = "ITEM",
  INVOICE = "INVOICE",
  JOB = "JOB",
  JOB_ASSIGNMENT = "JOB_ASSIGNMENT",
  JOB_CATEGORY = "JOB_CATEGORY",
  JOB_STATUS = "JOB_STATUS",
  JOB_WORKFLOW = "JOB_WORKFLOW",
  ORGANIZATION = "ORGANIZATION",
  ORGANIZATION_ITEM = "ORGANIZATION_ITEM",
  ORGANIZATION_PLUGIN = "ORGANIZATION_PLUGIN",
  PAYMENT = "PAYMENT",
  ESTIMATE = "ESTIMATE",
  ESTIMATE_REQUEST = "ESTIMATE_REQUEST",
  ROLE_MANAGEMENT = "ROLE_MANAGEMENT",
  TASK = "TASK",
  USER = "USER",
  PRODUCT_ORDER = "PRODUCT_ORDER",
  REGION = "REGION",
  TAX_RATE_GROUP = "TAX_RATE_GROUP",
  WORK_ORDER = "WORK_ORDER",
}

export interface Role {
  id: string
  name: string
  description?: string
  isStandard?: boolean
  permissions?: Permission[]
}

export interface User {
  id: string
  userHash?: string
  email?: string
  firstName?: string
  lastName?: string
  jobTitle?: string
  mobilePhoneNumber?: string
  languageCode: LanguageCode
  status?: UserStatus
  roles?: Role[]
  locations?: UserLocation[]
  organization: Organization
  isOrganizationOwner?: boolean
  lastLogin?: string
  lastLocation?: UserLocation
  isArchived?: boolean
  createdAt?: string
  availableNotificationTypes?: NotificationType[]
  notificationSettings?: NotificationSetting[]
  notifications?: Connection<Notification>
  unreadNotificationCount?: number
  avatar?: Attachment
}

export enum EventType {
  JOB_CREATED = "JOB_CREATED",
  JOB_UPDATED = "JOB_UPDATED",
  JOB_ARCHIVED = "JOB_ARCHIVED",

  JOB_NOTE_CREATED = "JOB_NOTE_CREATED",
  JOB_NOTE_UPDATED = "JOB_NOTE_UPDATED",
  JOB_NOTE_DELETED = "JOB_NOTE_DELETED",

  JOB_TASK_CREATED = "JOB_TASK_CREATED",
  JOB_TASK_UPDATED = "JOB_TASK_UPDATED",

  JOB_ATTACHMENT_CREATED = "JOB_ATTACHMENT_CREATED",
  JOB_ATTACHMENT_DELETED = "JOB_ATTACHMENT_DELETED",

  JOB_ASSIGNMENT_CREATED = "JOB_ASSIGNMENT_CREATED",
  JOB_ASSIGNMENT_UPDATED = "JOB_ASSIGNMENT_UPDATED",
  JOB_ASSIGNMENT_DELETED = "JOB_ASSIGNMENT_DELETED",

  INVOICE_CREATED = "INVOICE_CREATED",
  INVOICE_UPDATED = "INVOICE_UPDATED",
  INVOICE_SENT = "INVOICE_SENT",
  INVOICE_ARCHIVED = "INVOICE_ARCHIVED",
  INVOICE_PAID = "INVOICE_PAID",

  ESTIMATE_CREATED = "ESTIMATE_CREATED",
  ESTIMATE_UPDATED = "ESTIMATE_UPDATED",
  ESTIMATE_SENT = "ESTIMATE_SENT",
  ESTIMATE_ACCEPTED = "ESTIMATE_ACCEPTED",
  ESTIMATE_ARCHIVED = "ESTIMATE_ARCHIVED",

  SMS_SENT = "SMS_SENT",
  EMAIL_SENT = "EMAIL_SENT",
  EMAIL_RECEIVED = "EMAIL_RECEIVED",

  CHECKLIST_CREATED = "CHECKLIST_CREATED",
  CHECKLIST_UPDATED = "CHECKLIST_UPDATED",
  CHECKLIST_LINE_ITEM_NOTE_CREATED = "CHECKLIST_LINE_ITEM_NOTE_CREATED",

  JOB_WORKFLOW_STEP_EVENT_EMAIL_NOTIFICATION_SENT = "JOB_WORKFLOW_STEP_EVENT_EMAIL_NOTIFICATION_SENT",
  JOB_WORKFLOW_STEP_EVENT_SMS_NOTIFICATION_SENT = "JOB_WORKFLOW_STEP_EVENT_SMS_NOTIFICATION_SENT",

  WORK_ORDER_CREATED = "WORK_ORDER_CREATED",
  WORK_ORDER_UPDATED = "WORK_ORDER_UPDATED",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export interface Edge<T> {
  cursor: string
  node: T
}
export interface Connection<T> {
  edges: Edge<T>[]
}

export interface AuditEntryTag {
  id: string
  type: string
}

export interface EventData {
  eventType: EventType
}

export interface AuditEntry {
  id: string
  tags: AuditEntryTag[]
  eventType: string
  eventData: EventData
  createdAt: string
  createdBy: User
  updatedAt: string
  updatedBy: User
}

export enum UserStatus {
  UNVERIFIED = "UNVERIFIED",
  ACTIVE = "ACTIVE",
  LOCKED = "LOCKED",
  LOCKED_NEEDS_PASSWORD_CHANGE = "LOCKED_NEEDS_PASSWORD_CHANGE",
  DEACTIVATED = "DEACTIVATED",
}

export interface UserLocation {
  id: string
  latitude: number
  longitude: number
  createdAt: string
}

export interface FielderSubscriptionBillingDetails {
  name: string
  address: Address
}

export interface FielderSubscriptionPaymentCard {
  brand: string
  last4: string
  expMonth: number
  expYear: number
}

export interface FielderSubscriptionPaymentMethod {
  id: string
  billingDetails: FielderSubscriptionBillingDetails
  card: FielderSubscriptionPaymentCard
}

export interface FielderSubscriptionPaymentIntent {
  id: string
  status: string
  clientSecret: string
  currency: string
}

export interface FielderSubscriptionInvoice {
  id: string
  number: string
  paymentIntent: FielderSubscriptionPaymentIntent
}

export interface FielderSubscription {
  id: string
  status: FielderSubscriptionStatus
  pricePoint: FielderSubscriptionPricePoint
  paymentMethod: FielderSubscriptionPaymentMethod
  startDate: string
  endDate: string
  taxPercent: number
  organization: Organization
  latestInvoice: FielderSubscriptionInvoice
  customerId: string
}

export enum FielderSubscriptionStatus {
  ACTIVE = "ACTIVE",
  PAST_DUE = "PAST_DUE",
  UNPAID = "UNPAID",
  CANCELED = "CANCELED",
  INCOMPLETE = "INCOMPLETE",
  INCOMPLETE_EXPIRED = "INCOMPLETE_EXPIRED",
  TRIALING = "TRIALING",
}

export enum PluginProviderCategory {
  ACCOUNTING = "ACCOUNTING",
  OTHER = "OTHER",
}

export interface PluginProvider {
  id: string
  name: string
  category: PluginProviderCategory
}

export enum CoaAccountType {
  ASSET = "ASSET",
  EXPENSE = "EXPENSE",
  REVENUE = "REVENUE",
}

export interface CoaAccount {
  id: string
  code: string
  name: string
  type: CoaAccountType
}

export interface OrganizationPlugin {
  id: string
  organization: Organization
  pluginProvider: PluginProvider
  supportsInventoryTracking: boolean
  tenantId: string
  defaultExpenseAccount: CoaAccount
  defaultRevenueAccount: CoaAccount
  defaultAssetAccount: CoaAccount
  chartOfAccounts?: CoaAccount[]
  logoPath?: string
  createdAt: string
  createdBy: User
  updatedAt: string
  updatedBy: User
}

interface OrganizationDefaultTransactionSettings {
  showLineItems: boolean;
  showLineItemQuantity: boolean;
  showLineItemUnitPrice: boolean;
  showLineItemSubtotal: boolean;
  showLineItemCode: boolean;
  showLineItemName: boolean;
  showLineItemDescription: boolean;
  showLineItemBundleComponents: boolean;
  showLineItemBundleComponentUnitPrice: boolean;
  showLineItemBundleComponentQuantity: boolean;
  showFooter: boolean;
  showFooterSubtotal: boolean;
  showFooterDiscount: boolean;
  showFooterTaxes: boolean;
  showFooterTotal: boolean;
}

export interface OrganizationDefaultEstimateSettings extends OrganizationDefaultTransactionSettings {
  expirationDays: number;
  showExpirationDate: boolean;
  showContract: boolean;
}

export interface OrganizationDefaultInvoiceSettings extends OrganizationDefaultTransactionSettings {
  netTerms: NetTerms;
  showDueDate: boolean;
}

export interface OrganizationDefaultPricingStrategySettings {
  defaultPricingStrategy: PricingStrategy;
  defaultUnitSalePriceMarkup: number;
}

export interface Organization {
  id: string
  tradeName: string
  legalName: string
  level: number
  locale?: string
  region?: Region
  timeZone: string
  currencyCode: CurrencyCode
  languageCode: LanguageCode
  allowBundleModsOnTxns?: boolean
  allowJobAssignmentOverlap?: boolean
  bccJobEmailToOrganizationEmail?: boolean
  status: OrganizationStatus
  email?: string
  phoneNumber?: string
  websiteURL?: string
  address?: Address
  owner: User
  accountNumber?: string
  accountManager?: User
  fielderSubscription: FielderSubscription
  plugins?: OrganizationPlugin[]
  defaultEstimateSettings?: OrganizationDefaultEstimateSettings
  defaultInvoiceSettings?: OrganizationDefaultInvoiceSettings
  defaultPricingStrategySettings?: OrganizationDefaultPricingStrategySettings
  createdAt: string
  createdBy: User
  updatedAt: string
  updatedBy: User
}

export enum OrganizationStatus {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  ARCHIVED = "ARCHIVED",
}

export interface Address {
  addressString?: string
  latitude?: number
  longitude?: number
  streetNumber?: string
  route?: string
  locality?: string
  administrativeAreaLevel1?: string
  administrativeAreaLevel2?: string
  postalCode?: string
  country?: string
}

export interface JobCategory {
  id: string
  name: string
  isArchived?: boolean
  createdAt?: string
  createdBy?: User
}

export interface Region {
  id: string
  name: string
  code: string
  currencyCode: CurrencyCode
  languageCode: LanguageCode
  isArchived?: boolean
}

export enum LanguageCode {
  ENGLISH_CANADA = "ENGLISH_CANADA",
  ENGLISH_USA = "ENGLISH_USA",
  ENGLISH_UK = "ENGLISH_UK",
  FRENCH_CANADA = "FRENCH_CANADA",
  FRENCH_FRANCE = "FRENCH_FRANCE",
  GERMAN_GERMANY = "GERMAN_GERMANY",
  SPANISH_USA = "SPANISH_USA",
  DUTCH_BELGIUM = "DUTCH_BELGIUM",
  DUTCH_NETHERLANDS = "DUTCH_NETHERLANDS",
}

export enum CurrencyCode {
  USD = "USD",
  CAD = "CAD",
  EUR = "EUR",
  GBP = "GBP",
}

export interface Contact {
  id?: string
  firstName?: string
  lastName?: string
  email?: string
  address?: Address
  jobTitle?: string
  phoneNumber?: string
  canBeBillingContact?: boolean
  canBeJobSiteContact?: boolean
}

export interface CustomerFlag {
  id: string
  name: string
  description: string
  colorCode: string
}

export interface Customer {
  id?: string
  name?: string
  type?: CustomerType
  address?: Address
  languageCode?: LanguageCode
  isArchived?: boolean
  email?: string
  notes?: CustomerNote[]
  flags?: CustomerFlag[]
  attachments?: Attachment[]
  organization?: Organization
  phoneNumber?: string
  contacts?: Array<Contact>
  numberOfJobs?: number
  totalSales?: number
  createdAt?: string
  createdBy?: User
}

export enum CustomerType {
  INDIVIDUAL = "INDIVIDUAL",
  BUSINESS = "BUSINESS",
}

export interface JobStatus {
  id?: string
  name?: string
  description?: string
  isArchived?: boolean
  colorCode?: string
  lightColor?: string
  mediumColor?: string
  darkColor?: string
  position?: number
  category?: string
  parentStatus?: JobStatus
}

export interface JobWorkflowStepTransition {
  id?: string
  name?: string
  source?: JobWorkflowStep
  destination?: JobWorkflowStep
  createdAt?: string
  createdBy?: User
  updatedAt?: string
  updatedBy?: User
}

export interface Job {
  id: string
  workflow?: JobWorkflow
  workflowStep?: JobWorkflowStep
  attachments?: Attachment[]
  categories: JobCategory[]
  customer?: Customer
  description?: string
  desiredCompletionDate?: string
  organization: Organization
  address: Address
  siteContact?: Contact
  number: number
  emailAddress?: string
  boardPosition?: number
  notes?: JobNote[]
  estimates?: Estimate[]
  invoices?: Invoice[]
  assignments?: JobAssignment[]
  checklist?: Checklist
  isArchived?: boolean
  createdAt: string
}

export interface Checklist {
  id?: string
  job?: Job
  type?: ChecklistType
  diagram?: string
  installers?: User[]
  installationEquipment?: string
  installationDate?: string
  projectDescription?: string
  file?: Attachment
  lineItems?: ChecklistLineItem[]
  createdAt?: string
  createdBy?: User
}

export enum ChecklistType {
  REGULAR = "REGULAR",
  MOBILE_HOME = "MOBILE_HOME",
}

export interface ChecklistLineItem {
  id?: string
  diagramId: string
  number: number
  requiredCompressionCapacity: number
  achievedCompressionCapacity: number
  requiredTorque: number
  achievedTorque: number
  pileUsed: string
  helixUsed: string
  totalPileLength: number
  cutOffElevation: number
  pileInclination: number
  achievedTensionCapacity: number
  safetyFactor?: number
  notes?: ChecklistLineItemNote[]
  createdAt?: string
  createdBy?: User
}

export interface ChecklistLineItemNote {
  id: string
  text: string
  createdAt: string
  createdBy: User
  updatedAt: string
  updatedBy: User
}

export interface ChecklistTemplate {
  id?: string
  name: string
  type: ChecklistType
  diagram?: string
  installers?: User[]
  installationEquipment?: string
  installationDate?: string
  projectDescription?: string
  file?: Attachment
  lineItems?: ChecklistTemplateLineItem[]
  createdAt?: string
  createdBy?: User
}

export interface ChecklistTemplateLineItem {
  id?: string
  diagramId: string
  number: number
  requiredCompressionCapacity: number
  achievedCompressionCapacity: number
  requiredTorque: number
  achievedTorque: number
  pileUsed: string
  helixUsed: string
  totalPileLength: number
  cutOffElevation: number
  pileInclination: number
  achievedTensionCapacity: number
  safetyFactor?: number
  notes?: ChecklistLineItemNote[]
  createdAt?: string
  createdBy?: User
}

export interface TransactionCustomerVisibility {
  __typename?: string;
  showLineItems: boolean;
  showLineItemQuantity: boolean;
  showLineItemUnitPrice: boolean;
  showLineItemSubtotal: boolean;
  showLineItemCode: boolean;
  showLineItemName: boolean;
  showLineItemDescription: boolean;
  showLineItemBundleComponents: boolean;
  showLineItemBundleComponentUnitPrice: boolean;
  showLineItemBundleComponentQuantity: boolean;
  showFooter: boolean;
  showFooterSubtotal: boolean;
  showFooterDiscount: boolean;
  showFooterTaxes: boolean;
  showFooterTotal: boolean;
}

export interface InvoiceCustomerVisibility extends TransactionCustomerVisibility {
  showDueDate: boolean;
}

export interface EstimateCustomerVisibility extends TransactionCustomerVisibility {
  showExpirationDate: boolean;
  showContract: boolean;
}

export interface Estimate extends Transaction<EstimateLineItem> {
  job: Job
  status: EstimateStatus
  expirationDate?: string
  file?: Attachment
  footerTitle?: string
  footerBody?: string
  contractJson?: string
  documentUrl?: string
  isArchived: boolean
  customerVisibility?: EstimateCustomerVisibility
}

export interface EstimateLineItem extends TransactionLineItem<EstimateLineItemDetail> {
}

export interface EstimateLineItemDetail extends TransactionLineItemDetail {
  createdAt?: string
  createdBy?: User
}

export interface Invoice extends Transaction<InvoiceLineItem> {
  job: Job
  status?: InvoiceStatus
  issuedDate?: string
  netTerms?: NetTerms
  dueDate?: string
  customerNote?: string
  balanceDue?: number
  dateSentToCustomer?: string
  dateSentToPluginProvider?: string
  file?: Attachment
  documentUrl?: string
  isArchived: boolean
  externalId?: string
  pluginProviderUrl?: string
  customerVisibility?: InvoiceCustomerVisibility
}

export interface InvoiceLineItem extends TransactionLineItem<InvoiceLineItemDetail> {
}

export interface InvoiceLineItemDetail extends TransactionLineItemDetail {
  createdAt?: string
  createdBy?: User
}

export interface Discount {
  value?: number
  type?: DiscountType
}

export enum DiscountType {
  PERCENTAGE = "PERCENTAGE",
  AMOUNT = "AMOUNT",
}

export enum EstimateStatus {
  PENDING_SUBMISSION = "PENDING_SUBMISSION",
  PENDING_ACCEPTANCE = "PENDING_ACCEPTANCE",
  CHANGE_REQUESTED = "CHANGE_REQUESTED",
  ACCEPTED = "ACCEPTED",
  ARCHIVED = "ARCHIVED",
}

export enum InvoiceStatus {
  DRAFT = "DRAFT",
  APPROVED = "APPROVED",
  VOIDED = "VOIDED",
  ARCHIVED = "ARCHIVED",
}

export enum NetTerms {
  DUE_ON_RECEIPT = "DUE_ON_RECEIPT",
  NET_10 = "NET_10",
  NET_15 = "NET_15",
  NET_30 = "NET_30",
  NET_45 = "NET_45",
  NET_60 = "NET_60",
  NET_90 = "NET_90",
  CUSTOM = "CUSTOM",
}

export interface TaxSummary {
  total: number
  taxRateAmounts: Array<TaxRateAmount>
}

export interface TaxRateAmount {
  taxRate: TaxRate
  amount: number
}

export interface TaxRateGroup {
  id?: string
  name?: string
  isArchived?: boolean
  isLocked?: boolean
  taxRates: TaxRate[]
  totalTaxRate?: number
  createdAt?: string
  createdBy?: User
}

export interface TaxRate {
  id: string
  name: string
  rate: number
  createdAt?: string
  createdBy?: User
}

export enum AttachmentType {
  EMAIL = "EMAIL",
  ITEM = "ITEM",
  JOB = "JOB",
  CUSTOMER = "CUSTOMER",
  ESTIMATE_REQUEST = "ESTIMATE_REQUEST",
  ESTIMATE = "ESTIMATE",
  ORGANIZATION = "ORGANIZATION",
  INVOICE = "INVOICE",
  CHECKLIST = "CHECKLIST",
  USER = "USER",
}

export enum AttachmentPreviewSize {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
}

export enum AttachmentStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
}

export interface Attachment {
  id: string
  type?: AttachmentType
  objectName?: string
  contentType?: string
  isArchived?: boolean
  isSystemGenerated?: boolean
  name?: string
  previews?: Attachment[]
  previewSize?: AttachmentPreviewSize
  status?: AttachmentStatus
  statusMessage?: string
  signedUrl?: string
  createdAt?: string
}

export interface JobWorkflow {
  id?: string
  parentWorkflow?: JobWorkflow
  organization?: Organization
  name?: string
  description?: string
  steps?: JobWorkflowStep[]
  availableStatuses?: JobStatus[]
  isPublished?: boolean
  isArchived?: boolean
}

export interface JobWorkflowStep {
  id: string
  parentWorkflowStep?: JobWorkflowStep
  jobStatus?: JobStatus
  isInitialStep?: boolean
  isDeletable?: boolean
  responsibleUsers?: User[]
  responsibleRole?: Role
  boardPosition?: number
  events?: JobWorkflowStepEvent[]
  destinationTransitions?: JobWorkflowStepTransition[]
  sourceTransitions?: JobWorkflowStepTransition[]
  jobs?: Job[]
  createdAt?: string
}

export interface JobWorkflowStepEvent {
  id?: string
  displayKey?: string
  name?: string
  description?: string
  isArchived?: boolean
  createdAt?: string
}

export interface Note {
  id: string
  text: string
  createdAt: string
  createdBy: User
  updatedAt: string
  updatedBy: User
}

export interface JobNote {
  id: string
  text: string
  createdAt: string
  createdBy: User
  updatedAt: string
  updatedBy: User
}

export interface CustomerNote {
  id: string
  text: string
  createdAt: string
  createdBy: User
  updatedAt: string
  updatedBy: User
}

export interface JobAssignment {
  id: string
  job: Job
  workOrder?: WorkOrder
  assignees: User[]
  status: JobAssignmentStatus
  isLocked: boolean
  startDate: string
  endDate: string
  manifest?: Manifest
  createdAt: string
  createdBy: User
}

export enum TaskStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

export interface Task {
  id: string
  description?: string
  dueDate?: string
  assignee: User
  job?: Job
  status: TaskStatus
  notes?: string
  createdAt: string
  createdBy: User
  updatedAt: string
  updatedBy: User
}

export interface Manifest {
  lineItems?: Array<ManifestLineItem>
}

export interface ManifestLineItem {
  organizationItem?: OrganizationItem
  quantity?: number
}

export enum JobAssignmentStatus {
  TENTATIVE = "TENTATIVE",
  COMPLETED = "COMPLETED",
  CONFIRMED = "CONFIRMED",
}

export interface WorkOrder extends Transaction<WorkOrderLineItem> {
  job: Job
  jobAssignment?: JobAssignment
  file?: Attachment
  isArchived?: boolean
}

export enum WorkOrderStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  ARCHIVED = "ARCHIVED",
}

export interface WorkOrderLineItem extends TransactionLineItem<WorkOrderLineItemDetail> {
}

export interface WorkOrderLineItemDetail extends TransactionLineItemDetail {
  createdAt?: string
  createdBy?: User
}

export interface OrganizationItem extends Item {
  components?: OrganizationItemGroupComponent[]
  showComponentsOnTransactions?: boolean
  isTracked?: boolean
  qtyOnHand?: number
  orderedQty?: number
  committedQty?: number
  reorderPoint?: number
  reorderQty?: number
  pricingStrategy?: PricingStrategy
  unitSalePrice?: number
  unitSalePriceMarkup?: number
  averagePurchaseCost?: number
  isTopLevel?: boolean
  isAvailable?: boolean
  isActive?: boolean
  isArchived?: boolean
  createdAt?: string
  createdBy?: User
}

export interface OrganizationItemList {
  id: string
  name: string
  organizationItems: OrganizationItem[]
  createdAt?: string
  createdBy?: User
  updatedAt?: string
  updatedBy?: User
}

export enum ItemType {
  PRODUCT = "PRODUCT",
  SERVICE = "SERVICE",
  BUNDLE = "BUNDLE",
}

export enum StockType {
  STOCKED = "STOCKED",
  NON_STOCKED = "NON_STOCKED",
  SPECIAL_ORDER = "SPECIAL_ORDER",
}

export enum PricingStrategy {
  DYNAMIC = "DYNAMIC",
  FIXED = "FIXED",
  MSRP = "MSRP",
}

export interface OrganizationItemGroupComponent {
  id: string
  number: number
  organizationItem: OrganizationItem
  quantity: number
  enableUnitSalePriceOverride: boolean
  unitSalePrice: number
  createdAt?: string
  createdBy?: User
}

export interface FielderSubscriptionProduct {
  id: string
  name: string
  nameKey: string
  monthlyJobLimit: number
  isFeatured: boolean
  pricePoints: FielderSubscriptionPricePoint[]
}

export enum FielderSubscriptionPricePointType {
  ONE_TIME = "ONE_TIME",
  RECURRING = "RECURRING",
}

export enum FielderSubscriptionPricePointInterval {
  MONTH = "MONTH",
  YEAR = "YEAR",
}

export interface FielderSubscriptionPricePoint {
  id: string
  name: string
  type: FielderSubscriptionPricePointType
  interval: FielderSubscriptionPricePointInterval
  unitAmount: number
  effectiveMonthlyRate: number
}

export interface AllSubscriptionOptionsData {
  allSubscriptionOptions: FielderSubscriptionProduct[]
}

export interface GetMyProfileData {
  getMyProfile: User
}

export interface GetUserNotificationsData {
  getUserById: User
}

export interface EditMyProfileResponse {
  editMyProfile: {
    user: User
  }
}

export interface CreateEstimateTemplateResponse {
  createEstimateTemplate: {
    estimateTemplate: EstimateTemplate
  }
}

export interface EditEstimateTemplateResponse {
  editEstimateTemplate: {
    estimateTemplate: EstimateTemplate
  }
}

export interface EditNotificationSettingResponse {
  editNotificationSetting: {
    notificationSetting: NotificationSetting
  }
}

export interface GetInvoiceByIdData {
  getInvoiceById: Invoice
}

export interface GetEstimateByIdData {
  getEstimateById: Estimate
}

export interface CreateUserInput {
  email: string
  firstName?: string
  lastName?: string
  jobTitle?: string
  mobilePhoneNumber?: string
  roleIds: string[]
  organizationId?: string
  languageCode?: LanguageCode | null
}

export interface CreateUserResponse {
  createUser: {
    user: User
  }
}

export interface EditUserInput {
  id: string
  firstName?: string
  lastName?: string
  jobTitle?: string
  mobilePhoneNumber?: string
  roleIds: string[]
  organizationId: string
  status: UserStatus
  languageCode?: LanguageCode | null
}


export interface ArchiveUserInput {
  id: string
  reassignToUserId?: string
}
export interface UnarchiveUserInput {
  id: string
}

export interface EditUserResponse {
  editUser: {
    user: User
  }
}

export interface ArchiveUserResponse {
  archiveUser: {
    user: User
  }
}

export interface UnarchiveUserResponse {
  unarchiveUser: {
    user: User
  }
}

export interface CreateOrganizationInput {
  tradeName: string
  legalName: string
  ownerUserId?: string
  ownerEmail?: string
  ownerFirstName?: string
  ownerLastName?: string
  ownerMobilePhoneNumber?: string
  timeZone?: string
  email?: string
  websiteURL?: string
  phoneNumber?: string
  address?: Address
  regionId?: string
  accountManagerUserId?: string
  accountNumber?: string
  languageCode?: LanguageCode | null
  EditOrganizationInput?: boolean
  allowJobAssignmentOverlap?: boolean
  bccJobEmailToOrganizationEmail?: boolean
  shouldSendInvitation?: boolean
}

export interface EditOrganizationInput extends CreateOrganizationInput {
  id: string
}

export interface EditOrganizationResponse {
  editOrganization: {
    organization: Organization
  }
}

export enum EmailTemplateType {
  JOB = "JOB",
  ESTIMATE = "ESTIMATE",
  INVOICE = "INVOICE",
}

export interface EmailTemplate {
  id: string
  name: string
  type: EmailTemplateType
  subject: string
  body: string
  attachments: Attachment[]
  createdAt: string
  createdBy: User
  updatedAt: string
  updatedBy: User
}

export enum TemplateFieldFormat {
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  DATE = "DATE",
  CURRENCY = "CURRENCY",
  ADDRESS = "ADDRESS",
  URL = "URL",
}

export interface TemplateField {
  id: string
  key: string
  format: TemplateFieldFormat
}

export interface AllTemplateFieldsResponse {
  allTemplateFields: TemplateField[]
}

export interface ContractTemplate {
  id: string
  name: string
  body: string
  createdAt: string
  createdBy: User
  updatedAt: string
  updatedBy: User
}

export interface EstimateTemplate {
  id: string
  name: string
  currencyCode: CurrencyCode
  description: string
  notes: string
  subTotal: number
  taxSummary: TaxSummary
  totalDiscount: number
  total: number
  discount: Discount
  footerTitle: string
  footerBody: string
  contractJson?: string
  lineItems: EstimateTemplateLineItem[]
}

export interface Transaction<T extends TransactionLineItem<any>> {
  id: string
  number: number
  pdfFileName?: string
  organization: Organization
  currencyCode: CurrencyCode
  billingContact?: Contact
  description?: string
  notes?: string
  subTotal?: number
  total?: number
  taxSummary?: TaxSummary
  totalDiscount?: number
  discount?: Discount
  lineItems: T[]
  createdAt?: string
  createdBy?: User
}

export interface TransactionLineItemDetail {
  id: string
  organizationItem: OrganizationItem
  quantity: number
  unitPrice: number
  number: number
}

export interface TransactionLineItem<T extends TransactionLineItemDetail> {
  id: string
  number: number
  description: string
  organizationItem: OrganizationItem
  lineItemDetails?: T[]
  showDetails: boolean
  quantity: number
  unitPrice: number
  taxRateGroup: TaxRateGroup
  total: number
  createdAt?: string
  createdBy?: User
}

export type EstimateTemplateLineItemDetail = TransactionLineItemDetail
export type EstimateTemplateLineItem = TransactionLineItem<EstimateTemplateLineItemDetail>

export interface TransactionInput {
  id?: string
  number: number
  organizationId: string
  description?: string
  notes?: string
  footerTitle?: string
  footerBody?: string
  contractJson?: string
  lineItems?: TransactionLineItemInput[]
}

export interface TransactionLineItemInput {
  id?: string
  number?: number
  organizationItemId?: string
  description?: string
  quantity?: number
  taxRateGroupId?: string
  lineItemDetails?: TransactionLineItemDetailInput[]
  showDetails?: boolean
}

export interface TransactionLineItemDetailInput {
  id?: string
  organizationItemId?: string
  quantity?: number
  unitPrice?: number
  number?: number
}

export interface EstimatTemplateLineItemDetailInput {
  id?: string
  organizationItemId: string
  quantity: number
  number: number
}

export interface EstimateTemplateLineItemInput {
  id?: string
  number: number
  organizationItemId: string
  description?: string
  quantity: number
  taxRateGroupId: string
  lineItemDetails?: EstimatTemplateLineItemDetailInput[]
  showDetails: boolean
}

export interface CreateEstimateTemplateInput {
  name?: string
  description?: string
  notes?: string
  footerTitle?: string
  footerBody?: string
  contractJson?: string
  lineItems?: EstimateTemplateLineItemInput[]
}

export interface EditEstimateTemplateInput extends CreateEstimateTemplateInput {
  id?: string
}

export interface WorkOrderTemplate {
  id: string
  name: string
  currencyCode: CurrencyCode
  description: string
  notes: string
  subTotal: number
  total: number
  lineItems: WorkOrderTemplateLineItem[]
}

export type WorkOrderTemplateLineItemDetail = TransactionLineItemDetail
export type WorkOrderTemplateLineItem = TransactionLineItem<WorkOrderTemplateLineItemDetail>

// export interface WorkOrderTemplateLineItem {
//   id: string
//   number: number
//   organizationItem: OrganizationItem
//   description?: string
//   lineItemDetails: WorkOrderTemplateLineItemDetail[]
//   quantity: number
//   unitPrice: number
//   total: number
//   showDetails: boolean
// }

// export interface WorkOrderTemplateLineItemDetail {
//   id: string
//   organizationItem: OrganizationItem
//   quantity: number
//   unitPrice: number
//   number: number
// }

export interface Item {
  id: string
  code: string
  name: string
  region?: Region
  description?: string
  category?: string
  isIntendedForResale?: boolean
  isCancelable?: boolean
  isArchived?: boolean
  type?: ItemType
  stockType?: StockType
  msrp?: number
  wholesalePrice?: number
  wholesalePriceExpirationDate?: string
  leadTimeDays?: number
  palletQty?: number
  isRestricted?: boolean
  restrictedTo?: Organization[]
  attachments?: Attachment[]
  packSize?: number
  isPackBreakable?: boolean
}

export enum ChangeLogEventType {
  CREATED = "CREATED",
  UPDATED = "UPDATED",
  ARCHIVED = "ARCHIVED",
  UNARCHIVED = "UNARCHIVED",
  DELETED = "DELETED",
}

export interface ChangeLogFieldDetail {
  fieldName: string
  oldValue: string
  newValue: string
}

export interface ChangeLogEvent {
  eventType: ChangeLogEventType
  fieldDetails: ChangeLogFieldDetail[]
  user: User
  eventDate: string
}

export enum ProductOrderStatus {
  DRAFT = "DRAFT",
  SUBMITTED = "SUBMITTED",
  CANCELED = "CANCELED",
  IN_PROGRESS = "IN_PROGRESS",
  SHIPPING = "SHIPPING",
  INVOICING = "INVOICING",
  COMPLETED = "COMPLETED",
  ARCHIVED = "ARCHIVED",
}

export interface ProductOrderComment {
  id: string
  comment: string
  createdAt: string
  createdBy: User
}

export interface ProductOrder {
  id: string
  number: number
  organization: Organization
  status: ProductOrderStatus
  currencyCode: CurrencyCode
  dateSubmitted?: string
  submittedBy?: User
  boardPosition?: number
  notes: string
  subTotal: number
  taxSummary: TaxSummary
  total: number
  referenceNumber?: string
  containsMHI?: boolean
  lineItems: ProductOrderLineItem[]
  comments: ProductOrderComment[]
  changeLog: ChangeLogEvent[]
  isArchived: boolean
  createdAt?: string
  createdBy?: User
  updatedAt?: string
  updatedBy?: User
}

export interface ProductOrderLineItem {
  id: string
  number: number
  organizationItem: OrganizationItem
  lineItemDetails?: ProductOrderLineItemDetail[]
  deliveryLineItems?: ProductOrderDeliveryLineItem
  quantity: number
  unitPrice: number
  taxRateGroup: TaxRateGroup
  total: number
  createdAt?: string
  createdBy?: User
  updatedAt?: string
  updatedBy?: User
}

export interface ProductOrderLineItemDetail {
  id: string
  organizationItem: OrganizationItem
  quantity: number
  unitPrice: number
  number: number
  createdAt?: string
  createdBy?: User
  updatedAt?: string
  updatedBy?: User
}

export interface ProductOrderDeliveryLineItem {
  id: string
  productOrderLineItem: ProductOrderLineItem
  quantity: number
}

export interface CreateProductOrderDeliveryInput {
  productOrderId: string
  lineItems: ProductOrderDeliveryLineItemInput[]
}

export interface ProductOrderDeliveryLineItemInput {
  productOrderLineItemId: string
  quantity: number
}

export enum EstimateRequestStatus {
  NEW = "NEW",
  IN_PROGRESS = "IN_PROGRESS",
  CLOSED = "CLOSED",
  ARCHIVED = "ARCHIVED",
}

export interface EstimateRequest {
  id: string
  address: Address
  attachments: Attachment[]
  businessName: string
  description: string
  desiredCompletionDate: string
  email: string
  firstName: string
  organization: Organization
  jobId: string
  lastName: string
  phoneNumber: string
  status: EstimateRequestStatus
  customerType: CustomerType
  createdAt: string
  createdBy: User
  updatedAt: string
  updatedBy: User
}
