import React from "react"
import { useTranslation } from "react-i18next"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import TrashIcon from "@mui/icons-material/DeleteOutlined"
import ClosedIcon from "@mui/icons-material/MarkEmailReadOutlined"
import OpenIcon from "@mui/icons-material/DraftsOutlined"
import LabelIcon from "@mui/icons-material/SellOutlined"
import ConnectToJobIcon from "@mui/icons-material/WorkOutlineOutlined"

import { InboxConversationStatus } from "~/types"

const TOOLTIP_DELAY = 500

interface Props {
  readonly currentStatusFilter: InboxConversationStatus
  readonly onClickChangeStatus: (newStatus: InboxConversationStatus) => void
  readonly onClickLabel: () => void
  readonly onClickConnectWithJob: () => void
}

export default function HeaderConversationActions({
  currentStatusFilter,
  onClickChangeStatus,
  onClickLabel,
  onClickConnectWithJob,
}: Props) {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: "white",
        gap: "0.5rem",
      }}
    >
      {currentStatusFilter === InboxConversationStatus.OPEN ? (
        <Tooltip enterDelay={TOOLTIP_DELAY} title={t("page.inbox.closeSelectedConversations")}>
          <IconButton
            onClick={() => onClickChangeStatus(InboxConversationStatus.CLOSED)}
            sx={classes.button}
          >
            <ClosedIcon sx={classes.buttonIcon} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip enterDelay={TOOLTIP_DELAY} title={t("page.inbox.openSelectedConversations")}>
          <IconButton
            onClick={() => onClickChangeStatus(InboxConversationStatus.OPEN)}
            sx={classes.button}
          >
            <OpenIcon sx={classes.buttonIcon} />
          </IconButton>
        </Tooltip>
      )}
      <Divider flexItem orientation="vertical" variant="middle" />
      <Tooltip enterDelay={TOOLTIP_DELAY} title={t("page.inbox.addLabel")}>
        <IconButton onClick={onClickLabel} sx={classes.button}>
          <LabelIcon sx={classes.buttonIcon} />
        </IconButton>
      </Tooltip>
      <Divider flexItem orientation="vertical" variant="middle" />
      <Tooltip enterDelay={TOOLTIP_DELAY} title={t("page.inbox.connectToJob")}>
        <IconButton onClick={onClickConnectWithJob} sx={classes.button}>
          <ConnectToJobIcon sx={classes.buttonIcon} />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

const classes = {
  button: {
    width: "2.875rem",
  },
  buttonIcon: {
    color: "#4b5563",
    fontSize: "1.2rem",
  },
}
