import React from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import FlagIcon from "@mui/icons-material/Flag"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"

import { formatAddress, formatDate, truncate } from "~/util"
import { Job, JobAssignmentStatus } from "~/types"
import { useAuth } from "~/context/AuthContext"

interface Provided {
  draggableProps: any
  dragHandleProps: any
  innerRef: any
}

interface Props {
  readonly isDragging?: boolean
  readonly job: Job
  readonly provided: Provided
  readonly style?: React.CSSProperties
}

function JobCard({ isDragging, job, provided, style }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useAuth()

  const sortedAssignments =
    job.assignments?.slice()?.sort((a, b) => {
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    }) ?? []

  /**
   * Ideally this component shouldn't be handling it's own
   * click events like this, doing routing in response...
   * I tried really hard, but couldn't figure out how to handle
   * this in a better way with react-window and react-beautiful-dnd
   */
  function openJob(id: string) {
    navigate(`/app/jobs/edit/${id}`)
  }

  return (
    <Paper
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      onClick={() => openJob(job.id)}
      ref={provided.innerRef}
      style={getStyle(provided, style)}
      sx={{
        padding: "0.5rem",
        backgroundColor: "white",
        color: "#121212",
        borderRadius: "4px",
        overflowY: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <Box
          sx={{
            fontWeight: "bold",
          }}
        >
          {t("component.jobCard.jobNumber", { jobNumber: job.number })}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "0.125rem" }}>
          {sortedAssignments.map((assignment) => (
            <Box
              key={assignment.id}
              sx={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor: getAssignmentStatusColor(assignment.status),
              }}
              title={t("component.jobCard.assignmentTooltip", {
                status: t("jobAssignmentStatus." + assignment.status).toLowerCase(),
                date: formatDate(
                  assignment.startDate,
                  t("format:dateFormat.shortWithTime"),
                  user?.organization?.timeZone
                ),
              })}
            />
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: "block",
          marginRight: "0.5rem",
          verticalAlign: "bottom",
        }}
      >
        <Box
          component="span"
          sx={{
            fontSize: "0.875rem",
            fontWeight: 500,
            marginRight: "0.25rem",
          }}
        >
          {job.customer.name}
        </Box>
        {job.customer.flags?.length > 0 ? (
          <FlagIcon
            style={{
              color: job.customer.flags[0].colorCode,
              fontSize: "14px",
            }}
          />
        ) : null}
      </Box>
      <Box
        sx={{
          fontSize: "0.75rem",
          marginTop: "0.375rem",
          marginBottom: "0.375rem",
        }}
      >
        {formatAddress(job.address.addressString)}
      </Box>
      <Box
        sx={{
          fontSize: "0.75rem",
          fontStyle: "italic",
          overflow: "hidden",
          whiteSpace: "pre-wrap",
        }}
      >
        {truncate(job.description, 70, true)}
      </Box>
    </Paper>
  )
}

export default JobCard

function getAssignmentStatusColor(status: JobAssignmentStatus): string {
  if (status === JobAssignmentStatus.TENTATIVE) {
    return "#FDE047"
  } else if (status === JobAssignmentStatus.CONFIRMED) {
    return "hsl(229, 67%, 54%)"
  } else if (status === JobAssignmentStatus.COMPLETED) {
    return "#18671D"
  } else {
    return "hsl(224, 31%, 93%)"
  }
}

function getStyle(provided, style) {
  if (!style) {
    return provided.draggableProps.style
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  }
}
