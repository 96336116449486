import { useQuery } from "@apollo/client"
import { GET_ORGANIZATION_PLUGIN_INFO } from "~/queries/getOrganizationPluginInfo"
import { OrganizationPlugin, PluginProviderCategory } from "~/types"
import PLUGINS from "~/util/thirdPartyPluginData"

function getAccountingPluginLogoPath(accountingPlugin?: OrganizationPlugin | null): string {
  return PLUGINS.find(p => p.id === accountingPlugin?.pluginProvider?.id)?.logoPath ?? ""
}

export default function useGetAccountingPlugin(organizationId?: string): {
  accountingPlugin?: OrganizationPlugin
} {
  const { data: getOrgPluginData } = useQuery(GET_ORGANIZATION_PLUGIN_INFO, {
    variables: {
      id: organizationId,
    },
    skip: !organizationId,
  })
  const organization = getOrgPluginData?.getOrganizationById
  const accountingPlugin = organization?.plugins?.find(
    (p: OrganizationPlugin) => p.pluginProvider.category === PluginProviderCategory.ACCOUNTING
  )

  return {
    accountingPlugin: {
      ...accountingPlugin,
      logoPath: getAccountingPluginLogoPath(accountingPlugin),
    }
  }
}
