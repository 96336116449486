import React, { useMemo, useRef, useState } from "react"
import * as Sentry from "@sentry/react"
import { Navigate, NavigateProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useQuery, useMutation, NetworkStatus } from "@apollo/client"
import { nanoid } from "nanoid"
import dayjs from "dayjs"
import EditIcon from "@mui/icons-material/EditOutlined"
import ArchiveIcon from "@mui/icons-material/ArchiveOutlined"
import UnarchiveIcon from "@mui/icons-material/UnarchiveOutlined"
import AddIcon from "@mui/icons-material/Add"
import CircularProgress from "@mui/material/CircularProgress"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputAdornment,
  TextField,
  Avatar,
} from "@mui/material"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Button from "@mui/material/Button"

import { usePrompt } from "~/hooks/usePrompt"
import useGetAccountingPlugin from "~/hooks/useGetAccountingPlugin"
import { EDIT_PRODUCT_ORDER } from "~/queries/editProductOrder"
import { GET_PRODUCT_ORDER_BY_ID } from "~/queries/getProductOrderById"
import { ADD_PRODUCT_ORDER_COMMENT } from "~/queries/addProductOrderComment"
import { ARCHIVE_PRODUCT_ORDER } from "~/queries/archiveProductOrder"
import { UNARCHIVE_PRODUCT_ORDER } from "~/queries/unarchiveProductOrder"
import SectionHeader from "./SectionHeader"
import SectionContent from "./SectionContent"
import SaveButton from "./SaveButton"
import ProductOrderStatusPill from "./ProductOrderStatusPill"
import ProductOrderLineItemTableRow from "./ProductOrderLineItemTableRow"
import {
  asFloat,
  asInt,
  calculateLineTotal,
  formatDate,
  formatMoney,
  formatPersonName,
  NOT_SPECIFIED,
  parseGraphQLErrorCode,
} from "~/util"
import { useAuth } from "~/context/AuthContext"
import {
  ProductOrder,
  DefaultPermission,
  Snack,
  ProductOrderStatus,
  TransactionLineItemFormInput,
  ProductOrderComment,
} from "~/types"
import FielderTextField from "./FielderTextField"
import SelectorField from "./SelectorField"
import ReceiveShipmentDialog from "./ReceiveShipmentDialog"
import WhoWhen from "./WhoWhen"

interface Props {
  readonly productOrderId: string
  readonly setSnack: (snack: Snack) => void
}

function ProductOrderDetails({ productOrderId, setSnack }: Props) {
  const { t } = useTranslation()
  const { user, hasPermissions, isImpersonating } = useAuth()
  const [inEditMode, setInEditMode] = useState<boolean>(false)
  const [isReceiveShipmentDialogOpen, setIsReceiveShipmentDialogOpen] = useState<boolean>(false)
  const [isArchiveOrderDialogOpen, setIsArchiveOrderDialogOpen] = useState<boolean>(false)
  const [isUnarchiveOrderDialogOpen, setIsUnarchiveOrderDialogOpen] = useState<boolean>(false)
  const [productOrder, setProductOrder] = useState<ProductOrder>()
  const [lineItems, setLineItems] = useState<TransactionLineItemFormInput[]>([])
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [referenceNumber, setReferenceNumber] = useState<string>("")
  const [status, setStatus] = useState<ProductOrderStatus>()
  const { accountingPlugin } = useGetAccountingPlugin(user?.organization?.id)
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const [allComments, setAllComments] = useState<ProductOrderComment[]>([])
  const [comment, setComment] = useState<string>("")
  const commentsContainerRef = useRef<HTMLDivElement>(null)

  const statusOptions = useMemo(() => {
    return [
      {
        id: ProductOrderStatus.SUBMITTED,
        name: t("productOrderStatus.SUBMITTED"),
      },
      {
        id: ProductOrderStatus.IN_PROGRESS,
        name: t("productOrderStatus.IN_PROGRESS"),
      },
      {
        id: ProductOrderStatus.SHIPPING,
        name: t("productOrderStatus.SHIPPING"),
      },
      {
        id: ProductOrderStatus.INVOICING,
        name: t("productOrderStatus.INVOICING"),
      },
      {
        id: ProductOrderStatus.COMPLETED,
        name: t("productOrderStatus.COMPLETED"),
      },
    ]
  }, [t])

  usePrompt(t("messages.unsavedChangesNavPrompt"), isDirty)

  const { networkStatus: fetchNetworkStatus, refetch } = useQuery(GET_PRODUCT_ORDER_BY_ID, {
    variables: {
      id: productOrderId,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setProductOrder(data?.getProductOrderById)
      setStatus(data?.getProductOrderById?.status)
      setReferenceNumber(data?.getProductOrderById?.referenceNumber ?? "")
      const editableLineItems = data?.getProductOrderById?.lineItems
        ?.map((li: TransactionLineItemFormInput) => {
          return {
            ...li,
            organizationItem: li.organizationItem,
            organizationItemId: li.organizationItem?.id,
            key: li.id,
            quantity: li.quantity,
            unitPrice: li.unitPrice,
            taxRateGroup: li.taxRateGroup,
            total: li.total,
          }
        })
        .sort((a, b) => a.number - b.number)
      setLineItems(editableLineItems)
      const sortedComments = data?.getProductOrderById?.comments
        ?.concat([])
        .sort((a: ProductOrderComment, b: ProductOrderComment) => {
          return dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? -1 : 1
        })
      setAllComments(sortedComments)
      commentsContainerRef.current?.scrollTo(0, commentsContainerRef.current?.scrollHeight)
    },
  })

  const [editProductOrder, { loading: editProductOrderLoading }] = useMutation(EDIT_PRODUCT_ORDER, {
    onCompleted: () => {
      setInEditMode(false)
      setIsDirty(false)
      setSnack({
        messageKey: "messages.changesSaved",
        variant: "success",
      })
      refetch()
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [addComment, { loading: addCommentLoading }] = useMutation(ADD_PRODUCT_ORDER_COMMENT, {
    onCompleted: () => {
      refetch()
    },
  })

  /**
   * Only level 1 users can archive product orders
   */
  const [archiveProductOrder] = useMutation(ARCHIVE_PRODUCT_ORDER, {
    onCompleted: () => {
      setSnack({
        messageKey: "messages.archiveProductOrder.success",
        messageOptions: { orderNumber: productOrder?.number },
        variant: "success",
      })
      setIsArchiveOrderDialogOpen(false)
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  /**
   * Only level 1 users can unarchive product orders
   */
  const [unarchiveProductOrder] = useMutation(UNARCHIVE_PRODUCT_ORDER, {
    onCompleted: () => {
      setSnack({
        messageKey: "messages.unarchiveProductOrder.success",
        messageOptions: { orderNumber: productOrder?.number },
        variant: "success",
      })
      setIsUnarchiveOrderDialogOpen(false)
      setInEditMode(false)
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  function handleAddComment() {
    if (productOrderId) {
      addComment({
        variables: {
          productOrderId,
          comment,
        },
      })
      setComment("")
    }
  }

  function handleSave() {
    const sanitizedLineItems = lineItems
      .map((li) => {
        return {
          id: li.id,
          number: li.number,
          organizationItemId: li.organizationItem?.id ?? li.organizationItemId,
          quantity: asInt(li.quantity),
          unitPrice: li.unitPrice ? asFloat(li.unitPrice) : undefined,
        }
      })
      .filter((li) => li.organizationItemId && li.organizationItemId.length > 0)

    const payload = {
      variables: {
        id: productOrder?.id,
        lineItems: sanitizedLineItems,
        referenceNumber,
        status,
      },
    }

    editProductOrder(payload)
  }

  function updateLineItem(lineItem: TransactionLineItemFormInput) {
    setIsDirty(true)
    const idx = lineItems.findIndex((li) => li.key == lineItem.key)
    lineItem.total = calculateLineTotal(lineItem.quantity, lineItem.unitPrice)
    lineItems.splice(idx, 1, lineItem)
    lineItem.errors = {
      ...lineItem.errors,
      quantity: !lineItem.quantity || asInt(lineItem.quantity, true) < 0 ? "required" : null,
      unitPrice:
        typeof lineItem.unitPrice !== "undefined" && asFloat(lineItem.unitPrice, true) < 0
          ? "required"
          : null,
    }
    setLineItems(lineItems.concat([]))
  }

  function addLineItem() {
    setLineItems((prev) => {
      const maxLineNumber =
        lineItems?.length > 0 ? Math.max(...lineItems.map((li) => li.number ?? 0)) : 0
      return prev.concat([
        {
          number: maxLineNumber + 1,
          key: nanoid(),
          organizationItem: {
            id: "",
            code: "",
            name: "",
          },
          quantity: "",
          unitPrice: "",
          showDetails: false,
          errors: {
            showErrors: false,
          },
        },
      ])
    })
  }

  function removeLineItem(lineItem: TransactionLineItemFormInput) {
    setIsDirty(true)
    const updatedLineItems = lineItems.filter((li) => li.number !== lineItem.number)
    updatedLineItems.forEach((item, idx) => {
      item.number = idx + 1
    })
    setLineItems(updatedLineItems)
  }

  function calculateOrderTotal() {
    return lineItems?.reduce((acc, cur) => acc + (cur.total ?? 0), 0) || 0
  }

  function renderProductOrder() {
    const isEditable = hasPermissions?.([DefaultPermission.UpdateProductOrder]) && inEditMode
    return (
      <Table padding="none" size="small" sx={{ borderTop: "1px solid #E0E0E0", marginTop: "2rem" }}>
        <TableHead>
          <TableRow>
            <TableCell sx={classes.tableHeaderCell} width="49%">
              {t("item")}
            </TableCell>
            <TableCell align="right" sx={classes.tableHeaderCell}>
              {t("unitPrice")}
            </TableCell>
            <TableCell align="right" sx={classes.tableHeaderCell}>
              {t("quantityOrdered")}
            </TableCell>
            <TableCell align="right" sx={classes.tableHeaderCell}>
              {t("quantityReceived")}
            </TableCell>
            <TableCell align="right" sx={classes.tableHeaderCell} width="10%">
              {t("total")}
            </TableCell>
            {isEditable ? (
              <TableCell align="right" sx={classes.tableHeaderCell} width="1%" />
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {lineItems?.map((li) => (
            <ProductOrderLineItemTableRow
              currencyCode={currencyCode ?? "USD"}
              isEditable={isEditable}
              key={li.key}
              lineItem={li}
              onChange={updateLineItem}
              onDelete={() => removeLineItem(li)}
              productOrder={productOrder}
            />
          ))}
          {inEditMode ? (
            <TableRow>
              <TableCell colSpan="100%" padding="none" sx={{ padding: "1rem 0" }}>
                <Box
                  sx={{
                    border: "none",
                    paddingTop: "0.625rem",
                    paddingBottom: "0.625rem",
                    paddingRight: "1.25rem",
                    paddingLeft: "1.25rem",
                    textAlign: "center",
                  }}
                >
                  <Button
                    data-testid="estimateDialogField_addLineItemButton"
                    fullWidth
                    onClick={addLineItem}
                    sx={{
                      border: "1px solid #288906",
                      borderRadius: "4px",
                      color: "#288906",
                      fontWeight: 600,
                      "&:hover": {
                        backgroundColor: "#F5FFF2",
                      },
                    }}
                    variant="outlined"
                  >
                    <AddIcon />
                    <Box>{t("addALine")}</Box>
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          ) : null}
          <TableRow>
            <TableCell align="right" colSpan="100%" sx={classes.tableFooter}>
              <Box
                sx={{
                  marginTop: "1.5rem",
                  display: "flex",
                  flexDirection: "row",
                  gap: "1.2rem",
                  justifyContent: "flex-end",
                }}
              >
                <span>{t("subTotal")}</span>
                <span>{formatMoney(currencyCode, calculateOrderTotal())}</span>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const loading = fetchNetworkStatus === NetworkStatus.loading || editProductOrderLoading
  const currencyCode = productOrder?.currencyCode ?? user?.organization?.currencyCode
  const isLevel1User = user?.organization?.level === 1
  const isLevel2User = (user?.organization?.level ?? -1) >= 2

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <Paper sx={{ width: "100%" }}>
      {loading || !productOrder ? (
        <Box
          sx={{
            minHeight: "200px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "4rem",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <SectionHeader
            sx={(theme) => {
              return {
                [theme.breakpoints.up("sm")]: {
                  padding: "0.125rem",
                  paddingRight: "1.25rem",
                  paddingLeft: "1.25rem",
                },
              }
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "0.625rem",
                fontSize: "1.2rem",
                alignItems: "baseline",
              }}
            >
              <label>{t("orderNumber")}:</label>
              <label>{productOrder.number}</label>
            </Box>
            {!inEditMode &&
            isLevel1User &&
            hasPermissions?.([DefaultPermission.UpdateProductOrder]) ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1.5rem",
                }}
              >
                {!productOrder.isArchived ? (
                  <Button
                    onClick={() => {
                      setIsArchiveOrderDialogOpen(true)
                    }}
                    size="small"
                    startIcon={<ArchiveIcon />}
                    sx={{ color: "#b91c1c" }}
                    variant="text"
                  >
                    {t("archive")}
                  </Button>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "3rem",
                      alignItems: "center",
                    }}
                  >
                    <Box component="span" sx={{ fontSize: "0.875rem", color: "#b91c1c" }}>
                      {t("component.productOrderDetails.orderIsArchived")}
                    </Box>
                    <Button
                      onClick={() => {
                        setIsUnarchiveOrderDialogOpen(true)
                      }}
                      size="small"
                      startIcon={<UnarchiveIcon />}
                      variant="text"
                    >
                      {t("unarchive")}
                    </Button>
                  </Box>
                )}
                {!productOrder.isArchived ? (
                  <Button
                    onClick={() => {
                      setInEditMode(true)
                    }}
                    size="small"
                    startIcon={<EditIcon />}
                    variant="text"
                  >
                    {t("edit")}
                  </Button>
                ) : null}
              </Box>
            ) : null}
            {isLevel2User && hasPermissions?.([DefaultPermission.UpdateProductOrder]) ? (
              <Box>
                {[
                  ProductOrderStatus.DRAFT,
                  ProductOrderStatus.CANCELED,
                  ProductOrderStatus.SUBMITTED,
                ].includes(productOrder.status) ? (
                  <Button
                    onClick={() => {
                      setInEditMode(true)
                    }}
                    size="small"
                    startIcon={<EditIcon />}
                    sx={(theme) => ({
                      [theme.breakpoints.down("xs")]: {
                        marginLeft: 0,
                      },
                    })}
                    variant="text"
                  >
                    {t("edit")}
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setIsReceiveShipmentDialogOpen(true)
                    }}
                    variant="outlined"
                  >
                    {t("receiveShipment")}
                  </Button>
                )}
              </Box>
            ) : null}
          </SectionHeader>
          <Divider />
          <SectionContent sx={{ padding: "1.25rem" }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "2rem", marginBottom: "2rem" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "3rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    gap: "2rem",
                  }}
                >
                  <Box sx={classes.headerFieldContainer}>
                    <Box component="label" sx={classes.headerFieldLabel}>
                      {t("dateSubmitted")}
                    </Box>
                    <span>
                      {formatDate(
                        productOrder.dateSubmitted,
                        t("format:dateFormat.short"),
                        user?.organization?.timeZone,
                        user
                      )}
                    </span>
                  </Box>
                  <Box sx={[classes.headerFieldContainer, { maxWidth: "300px" }]}>
                    <Box component="label" sx={classes.headerFieldLabel}>
                      {t("component.productOrderDetails.submittedBy")}
                    </Box>
                    <span>{formatPersonName(productOrder.submittedBy)}</span>
                    {isLevel1User ? (
                      <Box
                        sx={{
                          fontSize: "0.875rem",
                          color: (theme) => theme.fielderColors.mutedText,
                        }}
                      >
                        {productOrder.organization.tradeName}
                      </Box>
                    ) : null}
                  </Box>
                  {/* TODO: this is GoliathTech-specific stuff below. Figure out a way to generalize it. */}
                  {productOrder?.organization?.region?.code === "USA" ? (
                    <Box sx={classes.headerFieldContainer}>
                      <Box component="label" sx={classes.headerFieldLabel}>
                        {t("component.productOrderDetails.mhiOrder")}
                      </Box>
                      <span>{productOrder.containsMHI ? t("yes") : t("no")}</span>
                    </Box>
                  ) : null}
                  {isLevel1User ? (
                    <>
                      <Box sx={classes.headerFieldContainer}>
                        <Box component="label" sx={classes.headerFieldLabel}>
                          {t("accountNumber")}
                        </Box>
                        <Box component="span" sx={{ whiteSpace: "nowrap" }}>
                          {productOrder?.organization?.accountNumber ?? NOT_SPECIFIED}
                        </Box>
                      </Box>
                      {inEditMode ? (
                        <FielderTextField
                          inputProps={{ maxLength: 255 }}
                          label={t("component.productOrderDetails.referenceNumber")}
                          name="referenceNumber"
                          onChange={(e) => setReferenceNumber(e.target.value)}
                          onFocus={(e) => e.target.select()}
                          value={referenceNumber}
                        />
                      ) : (
                        <Box sx={classes.headerFieldContainer}>
                          <Box component="label" sx={classes.headerFieldLabel}>
                            {t("component.productOrderDetails.referenceNumber")}
                          </Box>
                          <span>{productOrder.referenceNumber ?? NOT_SPECIFIED}</span>
                        </Box>
                      )}
                    </>
                  ) : null}
                </Box>
                <Box
                  sx={{
                    marginRight: "0.5rem",
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  {isLevel1User && inEditMode ? (
                    <SelectorField
                      disabled={false}
                      fullWidth
                      label={t("status") as string}
                      name="status"
                      onChange={(val) => {
                        setStatus(val.id)
                      }}
                      options={statusOptions}
                      required
                      value={status}
                    />
                  ) : (
                    <ProductOrderStatusPill status={productOrder.status} />
                  )}
                </Box>
              </Box>
              {productOrder.notes?.trim()?.length > 0 ? (
                <Box
                  sx={(theme) => {
                    const style = {
                      ...classes.headerFieldContainer,
                      flex: 1,
                    }
                    if (isLevel1User) {
                      return {
                        ...style,
                        padding: "1rem",
                        borderRadius: "4px",
                        backgroundColor: theme.fielderColors.brandPrimaryFaded,
                        border: `1px solid ${theme.fielderColors.brandPrimary}`,
                      }
                    } else {
                      return style
                    }
                  }}
                >
                  <Box component="label" sx={classes.headerFieldLabel}>
                    {t("component.productOrderDetails.specialInstructions")}
                  </Box>
                  <Box component="span" sx={{ maxWidth: "36rem" }}>
                    {productOrder.notes}
                  </Box>
                </Box>
              ) : null}
              <Box sx={{}}>
                <Box
                  component="label"
                  sx={[classes.headerFieldLabel, { display: "block", marginBottom: "0.5rem" }]}
                >
                  {t("comments")}
                </Box>
                {allComments?.length > 0 ? (
                  <Box
                    ref={commentsContainerRef}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1.5rem",
                      padding: "1rem 0.5rem",
                      maxHeight: "200px",
                      overflowY: "scroll",
                      marginBottom: "1rem",
                      borderRadius: "4px",
                      // border: "1px solid #EDEDED",
                    }}
                  >
                    {allComments?.map((c: ProductOrderComment) => {
                      return (
                        <Box key={c.id} sx={{ display: "flex", flexDirection: "row" }}>
                          <Box>
                            <Avatar
                              sx={{
                                width: "2rem",
                                height: "2rem",
                                marginRight: "1rem",
                                marginTop: "0.125rem",
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <WhoWhen
                              date={c.createdAt}
                              timeZone={user?.organization?.timeZone}
                              user={c.createdBy}
                            />
                            <Box>{c.comment}</Box>
                          </Box>
                        </Box>
                      )
                    })}
                  </Box>
                ) : null}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                  }}
                >
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {addCommentLoading ? (
                            <CircularProgress color="secondary" size={24} />
                          ) : null}
                        </InputAdornment>
                      ),
                    }}
                    disabled={addCommentLoading}
                    fullWidth
                    inputProps={{
                      maxLength: 1000,
                    }}
                    maxRows="3"
                    minRows="1"
                    multiline
                    name="note"
                    onChange={(e: any) => setComment(e.target.value)}
                    onKeyDown={(e: any) => {
                      if (e.key === "Enter") {
                        e.preventDefault()
                        handleAddComment()
                      }
                    }}
                    placeholder={
                      addCommentLoading
                        ? `${t("saving")}...`
                        : t("component.productOrderComments.placeholder")
                    }
                    sx={{ background: "#FFFFFF" }}
                    value={comment}
                    variant="outlined"
                  />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Button
                      disableElevation
                      disabled={isImpersonating}
                      onClick={handleAddComment}
                      sx={{ paddingLeft: "2rem", paddingRight: "2rem", height: "100%" }}
                      variant="outlined"
                    >
                      {t("send")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            {renderProductOrder()}
            {inEditMode ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "4rem",
                }}
              >
                <Button
                  color="secondary"
                  data-testid="cancelButton"
                  disabled={loading}
                  onClick={() => {
                    refetch()
                    setInEditMode(false)
                  }}
                  variant="outlined"
                >
                  {t("cancel")}
                </Button>
                <SaveButton loading={loading} onClick={handleSave} />
              </Box>
            ) : null}
          </SectionContent>
        </>
      )}
      {isReceiveShipmentDialogOpen && productOrder ? (
        <ReceiveShipmentDialog
          accountingPlugin={accountingPlugin}
          onClose={() => setIsReceiveShipmentDialogOpen(false)}
          productOrder={productOrder}
        />
      ) : null}
      {isArchiveOrderDialogOpen ? (
        <Dialog
          aria-describedby="archive-order-dialog-description"
          aria-labelledby="archive-order-dialog-title"
          onClose={() => setIsArchiveOrderDialogOpen(false)}
          open={isArchiveOrderDialogOpen}
        >
          <DialogTitle id="archive-order-dialog-title">{t("areYouSure")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="archive-order-dialog-description">
              {t("component.archiveOrderDialog.confirmationPrompt", {
                orderNumber: productOrder?.number,
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setIsArchiveOrderDialogOpen(false)}>
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              onClick={() => archiveProductOrder({ variables: { id: productOrder?.id } })}
            >
              {t("yes")}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {isUnarchiveOrderDialogOpen ? (
        <Dialog
          aria-describedby="unarchive-order-dialog-description"
          aria-labelledby="unarchive-order-dialog-title"
          onClose={() => setIsUnarchiveOrderDialogOpen(false)}
          open={isUnarchiveOrderDialogOpen}
        >
          <DialogTitle id="unarchive-order-dialog-title">{t("areYouSure")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="unarchive-order-dialog-description">
              {t("component.unarchiveOrderDialog.confirmationPrompt", {
                orderNumber: productOrder?.number,
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setIsUnarchiveOrderDialogOpen(false)}>
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              onClick={() => unarchiveProductOrder({ variables: { id: productOrder?.id } })}
            >
              {t("yes")}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </Paper>
  )
}

const classes = {
  headerFieldContainer: {
    display: "flex",
    flexDirection: "column",
  },
  headerFieldLabel: {
    fontWeight: 600,
    marginRight: "0.625rem",
    fontSize: "0.875rem",
    whiteSpace: "nowrap",
  },
  tableHeaderCell: {
    padding: "0.5rem",
    whiteSpace: "nowrap",
  },
  tableFooter: {
    fontWeight: 600,
    color: "#212121",
    fontSize: "1.0rem",
    padding: "0.125rem 0",
    border: "none",
  },
} as const

export default ProductOrderDetails
