import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { useMutation } from "@apollo/client"
import orderBy from "lodash/orderBy"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Hidden from "@mui/material/Hidden"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import EditIcon from "@mui/icons-material/EditOutlined"

import JobBasicInfoForm from "./JobBasicInfoForm"
import JobBasicInfoStatic from "./JobBasicInfoStatic"
import GoogleMapView from "~/components/GoogleMapView"
import SectionHeader from "~/components/SectionHeader"
import SectionContent from "~/components/SectionContent"
import { getAddressMarker, getDefaultMarkers, getUserHqMarker } from "~/util"
import { useAuth } from "~/context/AuthContext"
import { DefaultPermission, Job, Note as JobNote } from "~/types"
import Note from "~/components/Note"
import { CREATE_JOB_NOTE } from "~/queries/createJobNote"
import { EDIT_JOB_NOTE } from "~/queries/editJobNote"
import { DELETE_JOB_NOTE } from "~/queries/deleteJobNote"
import NoteForm from "~/components/NoteForm"

interface Props {
  readonly job: Job
  readonly onEnterEditMode: () => void
  readonly onSave: (job: Job) => void
  readonly onCancel: () => void
  readonly inEditMode?: boolean
  readonly loading?: boolean
}

function JobBasicInfoTab({
  job,
  onEnterEditMode,
  onSave,
  onCancel,
  inEditMode = false,
  loading = false,
}: Props) {
  const { user, hasPermissions } = useAuth()
  const { t } = useTranslation()
  const [jobAddressMarker, setJobAddressMarker] = useState(() =>
    getAddressMarker(job?.address, "job")
  )
  const [jobNotes, setJobNotes] = useState<JobNote[]>(() => {
    return job?.notes ? orderBy(job.notes, ["createdAt"], ["desc"]) : []
  })
  const [jobNoteIdsPending, setJobNoteIdsPending] = useState<string[]>([])
  const [markers, setMarkers] = useState(() => getDefaultMarkers(user, job))
  const canEdit = hasPermissions?.([DefaultPermission.UpdateJob])

  const [createJobNote, { loading: createNoteLoading }] = useMutation(CREATE_JOB_NOTE, {
    onCompleted: (data) => {
      const newNote = data.createJobNote.jobNote

      // Find the optimistic note and replace it with the real note
      setJobNotes((prevNotes) => {
        const updatedNotes = prevNotes.map((n) => {
          if (n.id === "optimistic" && n.text === newNote.text) {
            return newNote
          }
          return n
        })
        return updatedNotes
      })
    },
    onError: (error) => {
      Sentry.captureException(error)
    },
  })

  const [editJobNote, { loading: editNoteLoading }] = useMutation(EDIT_JOB_NOTE, {
    onCompleted: (data) => {
      const updatedNote = data.editJobNote.jobNote

      // Find the note in jobNotes and update it
      setJobNotes((prevNotes) => {
        const updatedNotes = prevNotes.map((n) => {
          if (n.id === updatedNote.id) {
            return updatedNote
          }
          return n
        })
        return updatedNotes
      })

      // Remove the note from jobNoteIdsPending
      setJobNoteIdsPending((prevIds) => prevIds.filter((id) => id !== updatedNote.id))
    },
    onError: (error) => {
      Sentry.captureException(error)
      // Remove the note from jobNoteIdsPending
      setJobNoteIdsPending([])
    },
  })

  const [deleteJobNote, { loading: deleteNoteLoading }] = useMutation(DELETE_JOB_NOTE, {
    onCompleted: (data) => {
      const deletedNoteId = data.deleteJobNote.id

      // Remove the note from jobNotes
      setJobNotes((prevNotes) => prevNotes.filter((n) => n.id !== deletedNoteId))

      // Remove the note from jobNoteIdsPending
      setJobNoteIdsPending((prevIds) => prevIds.filter((id) => id !== deletedNoteId))
    },
    onError: (error) => {
      Sentry.captureException(error)
      // Remove the note from jobNoteIdsPending
      setJobNoteIdsPending([])
    },
  })

  return (
    <Box
      sx={{
        paddingBottom: "400px",
      }}
    >
      <Grid container direction="row" justifyContent="center" spacing={2}>
        <Grid item lg={6} xs={12}>
          <Paper>
            <SectionHeader>
              <label>{t("basicInfo")}</label>
              {!inEditMode && canEdit ? (
                <Button
                  onClick={() => {
                    onEnterEditMode?.()
                  }}
                  size="small"
                  startIcon={<EditIcon />}
                  variant="text"
                >
                  {t("edit")}
                </Button>
              ) : null}
            </SectionHeader>
            <Divider />
            {!inEditMode && (
              <SectionContent>
                <JobBasicInfoStatic job={job} user={user} />
              </SectionContent>
            )}
            {inEditMode && user ? (
              <SectionContent>
                <JobBasicInfoForm
                  job={job}
                  loading={loading}
                  onCancel={onCancel}
                  onChangeAddress={(address) => {
                    const jobMarker = getAddressMarker(address, "estimate")
                    setJobAddressMarker(jobMarker)
                    const markers = [getUserHqMarker(user)]
                    if (jobMarker) {
                      markers.push(jobMarker)
                    }
                    setMarkers(markers)
                  }}
                  onSave={onSave}
                  user={user}
                />
              </SectionContent>
            ) : null}
          </Paper>
        </Grid>
        <Hidden smDown>
          <Grid item sm={6}>
            <Box
              sx={{
                height: "100%",
                maxHeight: "500px",
                borderRadius: "4px",
              }}
            >
              <GoogleMapView
                center={jobAddressMarker || getUserHqMarker(user)}
                markers={markers}
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                  borderRadius: "4px",
                  height: "100%",
                }}
                zoom={15}
              />
            </Box>
          </Grid>
        </Hidden>
        <Grid item lg={6} xs={12}>
          <Paper>
            <SectionHeader>
              <label>{t("notes")}</label>
            </SectionHeader>
            <Divider />
            <SectionContent
              sx={{
                paddingLeft: "1.25rem",
                paddingRight: "1.25rem",
                paddingBottom: "1.25rem",
                maxHeight: "500px",
                overflowY: "scroll",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                <NoteForm
                  addNoteLoading={createNoteLoading}
                  onAddNote={(note) => {
                    // optimistic update: add the note to jobNotes
                    setJobNotes((prevNotes) => {
                      const newNote = {
                        id: "optimistic",
                        text: note,
                        createdBy: user,
                        createdAt: new Date().toISOString(),
                      }
                      return [newNote, ...prevNotes]
                    })

                    createJobNote({
                      variables: {
                        jobId: job.id,
                        text: note,
                      },
                    })
                  }}
                />
                <Box>
                  {jobNotes.map((note, index) => (
                    <Box key={note.id}>
                      <Note
                        isAuthor={note.createdBy.id === user?.id}
                        isDeleting={Boolean(
                          deleteNoteLoading && jobNoteIdsPending.includes(note.id)
                        )}
                        isUpdating={Boolean(editNoteLoading && jobNoteIdsPending.includes(note.id))}
                        note={note}
                        onDelete={(id) => {
                          // optimistic update: find the note in jobNotes and remove it
                          setJobNotes((prevNotes) => {
                            const updatedNotes = prevNotes.filter((n) => n.id !== id)
                            return updatedNotes
                          })

                          setJobNoteIdsPending([...jobNoteIdsPending, id])

                          deleteJobNote({
                            variables: {
                              id,
                            },
                          })
                        }}
                        onEdit={(id: string, text: string) => {
                          // optimistic update: find the note in jobNotes and update it
                          setJobNotes((prevNotes) => {
                            const updatedNotes = prevNotes.map((n) => {
                              if (n.id === id) {
                                return {
                                  ...n,
                                  text,
                                }
                              }
                              return n
                            })
                            return updatedNotes
                          })

                          setJobNoteIdsPending([...jobNoteIdsPending, id])

                          editJobNote({
                            variables: {
                              id,
                              text,
                            },
                          })
                        }}
                        timeZone={user?.organization?.timeZone}
                      />
                      {index !== jobNotes.length - 1 && <Divider />}
                    </Box>
                  ))}
                </Box>
              </Box>
            </SectionContent>
          </Paper>
        </Grid>
        <Grid item lg={6} xs={12} />
      </Grid>
    </Box>
  )
}

export default JobBasicInfoTab
