import React from "react"
import { useTranslation } from "react-i18next"
import Paper from "@mui/material/Paper"
import InputBase from "@mui/material/InputBase"
import IconButton from "@mui/material/IconButton"
import SearchIcon from "@mui/icons-material/Search"
import CancelIcon from "@mui/icons-material/Cancel"
import { SxProps, Theme } from "@mui/material/styles"

import { isBlank } from "~/util"

interface Props {
  readonly onChange?: (term: string) => void
  readonly placeholder?: string | null
  readonly term: string
  readonly testID?: string
  readonly sx?: SxProps<Theme>
}

function SearchField({ onChange, placeholder = "Search", term, testID, sx = {} }: Props) {
  const { t } = useTranslation()

  return (
    <Paper
      data-testid={testID}
      elevation={0}
      sx={{
        padding: "0.125rem 0.25rem",
        display: "flex",
        alignItems: "center",
        borderRadius: "24px",
        border: "1px solid #ccc",
        ...sx,
      }}
    >
      <InputBase
        inputProps={{ "aria-label": placeholder }}
        onChange={(e) => {
          onChange?.(e.target.value)
        }}
        placeholder={placeholder}
        sx={{
          marginLeft: "0.75rem",
          flex: 1,
        }}
        value={term}
      />
      {isBlank(term) ? (
        <IconButton
          aria-label={t("search") as string}
          sx={{
            padding: "0.4375rem",
            marginRight: "0.125rem",
          }}
        >
          <SearchIcon />
        </IconButton>
      ) : (
        <IconButton
          aria-label={t("cancel") as string}
          onClick={() => {
            onChange?.("")
          }}
          sx={{
            padding: "0.4375rem",
            marginRight: "0.125rem",
          }}
        >
          <CancelIcon />
        </IconButton>
      )}
    </Paper>
  )
}

export default SearchField
