import React, { useState, useMemo, useEffect } from "react"
import * as Sentry from "@sentry/react"
import dayjs, { type Dayjs } from "dayjs"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { useTranslation } from "react-i18next"
import { nanoid } from "nanoid"
import { useMutation, useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import FormControl from "@mui/material/FormControl"
import Paper from "@mui/material/Paper"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import AddIcon from "@mui/icons-material/AddOutlined"

import CustomerContactSelect from "./CustomerContactSelect"
import CreateContactDialog from "./CreateContactDialog"
import LoadEstimateFromTemplateDialog from "./LoadEstimateFromTemplateDialog"
import SaveAsEstimateTemplateDialog from "./SaveAsEstimateTemplateDialog"
import { CREATE_ESTIMATE } from "~/queries/createEstimate"
import { EDIT_ESTIMATE } from "~/queries/editEstimate"
import { CREATE_WORK_ORDER } from "~/queries/createWorkOrder"
import { CREATE_INVOICE } from "~/queries/createInvoice"
import { GET_ESTIMATE_OPTIONS_AND_DEFAULTS } from "~/queries/getEstimateOptionsAndDefaults"
import {
  asFloat,
  asInt,
  formatDiscountValueForInput,
  isBlank,
  parseGraphQLErrorCode,
  sanitizeBillingContact,
  updateTransactionLineItem,
  createTransactionLineItemFormInput,
  formatAddress,
  getJobDocumentDisplayNumber,
  calculateLineTotal,
  isNumeric,
  convertToTransactionLineItemInput,
} from "~/util"
import FielderTextField from "~/components/FielderTextField"
import LineItem from "~/components/LineItem"
import SelectorField from "~/components/SelectorField"
import SnackbarMessage from "~/components/SnackbarMessage"
import TransactionFooter from "~/components/TransactionFooter"
import {
  DiscountType,
  Estimate,
  EstimateLineItem,
  EstimateStatus,
  User,
  Snack,
  DefaultPermission,
  Contact,
  WorkOrderLineItem,
  EstimateTemplateLineItem,
  WorkOrder,
  TaxRateGroup,
  Invoice,
  InvoiceStatus,
  WorkOrderStatus,
  EstimateLineItemDetail,
  NetTerms,
  EstimateCustomerVisibility,
  TransactionLineItemInput,
  TransactionLineItemFormInput,
} from "~/types"
import SaveButton from "~/components/SaveButton"
import { useAuth } from "~/context/AuthContext"
import LoadFromWorkOrderDialog from "./LoadFromWorkOrderDialog"
import EstimateContract from "./EstimateContract"
import GenerateInvoiceDialog from "./GenerateInvoiceDialog"
import GenerateWorkOrderDialog from "./GenerateWorkOrderDialog"
import useGetUserOrganizationPreferences from "~/hooks/useGetUserOrganizationPreferences"
import EstimateCustomerVisibilitySelect, {
  EstimateCustomerVisibilitySelectOption,
} from "~/components/EstimateCustomerVisibilitySelect"

const TODAY = dayjs().startOf("day")
const dialogBackgroundColor = "#FFFFFF"

function handleFocus(e: any) {
  e.target.select()
}

function getListStyle(isDraggingOver: boolean) {
  return {
    background: isDraggingOver ? "#efefef" : "#fff",
  }
}

function getRowStyle(isDragging: boolean, draggableStyle: any) {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? "#f1f8ff" : "#fff",

    // styles we need to apply on draggables
    ...draggableStyle,
  }
}

interface EstimateStatusOption {
  id: EstimateStatus
  name: string
}

interface FieldValidationError {
  title: string
  message: string
}

interface ValidationErrors {
  expirationDate: FieldValidationError | null
  lineItems: FieldValidationError | null
}

interface Props {
  readonly open?: boolean
  readonly onCancel: () => void
  readonly onSave: (estimate: Estimate) => void
  readonly estimate: Estimate
  readonly user: User
}

function EstimateDialog({ open = false, onCancel, onSave, estimate, user }: Props) {
  const { t } = useTranslation()
  const organizationPreferences = useGetUserOrganizationPreferences(user.organization.id)
  const [moreActionsMenuAnchorEl, setMoreActionsMenuAnchorEl] = useState<
    EventTarget & HTMLButtonElement
  >()
  const showMoreActionsMenu = Boolean(moreActionsMenuAnchorEl)
  const { isImpersonating, hasPermissions } = useAuth()
  const [snack, setSnack] = useState<Snack>()
  const [generateWorkOrderDialogOpen, setGenerateWorkOrderDialogOpen] = useState<boolean>(false)
  const [generatedWorkOrder, setGeneratedWorkOrder] = useState<WorkOrder | null>(null)
  const [generateInvoiceDialogOpen, setGenerateInvoiceDialogOpen] = useState<boolean>(false)
  const [generatedInvoice, setGeneratedInvoice] = useState<Invoice | null>(null)
  const [loadFromTemplateDialogOpen, setLoadFromTemplateDialogOpen] = useState<boolean>(false)
  const [saveAsTemplateDialogOpen, setSaveAsTemplateDialogOpen] = useState<boolean>(false)
  const [loadFromWorkOrderDialogOpen, setLoadFromWorkOrderDialogOpen] = useState<boolean>(false)
  const statusOptions: Array<EstimateStatusOption> = useMemo(() => {
    return [
      { id: EstimateStatus.PENDING_SUBMISSION, name: `${t("estimateStatus.pendingSubmission")}` },
      { id: EstimateStatus.PENDING_ACCEPTANCE, name: `${t("estimateStatus.pendingAcceptance")}` },
      { id: EstimateStatus.CHANGE_REQUESTED, name: `${t("estimateStatus.changeRequested")}` },
      { id: EstimateStatus.ACCEPTED, name: `${t("estimateStatus.accepted")}` },
    ]
  }, [t])
  const [description, setDescription] = useState<string>(() => estimate.description ?? "")
  const [notes, setNotes] = useState<string>(() => estimate.notes ?? "")
  const [billingContact, setBillingContact] = useState<Contact | undefined>(
    () => estimate.billingContact
  )
  const [createContactDialogOpen, setCreateContactDialogOpen] = useState<boolean>(false)
  const [expirationDate, setExpirationDate] = useState<Dayjs | null>(() => {
    return estimate.expirationDate ? dayjs(estimate.expirationDate) : null
  })
  const [discount, setDiscount] = useState<string>(() => {
    return estimate.discount?.value && asFloat(estimate.discount?.value) !== 0
      ? `${estimate.discount?.value}`
      : ""
  })
  const [discountType, setDiscountType] = useState<DiscountType>(
    () => estimate.discount?.type ?? DiscountType.PERCENTAGE
  )
  const [status, setStatus] = useState<EstimateStatus>(() => {
    const res =
      statusOptions.find((o) => o.id === estimate.status)?.id ??
      statusOptions.find((o) => o.id === EstimateStatus.PENDING_SUBMISSION)?.id
    return res as EstimateStatus
  })
  const [lineItems, setLineItems] = useState<TransactionLineItemFormInput[]>(
    () =>
      (estimate.lineItems
        ?.slice()
        ?.sort((a: EstimateLineItem, b: EstimateLineItem) => a.number - b.number)
        ?.map((lineItem: EstimateLineItem) => ({
          id: lineItem.id,
          key: nanoid(),
          number: lineItem.number,
          description: lineItem.description,
          quantity: `${lineItem.quantity}`,
          unitPrice: `${lineItem.unitPrice}`,
          total: lineItem.total,
          showDetails: lineItem.showDetails,
          organizationItem: lineItem.organizationItem,
          organizationItemId: lineItem.organizationItem?.id,
          taxRateGroup: lineItem.taxRateGroup,
          lineItemDetails: lineItem.lineItemDetails?.map((detail: EstimateLineItemDetail) => ({
            id: detail.id,
            organizationItem: detail.organizationItem,
            organizationItemId: detail.organizationItem?.id,
            quantity: `${detail.quantity}`,
            number: detail.number,
            unitPrice: detail.unitPrice,
          })),
        })) ?? []) as TransactionLineItemFormInput[]
  )
  const [footerTitle, setFooterTitle] = useState<string>(estimate.footerTitle ?? "")
  const [footerBody, setFooterBody] = useState<string>(estimate.footerBody ?? "")
  const [contractJson, setContractJson] = useState<string | null>(estimate.contractJson ?? null)
  const [customerVisibility, setCustomerVisibility] = useState<EstimateCustomerVisibility>(() => ({
    showLineItems:
      typeof estimate.customerVisibility?.showLineItems !== "undefined"
        ? estimate.customerVisibility?.showLineItems
        : true,
    showLineItemQuantity:
      typeof estimate.customerVisibility?.showLineItemQuantity !== "undefined"
        ? estimate.customerVisibility?.showLineItemQuantity
        : true,
    showLineItemUnitPrice:
      typeof estimate.customerVisibility?.showLineItemUnitPrice !== "undefined"
        ? estimate.customerVisibility?.showLineItemUnitPrice
        : true,
    showLineItemSubtotal:
      typeof estimate.customerVisibility?.showLineItemSubtotal !== "undefined"
        ? estimate.customerVisibility?.showLineItemSubtotal
        : true,
    showLineItemCode:
      typeof estimate.customerVisibility?.showLineItemCode !== "undefined"
        ? estimate.customerVisibility?.showLineItemCode
        : true,
    showLineItemName:
      typeof estimate.customerVisibility?.showLineItemName !== "undefined"
        ? estimate.customerVisibility?.showLineItemName
        : true,
    showLineItemDescription:
      typeof estimate.customerVisibility?.showLineItemDescription !== "undefined"
        ? estimate.customerVisibility?.showLineItemDescription
        : true,
    showLineItemBundleComponents:
      typeof estimate.customerVisibility?.showLineItemBundleComponents !== "undefined"
        ? estimate.customerVisibility?.showLineItemBundleComponents
        : true,
    showLineItemBundleComponentUnitPrice:
      typeof estimate.customerVisibility?.showLineItemBundleComponentUnitPrice !== "undefined"
        ? estimate.customerVisibility?.showLineItemBundleComponentUnitPrice
        : true,
    showLineItemBundleComponentQuantity:
      typeof estimate.customerVisibility?.showLineItemBundleComponentQuantity !== "undefined"
        ? estimate.customerVisibility?.showLineItemBundleComponentQuantity
        : true,
    showFooter:
      typeof estimate.customerVisibility?.showFooter !== "undefined"
        ? estimate.customerVisibility?.showFooter
        : true,
    showFooterSubtotal:
      typeof estimate.customerVisibility?.showFooterSubtotal !== "undefined"
        ? estimate.customerVisibility?.showFooterSubtotal
        : true,
    showFooterDiscount:
      typeof estimate.customerVisibility?.showFooterDiscount !== "undefined"
        ? estimate.customerVisibility?.showFooterDiscount
        : true,
    showFooterTaxes:
      typeof estimate.customerVisibility?.showFooterTaxes !== "undefined"
        ? estimate.customerVisibility?.showFooterTaxes
        : true,
    showFooterTotal:
      typeof estimate.customerVisibility?.showFooterTotal !== "undefined"
        ? estimate.customerVisibility?.showFooterTotal
        : true,
    showExpirationDate:
      typeof estimate.customerVisibility?.showExpirationDate !== "undefined"
        ? estimate.customerVisibility?.showExpirationDate
        : true,
    showContract:
      typeof estimate.customerVisibility?.showContract !== "undefined"
        ? estimate.customerVisibility?.showContract
        : true,
  }))
  const [errors, setErrors] = useState<ValidationErrors>(() => ({
    expirationDate: null,
    lineItems: null,
  }))

  const { loading: optionsAndDefaultsLoading, data: optionsAndDefaultsData } = useQuery(
    GET_ESTIMATE_OPTIONS_AND_DEFAULTS,
    {
      variables: {
        id: user.organization.id,
      },
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        if (!expirationDate) {
          const expirationDays = data.getOrganizationById.defaultEstimateSettings.expirationDays
          setExpirationDate(TODAY.add(expirationDays, "day"))
        }
        if (!estimate.id) {
          const defaultEstimateSettings = data.getOrganizationById.defaultEstimateSettings
          setCustomerVisibility({
            showExpirationDate: defaultEstimateSettings.showExpirationDate,
            showContract: defaultEstimateSettings.showContract,
            showLineItems: defaultEstimateSettings.showLineItems,
            showLineItemQuantity: defaultEstimateSettings.showLineItemQuantity,
            showLineItemUnitPrice: defaultEstimateSettings.showLineItemUnitPrice,
            showLineItemSubtotal: defaultEstimateSettings.showLineItemSubtotal,
            showLineItemCode: defaultEstimateSettings.showLineItemCode,
            showLineItemName: defaultEstimateSettings.showLineItemName,
            showLineItemDescription: defaultEstimateSettings.showLineItemDescription,
            showLineItemBundleComponents: defaultEstimateSettings.showLineItemBundleComponents,
            showLineItemBundleComponentUnitPrice:
              defaultEstimateSettings.showLineItemBundleComponentUnitPrice,
            showLineItemBundleComponentQuantity:
              defaultEstimateSettings.showLineItemBundleComponentQuantity,
            showFooter: defaultEstimateSettings.showFooter,
            showFooterSubtotal: defaultEstimateSettings.showFooterSubtotal,
            showFooterDiscount: defaultEstimateSettings.showFooterDiscount,
            showFooterTaxes: defaultEstimateSettings.showFooterTaxes,
            showFooterTotal: defaultEstimateSettings.showFooterTotal,
          })
        }
      },
    }
  )

  const [editEstimate, { loading: editEstimateLoading, error: editEstimateError }] = useMutation(
    EDIT_ESTIMATE,
    {
      onCompleted: (data) => {
        onSave?.(data.editEstimate.estimate)
      },
      onError: (error) => {
        Sentry.captureException(error)
      },
      refetchQueries: () => {
        return ["AllEstimatesForJob"]
      },
    }
  )

  const [createEstimate, { loading: createEstimateLoading, error: createEstimateError }] =
    useMutation(CREATE_ESTIMATE, {
      onCompleted: (data) => {
        onSave?.(data.createEstimate.estimate)
      },
      onError: (error) => {
        Sentry.captureException(error)
      },
      refetchQueries: () => {
        return ["AllEstimatesForJob"]
      },
    })

  const [createWorkOrder, { loading: createWorkOrderLoading, error: createWorkOrderError }] =
    useMutation(CREATE_WORK_ORDER, {
      onCompleted: (data) => {
        setGeneratedWorkOrder(data.createWorkOrder.workOrder)
      },
      onError: (error) => {
        Sentry.captureException(error)
      },
    })

  const [createInvoice, { loading: createInvoiceLoading, error: createInvoiceError }] = useMutation(
    CREATE_INVOICE,
    {
      onCompleted: (data) => {
        setGeneratedInvoice(data.createInvoice.invoice)
      },
      onError: (error) => {
        Sentry.captureException(error)
      },
    }
  )

  useEffect(() => {
    if (createWorkOrderLoading) {
      setGenerateWorkOrderDialogOpen(true)
    }
  }, [createWorkOrderLoading])

  useEffect(() => {
    if (createInvoiceLoading) {
      setGenerateInvoiceDialogOpen(true)
    }
  }, [createInvoiceLoading])

  const networkError = editEstimateError || createEstimateError
  const loading = editEstimateLoading || createEstimateLoading
  const editMode = !!estimate.id

  const taxRateGroupOptions =
    optionsAndDefaultsData?.getOrganizationById?.taxRateGroups
      ?.slice()
      .sort((a: TaxRateGroup, b: TaxRateGroup) => {
        const nameA = a.name ?? ""
        const nameB = b.name ?? ""
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      }) ?? []

  if (taxRateGroupOptions?.length) {
    lineItems.forEach((li: TransactionLineItemFormInput) => {
      if (!li.taxRateGroup?.id) {
        li.taxRateGroup = taxRateGroupOptions[0]
      }
    })
  }

  function addLineItem() {
    setLineItems((prev) => {
      const maxLineNumber =
        lineItems?.length > 0 ? Math.max(...lineItems.map((li) => li.number ?? 0)) : 0
      return prev.concat([
        createTransactionLineItemFormInput(
          maxLineNumber + 1,
          taxRateGroupOptions?.[0] as TaxRateGroup,
          1
        ),
      ])
    })
  }

  function updateLineItem(reason: string, payload: any) {
    const updatedLineItems = updateTransactionLineItem(reason, payload, lineItems)

    if (areAllLineItemsValid(updatedLineItems)) {
      setErrors({
        ...errors,
        lineItems: null,
      })
    }

    setLineItems(updatedLineItems)
  }

  function removeLineItem(lineItem: TransactionLineItemFormInput): void {
    const updatedLineItems = lineItems.filter((li) => li.number !== lineItem.number)
    updatedLineItems.forEach((item, idx) => {
      item.number = idx + 1
    })
    if (areAllLineItemsValid(updatedLineItems)) {
      setErrors({
        ...errors,
        lineItems: null,
      })
    }
    setLineItems(updatedLineItems)
  }

  function areAllLineItemsValid(lines: TransactionLineItemFormInput[]): boolean {
    const hasInvalidLineItem = lines.some((li) => {
      return (
        isBlank(li.organizationItemId) ||
        isBlank(`${li.quantity}`) ||
        isBlank(`${li.unitPrice}`) ||
        asInt(li.quantity ?? 0) < 0 ||
        asFloat(li.unitPrice ?? 0) < 0 ||
        li.taxRateGroup === null
      )
    })

    return !hasInvalidLineItem
  }

  function generateWorkOrder() {
    setMoreActionsMenuAnchorEl(undefined)
    handleSave({
      onCompleted: (data) => {
        const savedEstimate = editMode ? data.editEstimate.estimate : data.createEstimate.estimate

        const sanitizedLineItems = savedEstimate.lineItems
          .filter((li: EstimateLineItem) => isNumeric(li.quantity))
          .map((li: EstimateLineItem) => {
            const lineItemInput = {
              number: li.number,
              organizationItemId: li.organizationItem.id,
              description: li.description,
              quantity: asInt(li.quantity),
              unitPrice: asFloat(li.unitPrice),
              taxRateGroupId: li.taxRateGroup?.id,
              showDetails: li.showDetails,
              lineItemDetails: li.lineItemDetails?.map(
                (d: EstimateLineItemDetail, index: number) => ({
                  id: d.id,
                  organizationItemId: d.organizationItem.id,
                  quantity: asInt(d.quantity),
                  unitPrice: asFloat(d.unitPrice),
                  number: d.number > 0 ? d.number : index + 1,
                })
              ),
            }
            return lineItemInput
          })
          .sort((a: TransactionLineItemInput, b: TransactionLineItemInput) => {
            return (a.number ?? 0) - (b.number ?? 0)
          })
          .map((li: TransactionLineItemInput, index: number) => ({
            ...li,
            number: index + 1,
          })) as TransactionLineItemInput[]

        const variables = {
          jobId: savedEstimate.job.id,
          billingContact: savedEstimate.billingContact
            ? {
                id: savedEstimate.billingContact.id,
              }
            : undefined,
          description: savedEstimate.description?.trim()?.substring(0, 1000),
          notes: savedEstimate.notes?.trim()?.substring(0, 1000),
          discount: savedEstimate.discount
            ? {
                value: savedEstimate.discount.value,
                type: savedEstimate.discount.type,
              }
            : undefined,
          lineItems: sanitizedLineItems,
          status: WorkOrderStatus.PENDING,
        }

        createWorkOrder({ variables })
      },
    })
  }

  function generateInvoice() {
    setMoreActionsMenuAnchorEl(undefined)
    handleSave({
      onCompleted: (data) => {
        const savedEstimate = editMode ? data.editEstimate.estimate : data.createEstimate.estimate

        const sanitizedLineItems = savedEstimate.lineItems
          .filter((li: EstimateLineItem) => isNumeric(li.quantity))
          .map((li: EstimateLineItem) => {
            const lineItemInput = {
              number: li.number,
              organizationItemId: li.organizationItem.id,
              description: li.description,
              quantity: asInt(li.quantity),
              unitPrice: asFloat(li.unitPrice),
              taxRateGroupId: li.taxRateGroup?.id,
              showDetails: li.showDetails,
              lineItemDetails: li.lineItemDetails?.map(
                (d: EstimateLineItemDetail, index: number) => ({
                  id: d.id,
                  organizationItemId: d.organizationItem.id,
                  quantity: asInt(d.quantity),
                  unitPrice: asFloat(d.unitPrice),
                  number: d.number > 0 ? d.number : index + 1,
                })
              ),
            }
            return lineItemInput as TransactionLineItemInput
          })
          .sort((a: TransactionLineItemInput, b: TransactionLineItemInput) => {
            return (a.number ?? 0) - (b.number ?? 0)
          })
          .map((li: TransactionLineItemInput, index: number) => ({
            ...li,
            number: index + 1,
          }))

        const variables = {
          jobId: savedEstimate.job.id,
          billingContact: savedEstimate.billingContact
            ? {
                id: savedEstimate.billingContact.id,
              }
            : undefined,
          description: savedEstimate.description?.trim()?.substring(0, 1000),
          notes: savedEstimate.notes?.trim()?.substring(0, 1000),
          netTerms: NetTerms.NET_30,
          dueDate: null,
          discount: savedEstimate.discount
            ? {
                value: savedEstimate.discount.value,
                type: savedEstimate.discount.type,
              }
            : undefined,
          lineItems: sanitizedLineItems,
          status: InvoiceStatus.DRAFT,
        }

        createInvoice({ variables })
      },
    })
  }

  function handleSave(extraOptions?: { onCompleted: (data: any) => void }) {
    if (errors.expirationDate) {
      return
    }

    const nonEmptyLineItems = lineItems.filter((li) => Boolean(li.organizationItemId))

    if (!nonEmptyLineItems || nonEmptyLineItems.length === 0) {
      setErrors({
        ...errors,
        lineItems: {
          title: t("component.estimateDialog.lineItems.required.title"),
          message: t("component.estimateDialog.lineItems.required.message"),
        },
      })
      return
    }

    if (!areAllLineItemsValid(nonEmptyLineItems)) {
      setErrors({
        ...errors,
        lineItems: {
          title: t("component.estimateDialog.lineItems.invalid.title"),
          message: t("component.estimateDialog.lineItems.invalid.message"),
        },
      })
      const validatedLineItems = nonEmptyLineItems.map((li) => {
        return {
          ...li,
          errors: {
            showErrors: true,
            organizationItemId: !li.organizationItemId ? "required" : null,
            quantity: isBlank(`${li.quantity}`) || asInt(li.quantity ?? 0) < 0 ? "required" : null,
            unitPrice:
              isBlank(`${li.unitPrice}`) || asFloat(li.unitPrice ?? 0) < 0 ? "required" : null,
            taxRateGroup: !li.taxRateGroup?.id ? "required" : null,
          },
        }
      })
      setLineItems(validatedLineItems)
      return
    }

    setErrors({
      expirationDate: null,
      lineItems: null,
    })

    const variables = {
      id: estimate.id ?? undefined,
      jobId: estimate.job?.id || null,
      billingContact: billingContact ? sanitizeBillingContact(billingContact) : undefined,
      description: description.trim().substring(0, 1000),
      notes: notes.trim().substring(0, 1000),
      expirationDate: expirationDate ? dayjs(expirationDate).startOf("day").utc().format() : null,
      discount: {
        value: (asFloat(discount) ?? 0) / (discountType === DiscountType.PERCENTAGE ? 100 : 1.0),
        type: discountType,
      },
      lineItems: convertToTransactionLineItemInput(nonEmptyLineItems),
      status: status,
      footerTitle: footerTitle?.trim()?.substring(0, 50),
      footerBody: footerBody?.trim(),
      contractJson: contractJson ? contractJson : undefined,
      customerVisibility,
    }

    editMode
      ? editEstimate({ variables, ...extraOptions })
      : createEstimate({ variables, ...extraOptions })
  }

  function handleRowDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const startIndex = result.source.index
    const endIndex = result.destination.index
    if (startIndex === endIndex) {
      return
    }

    const sourceLineItem = lineItems[startIndex]

    let sortedLines = Array.from(lineItems)
    sortedLines.splice(startIndex, 1)
    sortedLines.splice(endIndex, 0, sourceLineItem)
    sortedLines = sortedLines.map((item, idx) => ({
      ...item,
      number: idx + 1,
    }))

    setLineItems(sortedLines)
  }

  const currencyCode = estimate.currencyCode ?? user.organization.currencyCode

  return (
    <>
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Dialog
        aria-labelledby="form-dialog-title"
        data-testid="EstimateDialog"
        fullWidth
        maxWidth="xl"
        onClose={(event, reason) => {
          if (reason === "escapeKeyDown") {
            onCancel?.()
          }
        }}
        open={open}
        sx={{
          marginBottom: "40px", // make room for the intercom bubble
        }}
      >
        <DialogTitle
          id="form-dialog-title"
          sx={{
            py: "0.625rem",
            px: "1.5rem",
            backgroundColor: (theme) => theme.palette.primary.main,
          }}
        >
          {estimate.id
            ? t("editEstimate", { number: getJobDocumentDisplayNumber(estimate) })
            : t("createEstimate")}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              backgroundColor: dialogBackgroundColor,
              paddingBottom: 0,
              paddingTop: "1.5rem",
              display: "flex",
              flexDirection: "column",
              minHeight: "390px",
            }}
          >
            {networkError ? (
              <Box sx={classes.errorContainer}>
                <Box sx={classes.errorTitle}>{t("error.general.title")}</Box>
                <Box sx={classes.errorMessage}>{t(parseGraphQLErrorCode(networkError))}</Box>
              </Box>
            ) : errors.lineItems ? (
              <Box sx={classes.errorContainer}>
                <Box sx={classes.errorTitle}>{errors.lineItems.title}</Box>
                <Box sx={classes.errorMessage}>{errors.lineItems.message}</Box>
              </Box>
            ) : null}
            {loading ? (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                  flex: 1,
                }}
              >
                <CircularProgress />
                <p>{t("saving")} ...</p>
              </Box>
            ) : (
              <Box
                sx={{
                  flex: 1,
                  minWidth: "88rem",
                  px: "1rem",
                }}
              >
                <Box sx={classes.rowContainer}>
                  <Box sx={classes.rowItem}>
                    <Box component="label" sx={classes.staticFieldLabel}>
                      {t("jobAddress")}
                    </Box>
                    <Box sx={classes.staticFieldValue}>
                      {formatAddress(estimate.job.address.addressString)}
                    </Box>
                  </Box>
                  <Box
                    sx={[
                      classes.rowItem,
                      { display: "flex", flexDirection: "row", justifyContent: "flex-end" },
                    ]}
                  >
                    <EstimateCustomerVisibilitySelect
                      customerVisibility={customerVisibility}
                      onToggleOption={(option: EstimateCustomerVisibilitySelectOption) => {
                        setCustomerVisibility({
                          ...customerVisibility,
                          [option.id]: !customerVisibility[option.id],
                        })
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={classes.rowContainer}>
                  <Box sx={classes.rowItem}>
                    <SelectorField
                      data-testid="estimateDialogField_status"
                      label={t("status") as string}
                      name="status"
                      onChange={(selectedOption) => setStatus(selectedOption.id)}
                      options={statusOptions}
                      sx={classes.formControl}
                      value={status}
                    />
                  </Box>
                  <Box sx={classes.rowItem}>
                    <FormControl fullWidth sx={classes.formControl}>
                      <CustomerContactSelect
                        customerId={estimate.job.customer?.id}
                        label={t("billingContact") as string}
                        name="BillingContact"
                        onChange={(val) => {
                          if (val === "CREATE_NEW_CONTACT") {
                            setCreateContactDialogOpen(true)
                          } else {
                            setBillingContact(val)
                          }
                        }}
                        onlyShowBillingContacts
                        selectedOption={billingContact}
                        showCreateContactOption={estimate.job.customer?.type === "BUSINESS"}
                      />
                    </FormControl>
                  </Box>
                  <Box sx={classes.rowItem}>
                    <FormControl fullWidth sx={classes.formControl}>
                      <DatePicker
                        aria-label={t("expirationDate") as string}
                        format={t("format:dateFormat.short") as string}
                        label={t("expirationDate")}
                        maxDate={TODAY.add(5, "year")}
                        minDate={
                          estimate.id && estimate.status !== EstimateStatus.PENDING_SUBMISSION
                            ? null
                            : TODAY
                        }
                        onChange={(newValue) => {
                          if (!newValue || newValue?.isValid()) {
                            if (newValue?.isBetween(TODAY, TODAY.add(5, "year"), "day", "[]")) {
                              setErrors({
                                ...errors,
                                expirationDate: null,
                              })
                            }
                          }
                          setExpirationDate(newValue)
                        }}
                        onError={(reason) => {
                          if (reason === "minDate") {
                            setErrors({
                              ...errors,
                              expirationDate: {
                                title: "",
                                message: t("component.estimateDialog.expirationDate.error.minDate"),
                              },
                            })
                          } else if (reason === "maxDate") {
                            setErrors({
                              ...errors,
                              expirationDate: {
                                title: "",
                                message: t("component.estimateDialog.expirationDate.error.maxDate"),
                              },
                            })
                          } else if (reason === "invalidDate") {
                            setErrors({
                              ...errors,
                              expirationDate: {
                                title: "",
                                message: t("component.estimateDialog.expirationDate.error.invalid"),
                              },
                            })
                          }
                        }}
                        slotProps={{
                          textField: {
                            InputProps: {
                              disableUnderline: true,
                            },
                            fullWidth: true,
                            required: false,
                            helperText: errors.expirationDate
                              ? errors.expirationDate.message
                              : t("component.estimateDialog.expirationDate.helperText"),
                          },
                        }}
                        slots={{
                          textField: FielderTextField,
                        }}
                        value={expirationDate}
                      />
                    </FormControl>
                  </Box>
                </Box>
                <Box sx={classes.rowContainer}>
                  <Box sx={classes.rowItem}>
                    <FormControl fullWidth sx={classes.formControl}>
                      <FielderTextField
                        aria-label={t("description") as string}
                        data-testid="estimateDialogField_description"
                        helperText={t("component.estimateDialog.descriptionHelperText")}
                        id="description"
                        inputProps={{ maxLength: 1000 }}
                        label={t("description")}
                        maxRows={4}
                        minRows={4}
                        multiline
                        name="description"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder={t("component.estimateDialog.descriptionPlaceholder") as string}
                        value={description}
                      />
                    </FormControl>
                  </Box>
                  <Box sx={classes.rowItem}>
                    <FormControl fullWidth sx={classes.formControl}>
                      <FielderTextField
                        aria-label={t("notes") as string}
                        data-testid="estimateDialogField_notes"
                        helperText={t("component.estimateDialog.notesHelperText")}
                        id="notes"
                        inputProps={{ maxLength: 1000 }}
                        label={t("notes")}
                        maxRows={4}
                        minRows={4}
                        multiline
                        name="notes"
                        onChange={(e) => setNotes(e.target.value)}
                        placeholder={t("component.estimateDialog.notesPlaceholder") as string}
                        value={notes}
                      />
                    </FormControl>
                  </Box>
                </Box>
                <Box sx={classes.rowContainer}>
                  <Box sx={classes.rowItem}>
                    <Paper elevation={0} sx={classes.lineItemTableContainer}>
                      <DragDropContext onDragEnd={handleRowDragEnd}>
                        <Box data-testid="estimateDialogField_lineItemsGrid">
                          <Box sx={[classes.lineItemGridRow, classes.lineItemHeader]}>
                            <Box />
                            <label>{t("item")}</label>
                            <label>{t("description")}</label>
                            <label>{t("quantity")}</label>
                            <label>{t("unitPrice")}</label>
                            <label style={{ justifySelf: "end", paddingRight: "0.5rem" }}>
                              {t("lineSubtotal")}
                            </label>
                            <label>{t("taxRate")}</label>
                            <Box />
                          </Box>
                          <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {lineItems.map((li, index) => {
                                  return (
                                    <Draggable draggableId={li.key} index={index} key={li.key}>
                                      {(provided, snapshot) => (
                                        <LineItem
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          allowBundleModifications={Boolean(
                                            organizationPreferences?.allowBundleModsOnTxns
                                          )}
                                          currencyCode={currencyCode}
                                          lineItem={li}
                                          onChange={updateLineItem}
                                          onDelete={(item) => removeLineItem(item)}
                                          style={{
                                            ...classes.lineItemGridRow,
                                            ...getRowStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            ),
                                          }}
                                          taxRateGroupOptions={taxRateGroupOptions}
                                          taxRateGroupsOptionsLoading={optionsAndDefaultsLoading}
                                          variant="ESTIMATE"
                                        />
                                      )}
                                    </Draggable>
                                  )
                                })}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                          <Box sx={classes.addLineCell}>
                            <Button
                              data-testid="estimateDialogField_addLineItemButton"
                              fullWidth
                              onClick={addLineItem}
                              sx={{
                                border: "1px solid #288906",
                                borderRadius: "4px",
                                color: "#288906",
                                fontWeight: 600,
                                "&:hover": {
                                  backgroundColor: "#F5FFF2",
                                },
                              }}
                              variant="outlined"
                            >
                              <AddIcon />
                              <Box>{t("addALine")}</Box>
                            </Button>
                          </Box>
                        </Box>
                      </DragDropContext>
                      <Box
                        sx={[
                          classes.rowContainer,
                          { justifyContent: "space-between", marginTop: "2rem", gap: "1rem" },
                        ]}
                      >
                        <Box
                          sx={{
                            marginTop: "1rem",
                            flex: 3,
                            margin: "0.9375rem",
                            visibility: "hidden",
                          }}
                        />
                        <Box sx={{ flex: 2 }}>
                          <TransactionFooter
                            currencyCode={currencyCode}
                            discount={discount}
                            discountType={discountType}
                            lineItems={lineItems}
                            onChangeDiscount={setDiscount}
                            onChangeDiscountType={setDiscountType}
                          />
                        </Box>
                      </Box>
                      {!isBlank(estimate.footerTitle) || !isBlank(estimate.footerBody) ? (
                        <Box
                          sx={{
                            flexGrow: 1,
                            marginTop: "3rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <FormControl fullWidth>
                            <FielderTextField
                              aria-label={t("component.estimateDialog.footerTitle.title") as string}
                              helperText={
                                t("component.estimateDialog.footerTitle.helperText") as string
                              }
                              id="footerTitle"
                              inputProps={{ maxLength: 50 }}
                              label={t("component.estimateDialog.footerTitle.title")}
                              name="footerTitle"
                              onChange={(e) => setFooterTitle(e.target.value)}
                              onFocus={handleFocus}
                              value={footerTitle}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <FielderTextField
                              aria-label={t("component.estimateDialog.footerBody.title") as string}
                              helperText={
                                t("component.estimateDialog.footerBody.helperText") as string
                              }
                              id="footerBody"
                              inputProps={{ maxLength: 100000 }}
                              label={t("component.estimateDialog.footerBody.title") as string}
                              multiline
                              name="footerBody"
                              onChange={(e) => setFooterBody(e.target.value)}
                              onFocus={handleFocus}
                              rows={3}
                              value={footerBody}
                            />
                          </FormControl>
                        </Box>
                      ) : null}
                      {estimate ? (
                        <EstimateContract
                          estimate={estimate}
                          onChangeContent={(content) => {
                            setContractJson(JSON.stringify(content))
                          }}
                        />
                      ) : null}
                    </Paper>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: dialogBackgroundColor,
            borderTop: "1px solid #cdcdcd",
            padding: "1.5rem",
            paddingTop: "0.75rem",
            paddingBottom: "0.75rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Box
            sx={(theme) => {
              return {
                width: "100%",
                display: "flex",
                justifyContent: "center",
                [theme.breakpoints.up("sm")]: {
                  display: "none",
                },
              }
            }}
          >
            <Button
              aria-controls={showMoreActionsMenu ? "more-actions-menu" : undefined}
              aria-expanded={showMoreActionsMenu ? "true" : undefined}
              aria-haspopup="true"
              disableElevation
              disabled={loading}
              endIcon={<KeyboardArrowDownIcon />}
              id="more-actions-menu-button"
              onClick={(event) => {
                setMoreActionsMenuAnchorEl(event.currentTarget)
              }}
              sx={{ width: "100%" }}
              variant="outlined"
            >
              {t("moreActions")}
            </Button>
          </Box>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
            <Button
              color="secondary"
              data-testid="estimateDialogField_cancelButton"
              disabled={loading}
              onClick={onCancel}
              variant="outlined"
            >
              {t("cancel")}
            </Button>
            <Button
              aria-controls={showMoreActionsMenu ? "more-actions-menu" : undefined}
              aria-expanded={showMoreActionsMenu ? "true" : undefined}
              aria-haspopup="true"
              disableElevation
              disabled={loading}
              endIcon={<KeyboardArrowDownIcon />}
              id="more-actions-menu-button"
              onClick={(event) => {
                setMoreActionsMenuAnchorEl(event.currentTarget)
              }}
              sx={(theme) => {
                return {
                  display: "none",
                  [theme.breakpoints.up("sm")]: {
                    display: "inline-flex",
                  },
                }
              }}
              variant="text"
            >
              {t("moreActions")}
            </Button>
            <SaveButton loading={loading} onClick={handleSave} />
          </Box>
        </DialogActions>
      </Dialog>
      {generateWorkOrderDialogOpen ? (
        <GenerateWorkOrderDialog
          error={createWorkOrderError ? t(parseGraphQLErrorCode(createWorkOrderError)) : null}
          isLoading={createWorkOrderLoading}
          onClose={() => {
            setGenerateWorkOrderDialogOpen(false)
            setGeneratedWorkOrder(null)
          }}
          workOrder={generatedWorkOrder}
        />
      ) : null}
      {generateInvoiceDialogOpen ? (
        <GenerateInvoiceDialog
          error={createInvoiceError ? t(parseGraphQLErrorCode(createInvoiceError)) : null}
          invoice={generatedInvoice}
          isLoading={createInvoiceLoading}
          onClose={() => {
            setGenerateInvoiceDialogOpen(false)
            setGeneratedInvoice(null)
          }}
        />
      ) : null}
      {createContactDialogOpen && estimate.job.customer ? (
        <CreateContactDialog
          canBeBillingContact
          canBeJobSiteContact
          customer={estimate.job.customer}
          onCancel={() => setCreateContactDialogOpen(false)}
          onSave={(contact) => {
            setBillingContact(contact)
            setCreateContactDialogOpen(false)
          }}
          showCanBeBillingContactToggle
          showCanBeJobSiteContactToggle={false}
          user={user}
        />
      ) : null}
      {saveAsTemplateDialogOpen ? (
        <SaveAsEstimateTemplateDialog
          description={description}
          discountType={discountType}
          discountValue={discount}
          lineItems={lineItems}
          notes={notes}
          onCancel={() => {
            setSaveAsTemplateDialogOpen(false)
          }}
          onSave={(success, messageKey, messageOptions) => {
            if (success) {
              setSnack({ messageKey, messageOptions, variant: "success" })
            } else {
              setSnack({ messageKey, messageOptions, variant: "error" })
            }
            setSaveAsTemplateDialogOpen(false)
          }}
        />
      ) : null}
      {loadFromTemplateDialogOpen ? (
        <LoadEstimateFromTemplateDialog
          onCancel={() => {
            setLoadFromTemplateDialogOpen(false)
          }}
          onSelectTemplate={(selectedTemplate) => {
            setDescription(
              description.concat(
                description.trim().length > 0 ? "\n" : "",
                selectedTemplate.description ?? ""
              )
            )
            setNotes(
              notes.concat(notes.trim().length > 0 ? "\n" : "", selectedTemplate.notes ?? "")
            )

            // Merge the incoming line items with the existing line items
            const existingOrgItemIds = lineItems
              .map((li) => li.organizationItem?.id)
              ?.filter((id) => Boolean(id))

            const updatedExistingLineItems = lineItems
              .filter((li) => Boolean(li.organizationItemId) || Boolean(li.organizationItem?.id))
              .map((li: TransactionLineItemFormInput) => {
                const matchingTemplateLine = selectedTemplate.lineItems.find(
                  (tli) => tli.organizationItem?.id === li.organizationItem?.id
                )
                return {
                  ...li,
                  quantity: String(
                    Math.max(Number(matchingTemplateLine?.quantity ?? 0), Number(li.quantity))
                  ),
                  lineItemDetails: matchingTemplateLine?.lineItemDetails ?? li.lineItemDetails,
                  showDetails: Boolean(matchingTemplateLine?.showDetails ?? li.showDetails),
                }
              }) as TransactionLineItemFormInput[]

            const newLines = selectedTemplate?.lineItems
              ?.filter(
                (li: EstimateTemplateLineItem) =>
                  !existingOrgItemIds.includes(li.organizationItem?.id)
              )
              ?.sort((a: EstimateTemplateLineItem, b: EstimateTemplateLineItem) => {
                return (a.number ?? 0) - (b.number ?? 0)
              })
              ?.map((li: EstimateTemplateLineItem, index: number) => {
                return {
                  id: undefined,
                  number: updatedExistingLineItems.length + index + 1,
                  organizationItemId: li.organizationItem?.id,
                  organizationItem: li.organizationItem,
                  description: li.description ?? li.organizationItem?.description,
                  unitPrice: String(li.organizationItem.unitSalePrice),
                  quantity: String(li.quantity),
                  lineItemDetails: li.lineItemDetails?.map((lid) => ({
                    id: undefined,
                    number: lid.number,
                    organizationItem: lid.organizationItem,
                    organizationItemId: lid.organizationItem.id,
                    quantity: String(lid.quantity),
                    unitPrice: String(lid.unitPrice),
                  })),
                  showDetails: li.showDetails,
                  taxRateGroup: li.taxRateGroup,
                  key: nanoid(),
                  total: calculateLineTotal(li.quantity, li.unitPrice),
                } as TransactionLineItemFormInput
              }) as TransactionLineItemFormInput[]

            const allLines = updatedExistingLineItems
              .concat(newLines)
              .filter((li) => !isBlank(li.organizationItem?.id))
              .sort((a, b) => {
                return (a.number ?? 0) - (b.number ?? 0)
              })
              .map((li, idx) => ({
                ...li,
                number: idx + 1,
              }))
            setLineItems(allLines)

            setDiscount(formatDiscountValueForInput(selectedTemplate.discount))
            setDiscountType(selectedTemplate.discount?.type ?? DiscountType.PERCENTAGE)
            setFooterTitle(selectedTemplate.footerTitle ?? "")
            setFooterBody(selectedTemplate.footerBody ?? "")
            setLoadFromTemplateDialogOpen(false)
          }}
        />
      ) : null}
      {loadFromWorkOrderDialogOpen && estimate.job.id ? (
        <LoadFromWorkOrderDialog
          jobId={estimate.job.id}
          onCancel={() => {
            setLoadFromWorkOrderDialogOpen(false)
          }}
          onSelectWorkOrder={(selectedWorkOrder: WorkOrder) => {
            // The strategy is really just to bring over line items from the WorkOrder that are not already in the Estimate,
            // or to update the quantities of existing Estimate Line Items to match the quantity from the respective Work order line.
            // Any newly imported line items are appended at the end of the list so the ordering of existing line items is not mutated.
            const existingOrgItemIds = lineItems
              .map((li) => li.organizationItem?.id)
              ?.filter((id) => Boolean(id))

            const updatedExistingLineItems = lineItems
              .filter((li) => Boolean(li.organizationItemId) || Boolean(li.organizationItem?.id))
              .map((li: TransactionLineItemFormInput) => {
                const matchingWorkOrderLine = selectedWorkOrder?.lineItems?.find(
                  (wli) =>
                    wli.organizationItem?.id === li.organizationItem?.id ||
                    wli.organizationItem?.id === li.organizationItemId
                )
                return {
                  ...li,
                  quantity: String(
                    Math.max(Number(matchingWorkOrderLine?.quantity ?? 0), Number(li.quantity))
                  ),
                  lineItemDetails: matchingWorkOrderLine?.lineItemDetails ?? li.lineItemDetails,
                  showDetails: Boolean(matchingWorkOrderLine?.showDetails ?? li.showDetails),
                }
              }) as TransactionLineItemFormInput[]

            const newLines = (selectedWorkOrder?.lineItems
              ?.filter(
                (li) => !existingOrgItemIds.includes(li.organizationItem?.id) && li.quantity > 0
              )
              ?.sort((a, b) => {
                return (a.number ?? 0) - (b.number ?? 0)
              })
              ?.map((li: WorkOrderLineItem, index: number) => {
                return {
                  id: undefined,
                  number: updatedExistingLineItems.length + index + 1,
                  organizationItemId: li.organizationItem?.id,
                  organizationItem: li.organizationItem,
                  description: li.description,
                  quantity: String(li.quantity ?? ""),
                  unitPrice: String(li.unitPrice ?? ""),
                  total: calculateLineTotal(li.quantity, li.unitPrice),
                  lineItemDetails: li.lineItemDetails?.map((lid) => {
                    return {
                      id: undefined,
                      number: lid.number,
                      organizationItem: lid.organizationItem,
                      organizationItemId: lid.organizationItem.id,
                      quantity: String(lid.quantity ?? ""),
                      unitPrice: String(lid.unitPrice),
                    }
                  }),
                  showDetails: li.showDetails,
                  key: nanoid(),
                } as TransactionLineItemFormInput
              }) ?? []) as TransactionLineItemFormInput[]

            const allLines = updatedExistingLineItems
              .concat(newLines)
              .filter((li) => !isBlank(li.organizationItem?.id))
              .sort((a, b) => {
                return (a.number ?? 0) - (b.number ?? 0)
              })
              .map((li, idx) => ({
                ...li,
                number: idx + 1,
              }))

            setLineItems(allLines)

            if (selectedWorkOrder.discount && Boolean(selectedWorkOrder.discount.value)) {
              setDiscount(formatDiscountValueForInput(selectedWorkOrder?.discount))
              setDiscountType(
                selectedWorkOrder?.discount?.type ? selectedWorkOrder.discount.type : discountType
              )
            }

            if (selectedWorkOrder.billingContact) {
              setBillingContact(selectedWorkOrder?.billingContact)
            }

            setDescription((prev) => {
              let newDescription = prev?.trim() ?? ""
              if (newDescription.length > 0) {
                newDescription += "\n"
              }
              if (!newDescription.includes(selectedWorkOrder?.description ?? "")) {
                newDescription += selectedWorkOrder?.description ?? ""
              }
              return newDescription
            })

            setLoadFromWorkOrderDialogOpen(false)
          }}
          variant="ESTIMATE"
        />
      ) : null}

      <Menu
        anchorEl={moreActionsMenuAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        elevation={1}
        onClose={() => {
          setMoreActionsMenuAnchorEl(undefined)
          setMoreActionsMenuAnchorEl(undefined)
        }}
        open={showMoreActionsMenu}
        sx={{
          "& .MuiMenu-list": {
            minWidth: "200px",
          },
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem
          disabled={loading}
          onMouseDown={() => {
            setMoreActionsMenuAnchorEl(undefined)
            setLoadFromWorkOrderDialogOpen(true)
          }}
        >
          {t("loadFromWorkOrder")}
        </MenuItem>
        <MenuItem
          disabled={loading}
          onMouseDown={() => {
            setMoreActionsMenuAnchorEl(undefined)
            setLoadFromTemplateDialogOpen(true)
          }}
        >
          {t("loadFromTemplate")}
        </MenuItem>
        {hasPermissions?.([DefaultPermission.CreateEstimateTemplate]) ? (
          <MenuItem
            disabled={loading || isImpersonating}
            onMouseDown={() => {
              setSaveAsTemplateDialogOpen(true)
            }}
          >
            {t("saveAsTemplate")}
          </MenuItem>
        ) : null}
        {hasPermissions?.([DefaultPermission.CreateWorkOrder]) ? (
          <MenuItem disabled={loading} onMouseDown={generateWorkOrder}>
            {t("component.estimateDialog.generateWorkOrder")}
          </MenuItem>
        ) : null}
        {hasPermissions?.([DefaultPermission.CreateInvoice]) ? (
          <MenuItem disabled={loading} onMouseDown={generateInvoice}>
            {t("component.estimateDialog.generateInvoice")}
          </MenuItem>
        ) : null}
      </Menu>
    </>
  )
}

const classes = {
  errorContainer: {
    backgroundColor: "#fef2f2",
    padding: "1.25rem",
    border: "1px solid #fee2e2",
    borderRadius: "4px",
    marginBottom: "1.25rem",
  },
  errorTitle: {
    color: "#991b1b",
    fontWeight: "600",
  },
  errorMessage: {
    color: "#7f1d1d",
  },
  formControl: {
    marginTop: "1rem",
  },
  rowContainer: {
    display: "flex",
    marginBottom: "0.625rem",
    marginTop: "0.625rem",
    minWidth: "450px",
    width: "100%",
  },
  rowItem: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "50%",
    marginRight: "0.3125rem",
    marginLeft: "0.3125rem",
  },
  staticFieldLabel: {
    fontSize: "0.85rem",
    fontWeight: 600,
  },
  staticFieldValue: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.9rem",
  },
  lineItemTableContainer: {
    marginTop: "0.625rem",
    paddingBottom: "1.25rem",
  },
  lineItemGridRow: {
    display: "grid",
    gridTemplateColumns:
      "50px minmax(100px, 4fr) minmax(100px, 4fr) repeat(3, minmax(50px, 1.2fr)) 2fr 50px",
    columnGap: "10px",
    paddingTop: "0.5rem",
    paddingBottom: "0.125rem",
    boxSizing: "border-box",
  },
  lineItemHeader: {
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    borderBottom: "1px solid #cdcdcd",
    "& label": {
      fontSize: "0.8rem",
      fontWeight: 600,
      lineHeight: "1.5rem",
    },
  },
  addLineCell: {
    border: "none",
    marginTop: "1.5rem",
    paddingTop: "0.625rem",
    paddingRight: "1.25rem",
    paddingLeft: "1.25rem",
    textAlign: "center",
  },
  addLineButton: {
    border: "1px solid #288906",
    borderRadius: "4px",
    color: "#288906",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#F5FFF2",
      border: "1px solid #288906",
    },
  },
} as const

export default EstimateDialog
