import gql from "graphql-tag"
import TaxRateGroupDetails from "./taxRateGroupDetails"

export default gql`
  fragment FullProductOrder on ProductOrder {
    id
    number
    status
    notes
    referenceNumber
    dateSubmitted
    currencyCode
    isArchived
    containsMHI
    boardPosition
    submittedBy {
      id
      firstName
      lastName
    }
    organization {
      id
      tradeName
      accountNumber
      accountManager {
        id
        firstName
        lastName
        avatar {
          id
          signedUrl
          updatedAt
        }
      }
      region {
        id
        name
        code
      }
    }
    subTotal
    taxSummary {
      total
      taxRateAmounts {
        taxRate {
          id
          name
          rate
        }
        amount
      }
    }
    total
    lineItems {
      id
      number
      organizationItem {
        id
        code
        name
        isCancelable
        leadTimeDays
        stockType
        qtyOnHand
        palletQty
        packSize
        isPackBreakable
        reorderPoint
        reorderQty
        attachments {
          id
          objectName
          signedUrl
          contentType
        }
      }
      description
      quantity
      unitPrice
      taxRateGroup {
        ...TaxRateGroupDetails
      }
      total
      deliveryLineItems {
        id
        quantity
      }
    }
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    updatedAt
    updatedBy {
      id
      firstName
      lastName
    }
  }
  ${TaxRateGroupDetails}
`
