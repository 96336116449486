import React from "react"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import SmsIcon from "@mui/icons-material/ForumOutlined"
import EmailIcon from "@mui/icons-material/EmailOutlined"
import dayjs from "dayjs"

import { createDayJS, formatDate } from "~/util"
import { InboxConversation, MessageChannel } from "~/types/apiTypes"
import TagChip from "./TagChip"
import { useAuth } from "~/context/AuthContext"

function formatDateSent(dateSent: string, timeZone: string) {
  const dateSentDayJS = createDayJS(dateSent, timeZone)
  const formatString = dateSentDayJS?.isSame(dayjs(), "day") ? "h:mm A" : "MMM D"
  return formatDate(dateSentDayJS, formatString, timeZone)
}

interface Props {
  readonly conversation: InboxConversation
  readonly isActive?: boolean // indicates if this is the currently displayed conversation
  readonly isSelected?: boolean // simply indicates if this conversation is selected (i.e., its checkbox is checked) for a bulk operation like archiving
  readonly onActivate: (conversationId: string) => void
  readonly onToggleConversationSelection: (conversationId: string, isSelected: boolean) => void
}

export default function ConversationSummary({
  conversation,
  isActive,
  isSelected,
  onActivate,
  onToggleConversationSelection,
}: Props) {
  const { user } = useAuth()

  return (
    <Box
      onClick={() => !isActive && onActivate(conversation.id)}
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "0.5rem",
        padding: "0.5rem",
        fontSize: "0.875rem",
        width: "100%",
        backgroundColor: isActive ? (theme) => theme.fielderColors.brandPrimaryFaded : "#fff",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: isActive ? (theme) => theme.fielderColors.brandPrimaryFaded : "#f9fafb",
        },
      }}
    >
      {/* Left Box */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "0.25rem",
          flexShrink: 0,
        }}
      >
        <Checkbox
          checked={isSelected}
          inputProps={{ "aria-label": "select conversation" }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            // event.target.checked ? onSelect(conversation.id) : onDeselect(conversation.id)
            onToggleConversationSelection(conversation.id, event.target.checked)
          }}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation() // prevent this event from causing this conversation to be activated
          }}
          size="small"
          sx={{ padding: "1px" }}
        />
        {!conversation.isRead ? (
          <Box
            sx={{
              width: "0.625rem",
              height: "0.625rem",
              borderRadius: "50%",
              backgroundColor: (theme) => theme.fielderColors.brandPrimary,
            }}
          />
        ) : null}
      </Box>

      {/* Middle Box */}
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1, flexGrow: 1, minWidth: 0 }}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "0.25rem" }}>
          {conversation.latestMessage.channel === MessageChannel.SMS ? (
            <SmsIcon sx={{ fontSize: "0.875rem" }} />
          ) : (
            <EmailIcon sx={{ fontSize: "0.875rem" }} />
          )}
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontWeight: conversation.isRead ? "normal" : "600",
            }}
          >
            {conversation.customer?.name}
          </Box>
        </Box>
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontWeight: conversation.isRead ? "normal" : "600",
            minHeight: "0.25rem",
          }}
        >
          {conversation.latestMessage.subject}
        </Box>
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontWeight: "500",
            alignItems: "flex-start",
            color: (theme) => theme.fielderColors.mutedText,
          }}
        >
          {conversation.latestMessage.bodyPlain}
        </Box>
        {conversation.tags && conversation.tags.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "0.25rem",
              marginTop: "0.5rem",
            }}
          >
            {conversation.tags.map((tag) => (
              <TagChip key={tag.id} tag={tag} />
            ))}
          </Box>
        ) : null}
      </Box>

      {/* Right Box */}
      <Box
        sx={{
          whiteSpace: "nowrap",
          fontWeight: conversation.isRead ? "500" : "600",
          flexShrink: 0,
        }}
      >
        {formatDateSent(
          conversation.latestMessage.dateSent,
          user?.organization?.timeZone ?? "Etc/UTC"
        )}
      </Box>
    </Box>
  )
}
