import React from "react"
import Box from "@mui/material/Box"

import BasicInfoStatic from "./BasicInfoStatic"
import ProductDetailsStatic from "./ProductDetailsStatic"
import Sidebar from "./Sidebar"
import { OrganizationItem } from "~/types"

interface Props {
  readonly currencyCode: string
  readonly item: OrganizationItem
  readonly onImageUpload?: () => void
}

function ProductStatic({ currencyCode, item, onImageUpload }: Props) {
  return (
    <Box
      sx={(theme) => {
        return {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          flex: 1,
          [theme.breakpoints.up("md")]: {
            flexDirection: "row",
          },
        }
      }}
    >
      <Sidebar item={item} onImageUpload={onImageUpload} />
      <Box
        sx={(theme) => {
          return {
            flex: 1,
            paddingLeft: "0",
            marginTop: "1rem",
            [theme.breakpoints.up("md")]: {
              padding: "0rem 1rem 1rem 2rem",
              marginTop: 0,
            },
          }
        }}
      >
        <BasicInfoStatic item={item} showBottomDivider />
        <ProductDetailsStatic currencyCode={currencyCode} item={item} />
      </Box>
    </Box>
  )
}

export default ProductStatic
