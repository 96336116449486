import gql from "graphql-tag"

export const ALL_ORGANIZATION_ITEMS = gql`
  query AllOrganizationItems(
    $filter: String
    $sortBy: String
    $sortDir: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $onlyInStockItems: Boolean
    $organizationItemListId: ID
    $status: OrganizationItemStatusFilter
    $itemTypes: [ItemType!]
    $stockTypes: [StockType!]
  ) {
    allOrganizationItems(
      input: {
        filter: $filter
        sortBy: $sortBy
        sortDir: $sortDir
        first: $first
        last: $last
        after: $after
        before: $before
      }
      organizationItemFilter: {
        onlyInStockItems: $onlyInStockItems
        organizationItemListId: $organizationItemListId
        status: $status
        itemTypes: $itemTypes
        stockTypes: $stockTypes
      }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          code
          name
          description
          category
          isActive
          isArchived
          isIntendedForResale
          isAvailable
          showComponentsOnTransactions
          type
          stockType
          msrp
          wholesalePrice
          leadTimeDays
          palletQty
          packSize
          isPackBreakable
          qtyOnHand
          reorderPoint
          reorderQty
          pricingStrategy
          unitSalePrice
          unitSalePriceMarkup
          isTopLevel
          createdAt
          createdBy {
            id
            email
          }
          updatedAt
          updatedBy {
            id
            email
          }
        }
      }
    }
  }
`
