import React from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import { Theme } from "@mui/material/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { ErrorMessage, Seo } from "../components"
import MasterInfoRow from "../components/MasterInfoRow"
import MasterInfoItem from "../components/MasterInfoItem"
import TotalRow from "../components/TotalRow"
import TotalRowContent from "../components/TotalRowContent"
import invoiceLogo from "../images/invoice_logo.png"
import { GET_INVOICE_BY_ID } from "../queries/getInvoiceById"
import {
  formatAddress,
  formatPersonName,
  formatPhoneNumber,
  formatDiscount,
  formatMoney,
  formatTaxRate,
  getJobDocumentDisplayNumber,
  formatDate,
} from "../util"
import orderBy from "lodash/orderBy"
import { GetInvoiceByIdData } from "../types"
import PublicAppBar from "../components/PublicAppBar"

const formWidth = 900
const breakpoint1 = 768
const breakpoint2 = 924

const classes = {
  invoiceMeta: {
    display: "flex",
    flexDirection: "column",
    fontSize: "0.95rem",
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: "3.125rem",
    maxWidth: "25%",
    minWidth: "65px",
  },
  invoiceMetaItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

    "& span": {
      textAlign: "right",
      marginLeft: "0.625rem",
    },
  },
  organizationInfoItem: {
    width: "50%",
  },
} as const

function CustomerInvoice() {
  const { t } = useTranslation()
  const { id } = useParams()

  const { loading, data, error } = useQuery<GetInvoiceByIdData>(GET_INVOICE_BY_ID, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  })

  const invoice = data?.getInvoiceById
  const currencyCode = invoice?.currencyCode

  // Get the sorted tax amounts. Can't use Array.sort here because it
  // mutates the original array, which Apollo Client will complain about.
  const taxRateAmounts = invoice?.taxSummary?.taxRateAmounts
    ? orderBy(invoice.taxSummary?.taxRateAmounts, ["amount"], ["desc"])
    : []

  return (
    <>
      <Seo lang="en" title={t("invoice")} />
      <PublicAppBar />
      <Box
        component="main"
        sx={(theme: Theme) => ({
          width: "auto",
          fontSize: "0.375rem",
          mx: 2,
          [theme.breakpoints.up(breakpoint1)]: {
            fontSize: "0.75rem",
          },
          [theme.breakpoints.up(breakpoint2)]: {
            width: formWidth,
            fontSize: "0.875rem",
            marginLeft: "auto",
            marginRight: "auto",
          },
          "@media print": {
            margin: 0,
          },
        })}
      >
        {!loading && error ? (
          <Box
            sx={{
              textAlign: "center",
              marginTop: "10%",
              fontWeight: 600,
              fontSize: "1.2rem",
              color: (theme) => theme.palette.error.main,
            }}
          >
            <ErrorMessage
              data-testid="error-message"
              message={t("error.invoiceNotFound.message")}
              title={t("error.invoiceNotFound.title")}
            />
          </Box>
        ) : null}
        {!error && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                my: 3,
                "@media print": {
                  display: "none",
                },
              }}
            >
              <Button
                data-testid="printBtn"
                onClick={() => window.print()}
                sx={(theme) => ({
                  display: "none",
                  [theme.breakpoints.up(breakpoint1)]: {
                    display: "inline-flex",
                  },
                })}
                variant="outlined"
              >
                {t("print")}
              </Button>
              <Box>
                {/* <Button data-testid="payInvoicenBtn" variant="outlined">
                  {t("payInvoice")}
                </Button> */}
              </Box>
            </Box>
            <Paper
              elevation={3}
              sx={(theme: Theme) => ({
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                backgroundColor: "#ffffff",
                color: theme.fielderScreens.estimateRequest.color,
                mt: 0,
                mb: "1.5rem",
                p: "1.5rem",
                [theme.breakpoints.up(breakpoint1)]: {
                  mb: "3rem",
                  p: "1.5rem",
                  minHeight: "800px",
                },
                "& label": {
                  color: "#212121",
                },
                "@media print": {
                  paddingBottom: "0.5rem",
                  border: "none",
                  boxShadow: "none",
                  fontSize: "0.75rem",
                  minHeight: "100px",
                },
              })}
            >
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "25%",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : null}
              {!loading && invoice ? (
                <Box>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        flexGrow: 1,
                        flexShrink: 1,
                        maxWidth: "50%",
                        "& img": {
                          maxWidth: "100%",
                        },
                      }}
                    >
                      <img alt="logo" src={invoiceLogo} />
                    </Box>
                    <Box sx={classes.invoiceMeta}>
                      <Box sx={classes.invoiceMetaItem}>
                        <label>{t("invoice")} #</label>
                        <span>{getJobDocumentDisplayNumber(invoice)}</span>
                      </Box>
                      <Box sx={classes.invoiceMetaItem}>
                        <label>{t("dateCreated")}</label>
                        <span>
                          {formatDate(
                            invoice.createdAt,
                            t("format:dateFormat.short"),
                            invoice.job.organization.timeZone
                          )}
                        </span>
                      </Box>
                      <Box sx={classes.invoiceMetaItem}>
                        <label>{t("dueDate")}</label>
                        <span>
                          {formatDate(
                            invoice.dueDate,
                            t("format:dateFormat.short"),
                            invoice.job.organization.timeZone
                          )}
                        </span>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={(theme) => ({
                      px: "0.3125rem",
                      [theme.breakpoints.up(breakpoint1)]: {
                        px: "0.9375rem",
                      },
                    })}
                  >
                    <Box
                      sx={(theme) => ({
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        fontSize: "1.0em",
                        my: "0.625rem",
                        [theme.breakpoints.up(breakpoint1)]: {
                          my: "0.9375rem",
                        },
                        [theme.breakpoints.up(breakpoint2)]: {
                          my: "1.25rem",
                        },
                      })}
                    >
                      <Box sx={classes.organizationInfoItem}>
                        <Box>{invoice?.job?.organization?.tradeName}</Box>
                        {invoice?.job?.organization?.address ? (
                          <Box>{formatAddress(invoice.job.organization.address.addressString)}</Box>
                        ) : null}
                      </Box>
                      <Box sx={classes.organizationInfoItem}>
                        {invoice?.job?.organization?.phoneNumber ? (
                          <Box>
                            {t("phone")}: {formatPhoneNumber(invoice.job.organization.phoneNumber)}
                          </Box>
                        ) : null}
                        {invoice?.job?.organization?.email ? (
                          <Box>
                            {t("email")}: {invoice.job.organization.email}
                          </Box>
                        ) : null}
                        {invoice?.job?.organization?.websiteURL ? (
                          <Box>
                            {t("web")}: {invoice.job.organization.websiteURL}
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                    <Box
                      sx={(theme) => ({
                        my: "0.625rem",
                        paddingTop: "1.25rem",
                        borderTop: "1px solid rgba(0,0,0,0.12)",
                        [theme.breakpoints.up(breakpoint1)]: {
                          my: "0.9375rem",
                        },
                        [theme.breakpoints.up(breakpoint2)]: {
                          my: "1.25rem",
                        },
                      })}
                    >
                      <MasterInfoRow>
                        <MasterInfoItem>
                          <label>{t("billTo")}</label>
                          {invoice.billingContact ? (
                            <>
                              {invoice?.job?.customer?.type == "BUSINESS" && (
                                <Box>{invoice.job.customer.name}</Box>
                              )}
                              <Box>{formatPersonName(invoice.billingContact)}</Box>
                              {invoice.billingContact.address ? (
                                <Box>
                                  {formatAddress(invoice.billingContact.address.addressString)}
                                </Box>
                              ) : null}
                            </>
                          ) : null}
                        </MasterInfoItem>
                        <MasterInfoItem>
                          <label>{t("jobAddress")}</label>
                          {invoice?.job?.address ? (
                            <Box>{formatAddress(invoice.job.address.addressString)}</Box>
                          ) : null}
                        </MasterInfoItem>
                      </MasterInfoRow>
                      <MasterInfoRow>
                        <MasterInfoItem style={{ width: "100%" }}>
                          <label>{t("description")}</label>
                          <Box>{invoice.description}</Box>
                        </MasterInfoItem>
                      </MasterInfoRow>
                    </Box>
                    <Table
                      size="small"
                      sx={(theme) => ({
                        marginTop: "1.875rem",
                        "& th": {
                          fontWeight: "bold",
                          fontSize: "0.9rem !important",
                          lineHeight: "1.0rem !important",
                          paddingLeft: 0,
                          py: "0.3125rem",
                          paddingBottom: "0.3125rem",
                          [theme.breakpoints.up(breakpoint1)]: {
                            py: "0.625rem",
                          },
                          [theme.breakpoints.up(breakpoint2)]: {
                            paddingTop: "0.9375rem",
                          },
                          "&:last-child": {
                            paddingRight: 0,
                          },
                        },
                        "& td": {
                          fontSize: "0.84rem !important",
                          color: theme.fielderScreens.estimateRequest.color,
                          paddingLeft: 0,
                          "&:last-child": {
                            paddingRight: 0,
                          },
                        },
                      })}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell width="65%">{t("item")}</TableCell>
                          <TableCell align="right" width="10%">
                            {t("quantity")}
                          </TableCell>
                          <TableCell align="right" width="15%">
                            {t("unitPrice")}
                          </TableCell>
                          <TableCell align="right" width="10%">
                            {t("amount")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoice?.lineItems?.map((li) => (
                          <>
                            <TableRow key={li.id}>
                              <TableCell>
                                <strong>{li.organizationItem?.name}</strong>
                                <Box>{li.organizationItem?.code}</Box>
                              </TableCell>
                              <TableCell align="right">{li.quantity}</TableCell>
                              <TableCell align="right">
                                {formatMoney(currencyCode, li.unitPrice)}
                              </TableCell>
                              <TableCell align="right">
                                {formatMoney(currencyCode, li.total)}
                              </TableCell>
                            </TableRow>
                            {li.showDetails
                              ? li.lineItemDetails
                                  ?.slice()
                                  ?.sort((a, b) => a.number - b.number)
                                  ?.map((d) => (
                                    <TableRow
                                      key={d.organizationItem?.id}
                                      sx={{
                                        fontSize: "0.84rem",
                                        backgroundColor: "#fafafa",
                                      }}
                                    >
                                      <TableCell>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginLeft: "1.25rem",
                                          }}
                                        >
                                          <Box>
                                            <strong>{d.organizationItem?.name}</strong>
                                          </Box>
                                          <Box>{d.organizationItem?.code}</Box>
                                        </Box>
                                      </TableCell>
                                      <TableCell align="right">{d.quantity}</TableCell>
                                      <TableCell align="right">
                                        {formatMoney(currencyCode, d.unitSalePrice)}
                                      </TableCell>
                                      <TableCell align="right" />
                                      <TableCell colSpan={1} />
                                    </TableRow>
                                  ))
                              : null}
                          </>
                        ))}
                      </TableBody>
                    </Table>
                    <Box
                      sx={(theme: Theme) => ({
                        marginTop: "0.625rem",
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        fontSize: "0.875rem",
                        [theme.breakpoints.up(breakpoint1)]: {
                          marginTop: "0.625rem",
                        },
                        [theme.breakpoints.up(breakpoint2)]: {
                          marginTop: "1.25rem",
                        },
                      })}
                    >
                      <TotalRow>
                        <TotalRowContent>
                          <label>{t("subTotal")}</label>
                          <span>
                            {invoice.subTotal ? formatMoney(currencyCode, invoice.subTotal) : null}
                          </span>
                        </TotalRowContent>
                      </TotalRow>
                      {invoice?.discount?.value && invoice.discount.value > 0 ? (
                        <TotalRow>
                          <TotalRowContent>
                            <label>{t("discount")}</label>
                            <span>{formatDiscount(invoice)}</span>
                          </TotalRowContent>
                        </TotalRow>
                      ) : null}
                      {taxRateAmounts?.length > 0 && (
                        <Box
                          style={{
                            fontSize: "0.75rem",
                            marginTop: "0.25rem",
                            marginBottom: "0.25rem",
                          }}
                        >
                          <label style={{ fontSize: "0.875rem" }}>{t("tax")}</label>
                          {taxRateAmounts?.map((taxRateAmount) => (
                            <TotalRow key={taxRateAmount?.taxRate?.id}>
                              <TotalRowContent>
                                <Box>
                                  <Box>{taxRateAmount?.taxRate?.name}</Box>
                                  <Box sx={{ fontSize: "0.6875rem", color: "#747474" }}>
                                    <em>
                                      {taxRateAmount?.taxRate?.rate
                                        ? formatTaxRate(taxRateAmount.taxRate.rate)
                                        : null}
                                    </em>
                                  </Box>
                                </Box>
                                <Box data-testid="invoiceDialogField_totalTax">
                                  {taxRateAmount?.amount
                                    ? formatMoney(currencyCode, taxRateAmount.amount)
                                    : null}
                                </Box>
                              </TotalRowContent>
                            </TotalRow>
                          ))}
                        </Box>
                      )}
                      <TotalRow>
                        <TotalRowContent>
                          <label>{t("totalTax")}</label>
                          <span>
                            {invoice?.taxSummary?.total
                              ? formatMoney(currencyCode, invoice.taxSummary.total)
                              : null}
                          </span>
                        </TotalRowContent>
                      </TotalRow>
                      <TotalRow
                        style={{
                          marginTop: "0.625rem",
                          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                          paddingTop: "0.625rem",
                        }}
                      >
                        <TotalRowContent style={{ fontWeight: "bold" }}>
                          <label>{t("total")}</label>
                          <span>
                            {invoice.total ? formatMoney(currencyCode, invoice.total) : null}
                          </span>
                        </TotalRowContent>
                      </TotalRow>
                    </Box>
                  </Box>
                </Box>
              ) : null}
            </Paper>
          </>
        )}
      </Box>
    </>
  )
}

export default CustomerInvoice
