import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import numeral from "numeral"
import { useAuth } from "../../../../context/AuthContext"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TableHead from "@mui/material/TableHead"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import EditIcon from "@mui/icons-material/EditOutlined"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import LockIcon from "@mui/icons-material/LockOutlined"

import { DefaultPermission, TaxRateGroup } from "~/types"

interface TaxRateGroupRowProps {
  readonly onClickArchive: () => void
  readonly onClickEdit: () => void
  readonly taxRateGroup: TaxRateGroup
}

function TaxRateGroupRow({ onClickArchive, onClickEdit, taxRateGroup }: TaxRateGroupRowProps) {
  const { t } = useTranslation()
  const [openDetails, setOpenDetails] = useState<boolean>(false)
  const { hasPermissions } = useAuth()

  return (
    <>
      <TableRow key={taxRateGroup.id} sx={{ height: "60px" }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            onClick={() => setOpenDetails(!openDetails)}
            size="small"
          >
            {openDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{taxRateGroup.name}</TableCell>
        <TableCell>{numeral(taxRateGroup.totalTaxRate).format("0.[0000]%")}</TableCell>
        <TableCell align="right" style={{ paddingRight: "0.5rem" }}>
          <Box sx={classes.rowButtonContainer}>
            {taxRateGroup.isLocked ? (
              <IconButton
                aria-label="edit"
                data-testid="editTaxRateGroupBtn"
                disabled
                sx={classes.rowButton}
              >
                <LockIcon fontSize="small" style={{ color: "rgba(0, 0, 0, 0.54)" }} />
              </IconButton>
            ) : null}
            {hasPermissions?.([DefaultPermission.UpdateTaxRateGroup]) && !taxRateGroup.isLocked ? (
              <IconButton
                aria-label="edit"
                data-testid="editTaxRateGroupBtn"
                onClick={onClickEdit}
                sx={classes.rowButton}
              >
                <EditIcon />
              </IconButton>
            ) : null}
            {hasPermissions?.([DefaultPermission.UpdateTaxRateGroup]) && !taxRateGroup.isLocked ? (
              <IconButton
                aria-label="archive"
                data-testid="archiveTaxRateGroupBtn"
                onClick={onClickArchive}
                sx={classes.rowButton}
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
          </Box>
        </TableCell>
      </TableRow>
      <TableRow style={{ border: "none" }}>
        <TableCell colSpan={4} style={{ padding: 0, border: "none" }}>
          <Collapse in={openDetails} timeout="auto" unmountOnExit>
            <Box sx={classes.detailsContainer}>
              <Table size="small" sx={classes.detailsTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("taxName")}</TableCell>
                    <TableCell>{t("taxRate")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {taxRateGroup?.taxRates?.map((tr) => (
                    <TableRow key={tr.id}>
                      <TableCell>{tr.name}</TableCell>
                      <TableCell>{numeral(tr.rate).format("0.[0000]%")}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const classes = {
  rowButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  rowButton: {
    marginRight: "0.5rem",
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "rgb(255, 200, 100, 0.10)",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    paddingBottom: "0.625rem",
  },
  detailsTable: {
    width: "70%",
    alignSelf: "center",
    "& td": {
      border: "none",
    },
  },
}

export default TaxRateGroupRow
