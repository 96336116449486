import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { Navigate, NavigateProps, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "@apollo/client"
import UserStatic from "./components/UserStatic"
import Divider from "@mui/material/Divider"
import Paper from "@mui/material/Paper"
import EditIcon from "@mui/icons-material/EditOutlined"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import RestoreIcon from "@mui/icons-material/RestoreFromTrashOutlined"
import CircularProgress from "@mui/material/CircularProgress"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"

import {
  MainLayout,
  PageHeader,
  SectionContent,
  SectionHeader,
  Seo,
  SnackbarMessage,
} from "~/components"
import UserForm from "./components/UserForm"
import { ARCHIVE_USER } from "~/queries/archiveUser"
import { UNARCHIVE_USER } from "~/queries/unarchiveUser"
import { EDIT_USER } from "~/queries/editUser"
import { GET_USER_BY_ID } from "~/queries/getUserById"
import { SEND_ACCOUNT_ACTIVATION_CODE } from "~/queries/sendAccountActivationCode"
import { formatPersonName, parseGraphQLErrorCode, SETTINGS } from "~/util"
import {
  EditUserResponse,
  Snack,
  UserFormInput,
  EditUserInput,
  ArchiveUserResponse,
  ArchiveUserInput,
  UnarchiveUserResponse,
  UnarchiveUserInput,
  UserStatus,
  DefaultPermission,
} from "~/types"
import { useAuth } from "~/context/AuthContext"
import UserAvatarManager from "~/components/UserAvatarManager"

function EditUser() {
  const { hasPermissions, user: currentUser } = useAuth()
  const { t } = useTranslation()
  const { id } = useParams()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const [snack, setSnack] = useState<Snack>()
  const [inEditMode, setInEditMode] = useState<boolean>(false)
  const [isDeactivateUserDialogOpen, setIsDeactivateUserDialogOpen] = useState<boolean>(false)
  const [isReactivateUserDialogOpen, setIsReactivateUserDialogOpen] = useState<boolean>(false)
  const [originalStatus, setOriginalStatus] = useState<UserStatus>()

  const [editUser, { loading: editUserLoading }] = useMutation<EditUserResponse, EditUserInput>(
    EDIT_USER,
    {
      onCompleted: () => {
        setInEditMode(false)
        setSnack({ messageKey: "messages.changesSaved", variant: "success" })
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    }
  )

  const [archiveUser, { loading: archiveUserLoading }] = useMutation<
    ArchiveUserResponse,
    ArchiveUserInput
  >(ARCHIVE_USER, {
    onCompleted: () => {
      setInEditMode(false)
      setIsDeactivateUserDialogOpen(false)

      if (originalStatus === UserStatus.UNVERIFIED) {
        setRedirectTo({
          to: "/app/settings/users/list",
          replace: false,
          state: {
            snack: {
              messageKey: "messages.userDeleted",
              messageOptions: { name: formatPersonName(user) },
              variant: "success",
            },
          },
        })
      } else {
        setSnack({ messageKey: "messages.changesSaved", variant: "success" })
      }
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [unarchiveUser, { loading: unarchiveUserLoading }] = useMutation<
    UnarchiveUserResponse,
    UnarchiveUserInput
  >(UNARCHIVE_USER, {
    onCompleted: () => {
      setInEditMode(false)
      setIsReactivateUserDialogOpen(false)
      setSnack({ messageKey: "messages.changesSaved", variant: "success" })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [sendAccountActivationCode, { loading: sendAccountActivationCodeLoading }] = useMutation(
    SEND_ACCOUNT_ACTIVATION_CODE,
    {
      onCompleted: () => {
        setRedirectTo({
          to: "/app/settings/users/list",
          replace: false,
          state: {
            snack: {
              messageKey: "messages.accountActivationCodeResent",
              variant: "success",
            },
          },
        })
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    }
  )

  const {
    data,
    loading: queryLoading,
    refetch: refetchUser,
  } = useQuery(GET_USER_BY_ID, {
    variables: { id },
  })

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  const mutationsLoading =
    editUserLoading ||
    archiveUserLoading ||
    sendAccountActivationCodeLoading ||
    unarchiveUserLoading

  const user = data?.getUserById

  return (
    <>
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Seo title={t("editUser")} />
      <MainLayout activeSection={SETTINGS}>
        <Box
          sx={{
            margin: "0 1.25rem",
            paddingBottom: "6.25rem",
          }}
        >
          <PageHeader
            breadcrumbs={[
              { to: SETTINGS.path, titleKey: SETTINGS.titleKey },
              { to: "/app/settings/users/list", titleKey: "users" },
            ]}
            icon={SETTINGS.icon}
            leafTitleKey="editUser"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: 800,
            }}
          >
            {queryLoading ? (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Paper
                sx={{
                  marginTop: "1.25rem",
                  marginBottom: "6.25rem",
                }}
              >
                <SectionHeader>
                  <label>{t("basicInfo")}</label>
                  <Box sx={{ display: "flex", gap: "1rem" }}>
                    {!inEditMode && hasPermissions?.([DefaultPermission.UpdateUser]) ? (
                      <Button
                        onClick={() => setInEditMode(true)}
                        size="small"
                        startIcon={<EditIcon />}
                        sx={(theme) => ({
                          [theme.breakpoints.down("xs")]: {
                            marginLeft: 0,
                          },
                        })}
                        variant="text"
                      >
                        {t("edit")}
                      </Button>
                    ) : (
                      <Box sx={{ display: "flex", gap: "1rem" }}>
                        {hasPermissions?.([DefaultPermission.UpdateUser]) ? (
                          user?.status === UserStatus.DEACTIVATED ? (
                            <Button
                              onClick={() => setIsReactivateUserDialogOpen(true)}
                              size="small"
                              startIcon={<RestoreIcon />}
                              sx={(theme) => ({
                                [theme.breakpoints.down("xs")]: {
                                  marginLeft: 0,
                                },
                              })}
                              variant="text"
                            >
                              {t("reactivateUser")}
                            </Button>
                          ) : (
                            <Button
                              onClick={() => setIsDeactivateUserDialogOpen(true)}
                              size="small"
                              startIcon={<DeleteIcon />}
                              sx={(theme) => ({
                                color:
                                  user?.status === UserStatus.UNVERIFIED
                                    ? theme.fielderColors.error
                                    : theme.fielderColors.text,
                                "&:hover": {
                                  borderColor:
                                    user?.status === UserStatus.UNVERIFIED
                                      ? theme.fielderColors.error
                                      : theme.fielderColors.text,
                                  backgroundColor:
                                    user?.status === UserStatus.UNVERIFIED
                                      ? "hsla(360, 100%, 98%, 1)"
                                      : theme.fielderColors.white,
                                },

                                [theme.breakpoints.down("xs")]: {
                                  marginLeft: 0,
                                },
                              })}
                              variant="text"
                            >
                              {user?.status === UserStatus.UNVERIFIED
                                ? t("deleteUser")
                                : t("deactivateUser")}
                            </Button>
                          )
                        ) : null}
                      </Box>
                    )}
                  </Box>
                </SectionHeader>
                <Divider />
                <SectionContent
                  sx={(theme) => {
                    return {
                      display: "flex",
                      flexDirection: "column",
                      [theme.breakpoints.up("md")]: {
                        padding: "1.25rem 2rem",
                        flexDirection: "row",
                        gap: "2rem",
                      },
                    }
                  }}
                >
                  <Box
                    sx={(theme) => {
                      return {
                        display: "flex",
                        margin: 0,
                        padding: 0,
                        fontSize: "0.875rem",
                        [theme.breakpoints.up("sm")]: {},
                        [theme.breakpoints.up("md")]: {
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          maxWidth: "200px",
                        },
                      }
                    }}
                  >
                    <UserAvatarManager avatar={user?.avatar} userId={user?.id} />
                  </Box>
                  {inEditMode ? (
                    <UserForm
                      loading={mutationsLoading}
                      onCancel={() => {
                        refetchUser()
                        setInEditMode(false)
                      }}
                      onDeactivate={() => setIsDeactivateUserDialogOpen(true)}
                      onReactivate={() => setIsReactivateUserDialogOpen(true)}
                      onResendActivationCode={() => {
                        sendAccountActivationCode({
                          variables: {
                            username: user.email,
                          },
                        })
                      }}
                      onSubmit={(updatedUser: UserFormInput) => {
                        editUser({
                          variables: updatedUser as EditUserInput,
                        })
                      }}
                      user={user}
                    />
                  ) : (
                    <UserStatic
                      onDeactivate={() => setIsDeactivateUserDialogOpen(true)}
                      onReactivate={() => setIsReactivateUserDialogOpen(true)}
                      userObject={user}
                    />
                  )}
                </SectionContent>
              </Paper>
            )}
          </Box>
        </Box>
      </MainLayout>
      {isDeactivateUserDialogOpen ? (
        <Dialog
          aria-describedby="deactivate-user-dialog-description"
          aria-labelledby="deactivate-user-dialog-title"
          onClose={() => setIsDeactivateUserDialogOpen(false)}
          open={isDeactivateUserDialogOpen}
        >
          <DialogTitle id="deactivate-user-dialog-title">{t("areYouSure")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="deactivate-user-dialog-description">
              {user.status === UserStatus.UNVERIFIED
                ? t("component.deleteUserDialog.confirmationPrompt", {
                    name: formatPersonName(user),
                  })
                : t("component.deactivateUserDialog.confirmationPrompt", {
                    name: formatPersonName(user),
                  })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={mutationsLoading}
              onClick={() => setIsDeactivateUserDialogOpen(false)}
            >
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              disabled={mutationsLoading}
              onClick={() => {
                setOriginalStatus(user.status)
                archiveUser({ variables: { id: user.id } })
              }}
            >
              {t("yes")}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {isReactivateUserDialogOpen ? (
        <Dialog
          aria-describedby="reactivate-user-dialog-description"
          aria-labelledby="reactivate-user-dialog-title"
          onClose={() => setIsReactivateUserDialogOpen(false)}
          open={isReactivateUserDialogOpen}
        >
          <DialogTitle id="reactivate-user-dialog-title">{t("areYouSure")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="reactivate-user-dialog-description">
              {t("component.reactivateUserDialog.confirmationPrompt", {
                name: formatPersonName(user),
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={mutationsLoading}
              onClick={() => setIsReactivateUserDialogOpen(false)}
            >
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              disabled={mutationsLoading}
              onClick={() => unarchiveUser({ variables: { id: user.id } })}
            >
              {t("yes")}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  )
}

export default EditUser
