import gql from "graphql-tag"

export const SEND_INVOICE_TO_PLUGIN = gql`
  mutation SendInvoiceToPlugin($invoiceId: ID!) {
    sendInvoiceToPlugin(input: { invoiceId: $invoiceId }) {
      wasSent
      invoice {
        id
        issuedDate
        pluginProvider {
          id
          name
          category
        }
        pluginProviderUrl
        externalId
        dateSentToPluginProvider
      }
    }
  }
`
