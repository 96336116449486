/* eslint-disable react/no-array-index-key */
/* eslint-disable react/iframe-missing-sandbox */
/* eslint-disable react/no-danger */
import React from "react"
import { useTranslation } from "react-i18next"
import DOMPurify from "dompurify"
import dayjs from "dayjs"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import IncomingIcon from "@mui/icons-material/MoveToInboxOutlined"
import OutgoingIcon from "@mui/icons-material/OutboxOutlined"
import AttachmentIcon from "@mui/icons-material/AttachFileOutlined"
import { Tooltip } from "@mui/material"

import { type Attachment, type InboxMessage, MessageDirection } from "~/types/apiTypes"
import { useAuth } from "~/context/AuthContext"
import { formatDate } from "~/util"
import ReplyIcon from "~/components/icons/ReplyIcon"
import ReplyAllIcon from "~/components/icons/ReplyAllIcon"
import ForwardIcon from "~/components/icons/ForwardIcon"
import AttachmentPreview from "./AttachmentPreview"

interface Props {
  readonly message: InboxMessage
  readonly isLatestMessage: boolean
}

export default function ConversationMessage({ message, isLatestMessage = false }: Props) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [openAttachment, setOpenAttachment] = React.useState<Attachment | null | undefined>()

  const sortedEvents =
    message.events?.slice()?.sort((a, b) => {
      return dayjs(a.timestamp).isAfter(dayjs(b.timestamp)) ? -1 : 1
    }) ?? []
  const deliveredEvent = sortedEvents.find((event) => event.type === "DELIVERED")
  const openedEvent = sortedEvents.find((event) => event.type === "OPENED")
  const failedEvent = sortedEvents.find((event) => event.type === "FAILED")
  const complainedEvent = sortedEvents.find((event) => event.type === "COMPLAINED")

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        padding: "0.5rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          paddingTop: isLatestMessage ? "0.5rem" : "0.15rem",
        }}
      >
        {message.direction === MessageDirection.CUSTOMER_REPLY ||
        message.direction === MessageDirection.INCOMING ? (
          <IncomingIcon sx={{ fontSize: "1.2rem" }} />
        ) : (
          <OutgoingIcon sx={{ fontSize: "1.2rem" }} />
        )}
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: "2.5rem",
          }}
        >
          <Box sx={{ fontWeight: "600", fontSize: "1rem" }}>
            {t(`page.inbox.messageDirectionAndChannel.${message.direction}_${message.channel}`)}
          </Box>
          {isLatestMessage ? (
            <Box>
              <Tooltip title={t("page.inbox.reply")}>
                <IconButton>
                  <ReplyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("page.inbox.replyAll")}>
                <IconButton>
                  <ReplyAllIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("page.inbox.forward")}>
                <IconButton>
                  <ForwardIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ) : null}
          <Box sx={{ fontSize: "0.875rem" }}>
            {formatDate(
              message.dateSent,
              "ddd MMM D, h:mm a",
              user?.organization?.timeZone ?? "Etc/UTC"
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            color: (theme) => theme.fielderColors.mutedText,
          }}
        >
          <Box component="span">{t("page.inbox.from")}:</Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "0.25rem" }}>
            <Box component="span">{message.sender.name}</Box>
            <Box component="span">&lt;{message.sender.messageAddress}&gt;</Box>
          </Box>
        </Box>
        {message.direction === MessageDirection.OUTGOING ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              color: (theme) => theme.fielderColors.mutedText,
            }}
          >
            <Box component="span">{t("page.inbox.to")}:</Box>
            {message.toRecipients?.map((recipient, index) => (
              <Box
                key={`${recipient.id}-${index}`}
                sx={{ display: "flex", flexDirection: "row", gap: "0.25rem" }}
              >
                <Box component="span">{recipient.name}</Box>
                <Box component="span">
                  &lt;{recipient.messageAddress}&gt;
                  {index < (message.toRecipients?.length ?? 1) - 1 ? ", " : null}
                </Box>
              </Box>
            ))}
          </Box>
        ) : null}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            color: (theme) => theme.fielderColors.mutedText,
            marginBottom: "0.5rem",
          }}
        >
          <Box component="span">{t("page.inbox.subject")}:</Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "0.25rem" }}>
            <Box component="span">{message.subject}</Box>
          </Box>
        </Box>
        {message.direction === MessageDirection.OUTGOING ? (
          <Box sx={{ fontWeight: "600", fontSize: "0.875rem" }}>
            {deliveredEvent && !openedEvent ? (
              <Box>
                {t("page.inbox.events.delivered", {
                  date: formatDate(
                    deliveredEvent.timestamp,
                    t("format:dateFormat.shortWithAtTime"),
                    user?.organization?.timeZone ?? "Etc/UTC"
                  ),
                })}
              </Box>
            ) : null}
            {openedEvent ? (
              <Box sx={{ color: "#166534", fontWeight: "600" }}>
                {t("page.inbox.events.opened", {
                  date: formatDate(
                    openedEvent.timestamp,
                    t("format:dateFormat.shortWithAtTime"),
                    user?.organization?.timeZone ?? "Etc/UTC"
                  ),
                })}
              </Box>
            ) : null}
            {failedEvent ? (
              <Box sx={{ color: "#991b1b", fontWeight: "600" }}>
                {t("page.inbox.events.failed", {
                  recipientEmail: failedEvent.messageAddress,
                })}
              </Box>
            ) : null}
            {complainedEvent ? (
              <Box sx={{ color: "#ea580c", fontWeight: "600" }}>
                {t("page.inbox.events.complained", {
                  recipientEmail: complainedEvent.messageAddress,
                })}
              </Box>
            ) : null}
          </Box>
        ) : null}
        {message.attachments?.length ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              gap: "0.5rem",
              fontSize: "0.875rem",
            }}
          >
            {message.attachments.map((attachment: Attachment) => {
              return (
                <Box
                  key={attachment.id}
                  onClick={() => {
                    setOpenAttachment(attachment)
                  }}
                >
                  <Box
                    sx={{
                      display: "inline-flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "0.25rem",
                      color: "#1e40af",
                      padding: "0.25rem 0.5rem 0.25rem 0.15rem ",
                      borderRadius: "0.25rem",
                      whiteSpace: "nowrap",
                      "&:hover": {
                        backgroundColor: "#eff6ff",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <AttachmentIcon sx={{ fontSize: "0.875em" }} />
                    <Box component="span">{attachment.name}</Box>
                  </Box>
                </Box>
              )
            })}
          </Box>
        ) : null}
        <Box sx={{ marginRight: "1rem", marginTop: "0.875rem" }}>
          {message.bodyHtml ? (
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.bodyHtml) }} />
          ) : (
            message.bodyPlain
          )}
        </Box>
      </Box>
      {openAttachment ? (
        <AttachmentPreview
          attachment={openAttachment}
          onClickNext={() => {
            // find the index of openAttachment in message.attachments
            const index =
              message.attachments?.findIndex((attachment) => attachment.id === openAttachment.id) ??
              undefined
            if (typeof index === "undefined") {
              setOpenAttachment(undefined)
            } else {
              setOpenAttachment(message.attachments?.[(index + 1) % message.attachments.length])
            }
          }}
          onClickPrevious={() => {
            // find the index of openAttachment in message.attachments
            const index =
              message.attachments?.findIndex((attachment) => attachment.id === openAttachment.id) ??
              undefined
            if (typeof index === "undefined") {
              setOpenAttachment(undefined)
            } else {
              // if index is 0, go to the last attachment
              setOpenAttachment(
                message.attachments?.[index === 0 ? message.attachments.length - 1 : index - 1]
              )
            }
          }}
          onClose={() => setOpenAttachment(undefined)}
        />
      ) : null}
    </Box>
  )
}
