import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import CircularProgress from "@mui/material/CircularProgress"
import InputAdornment from "@mui/material/InputAdornment"
import Button from "@mui/material/Button"

import { useAuth } from "~/context/AuthContext"
import { isBlank } from "~/util"
import FieldHelperText from "./FieldHelperText"

interface ValidationErrors {
  note: string | null
}

interface Props {
  readonly addNoteLoading: boolean
  readonly onAddNote: (note: string) => void
}

function NoteForm({ addNoteLoading, onAddNote }: Props) {
  const { t } = useTranslation()
  const { isImpersonating } = useAuth()
  const [note, setNote] = useState<string>("")
  const [errors, setErrors] = useState<ValidationErrors>(() => ({
    note: null,
  }))

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {addNoteLoading ? <CircularProgress color="secondary" size={24} /> : null}
              </InputAdornment>
            ),
          }}
          disabled={addNoteLoading}
          error={Boolean(errors.note)}
          fullWidth
          inputProps={{
            maxLength: 1000,
          }}
          maxRows="3"
          minRows="1"
          multiline
          name="note"
          onChange={(e: any) => {
            setNote(e.target.value)
            if (!isBlank(e.target.value)) {
              setErrors({ note: null })
            }
          }}
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              e.preventDefault()
              if (!isBlank(note)) {
                onAddNote?.(note)
              } else {
                setErrors({ note: t("component.noteForm.validation.noteRequired") })
              }
              setNote("")
            }
          }}
          placeholder={
            addNoteLoading
              ? (`${t("saving")}...` as string)
              : (t("component.noteForm.placeholder") as string)
          }
          sx={{ background: "#FFFFFF" }}
          value={note}
          variant="outlined"
        />
        <Button
          disableElevation
          disabled={isImpersonating}
          onClick={() => {
            if (!isBlank(note)) {
              onAddNote?.(note)
              setNote("")
            } else {
              setErrors({ note: t("component.noteForm.validation.noteRequired") })
            }
          }}
          sx={{ paddingLeft: "2rem", paddingRight: "2rem" }}
          variant="contained"
        >
          {t("save")}
        </Button>
      </Box>
      {errors.note ? <FieldHelperText error message={errors.note} /> : null}
    </Box>
  )
}

const classes = {
  buttonIcon: {
    fontSize: "1rem",
  },
} as const

export default NoteForm
