/* eslint-disable react/style-prop-object */
/* eslint-disable react/iframe-missing-sandbox */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React, { Suspense } from "react"
import { ApolloProvider } from "@apollo/client"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import { I18nextProvider } from "react-i18next"
import { Helmet } from "react-helmet"
import { GatsbyBrowser } from "gatsby"
import { client } from "./src/apollo/client"
import theme from "./src/themes/theme"
import i18n from "./src/i18n"
import * as Sentry from "@sentry/react"

import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import isBetween from "dayjs/plugin/isBetween"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import isoWeek from "dayjs/plugin/isoWeek"
import localizedFormat from "dayjs/plugin/localizedFormat"
import quarterOfYear from "dayjs/plugin/quarterOfYear"
import relativeTime from "dayjs/plugin/relativeTime"

dayjs.extend(advancedFormat)
dayjs.extend(isBetween)
dayjs.extend(isSameOrBefore)
dayjs.extend(timezone)
dayjs.extend(isoWeek)
dayjs.extend(localizedFormat)
dayjs.extend(quarterOfYear)
dayjs.extend(relativeTime)
dayjs.extend(utc) // put this at the end, or other plugins *might* overwrite some things that it does

export function wrapRootElement({ element }): GatsbyBrowser["wrapRootElement"] {
  console.log(`gatsby-browser:: GATSBY_APP_VERSION: ${process.env.GATSBY_APP_VERSION}`)

  Sentry.init({
    dsn: "https://42822344c40d442f8563ba9c2c5e1c9b@o505338.ingest.sentry.io/5593671",
    autoSessionTracking: true,
    sampleRate: 1.0,
    debug: false,
    sendDefaultPii: true,
    release: `Fielder-Goliath-Web@${process.env.GATSBY_APP_VERSION}`,
    tracesSampleRate: 0.7,
    enabled: process.env.GATSBY_ACTIVE_ENV === "production",
    ignoreErrors: ["ResizeObserver loop"],

    // This sets the sample rate. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.15,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        blockAllMedia: false,
        maskAllText: false,
        maskAllInputs: false,
      }),
    ],
  })

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta content="ie=edge" httpEquiv="x-ua-compatible" />
        <meta
          content="width=device-width, minimum-scale=1, initial-scale=1, shrink-to-fit=no"
          name="viewport"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700&display=swap"
          rel="stylesheet"
        />
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
        <script
          async
          defer
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&libraries=places`}
        />

        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID}`}
        />
        <script>
          {` window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID}');`}
        </script>

        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.GOOGLE_TAG_MANAGER_ID}');`}</script>

        <script type="text/javascript">
          {`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
            heap.load("${process.env.HEAP_APP_ID}");`}
        </script>

        {/* Microsoft Clarity tracking code */}
        {process.env.GATSBY_ACTIVE_ENV === "production" ? (
          <script type="text/javascript">
            {`(function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "oeemnuugtf");`}
          </script>
        ) : null}

        {/* Set the base font-size to 16px. Yes, deliberately ignoring/overriding the user's browser font-size preferences. */}
        <style type="text/css">{`
          html {
              font-size: 16px;
          }
        `}</style>
      </Helmet>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <ApolloProvider client={client}>
            <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
              <Suspense fallback={<div>Loading...</div>}>
                <CssBaseline />
                <noscript>
                  <iframe
                    height="0"
                    src="https://www.googletagmanager.com/ns.html?id=GTM-NG2M6HL"
                    style={{ display: "none", visibility: "hidden" }}
                    width="0"
                  />
                </noscript>
                <div
                  data-sentryrelease={`Fielder-Goliath-Web@${process.env.GATSBY_APP_VERSION}`}
                  style={{ display: "none", visibility: "hidden" }}
                />
                {element}
              </Suspense>
            </LocalizationProvider>
          </ApolloProvider>
        </I18nextProvider>
      </ThemeProvider>
    </>
  )
}
