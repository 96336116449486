import * as Sentry from "@sentry/react"
import dayjs, { Dayjs } from "dayjs"

import { NOT_SPECIFIED } from "./stringUtils"
import { User } from "../types"

export function createDayJS(
  date?: string | number | Date | Dayjs | null,
  timeZone?: string,
  user?: Partial<User> | null
): Dayjs | null {
  const zone = timeZone ?? "Etc/UTC"
  try {
    return date ? dayjs(date).tz(zone) : null
  } catch (err) {
    console.error(err)
    const msg = `could not create a DayJS instance with dateString: ${date} and timeZone: ${zone}`
    console.error(msg)
    Sentry.captureMessage(msg, {
      user: {
        id: user?.id,
        email: user?.email,
        username: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
      },
      level: "error",
    })
    return null
  }
}

export function formatDate(
  date?: string | number | Date | dayjs.Dayjs | null | undefined,
  formatString?: string | null,
  timeZone?: string | null,
  user?: Partial<User> | null
): string {
  if (date && timeZone) {
    try {
      return dayjs(date)
        .tz(timeZone)
        .format(formatString ?? "YYYY-MM-DD")
    } catch (err) {
      const msg = `could not format a date with dateString: ${date} and timeZone: ${timeZone}`
      console.error(msg)
      Sentry.captureMessage(msg, {
        user: {
          id: user?.id,
          email: user?.email,
          username: user?.email,
          firstName: user?.firstName,
          lastName: user?.lastName,
        },
        level: "error",
      })
      return NOT_SPECIFIED
    }
  } else {
    return NOT_SPECIFIED
  }
}
