import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"

import FieldSpinner from "./FieldSpinner"

interface Props {
  readonly disabled?: boolean
  readonly elementId?: string
  readonly error?: string
  readonly hideLabel?: boolean
  readonly loading?: boolean
  readonly onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  readonly onChange?: (value: any) => void
  readonly options: any[]
  readonly required?: boolean
  readonly size?: "small" | "medium"
  readonly style?: React.CSSProperties
  readonly value?: any
}

function TaxRateGroupSelect({
  disabled,
  elementId,
  error,
  hideLabel,
  loading,
  onBlur,
  onChange,
  options,
  required,
  size,
  style,
  value,
}: Props) {
  const { t } = useTranslation()

  if (loading) {
    return (
      <Box>
        <FieldSpinner
          message={`${t("loadingTaxRates")}...`}
          style={{ marginTop: 0, padding: "0.625rem" }}
        />
      </Box>
    )
  }

  return (
    <Autocomplete
      disableClearable
      disabled={disabled}
      getOptionLabel={(option) => {
        return option.name ?? ""
      }}
      id={elementId}
      isOptionEqualToValue={(option, value) => {
        return value.id === option.id || options.length === 1
      }}
      onBlur={onBlur}
      onChange={(event, newValue) => {
        onChange?.(newValue)
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password",
          }}
          label={!hideLabel ? t("taxRate") : undefined}
          required={required}
          size={size}
          variant="outlined"
        />
      )}
      required={required}
      style={style}
      value={value}
    />
  )
}

export default TaxRateGroupSelect
