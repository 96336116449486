import React from "react"
import { Trans, useTranslation } from "react-i18next"

import { Organization, PricingStrategy } from "~/types"
import StaticField from "~/components/StaticField"
import DefaultEstimateSettingsStatic from "./DefaultEstimateSettingsStatic"
import DefaultInvoiceSettingsStatic from "./DefaultInvoiceSettingsStatic"
import HelperTextContainer from "~/app/inventory/components/HelperTextContainer"
import Box from "@mui/material/Box"

interface Props {
  readonly organization: Organization
}

function OrganizationPreferencesStatic({ organization }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <StaticField
        label={t("language") as string}
        showBottomDivider
        value={t(`languageCodeOptions.${organization.languageCode}`)}
      />
      {organization.level >= 2 ? (
        <>
          <StaticField
            label={t("page.organizationDetails.allowJobAssignmentOverlap") as string}
            showBottomDivider
            value={organization.allowJobAssignmentOverlap ? t("yes") : t("no")}
          />
          <StaticField
            label={t("page.organizationDetails.allowBundleModsOnTxns") as string}
            showBottomDivider
            value={organization.allowBundleModsOnTxns ? t("yes") : t("no")}
          />
          <StaticField
            label={t("page.organizationDetails.bccJobEmailToOrganizationEmail") as string}
            showBottomDivider
            value={organization.bccJobEmailToOrganizationEmail ? t("yes") : t("no")}
          />
          <StaticField
            label={t("page.organizationDetails.defaultPricingStrategy.label") as string}
            renderHelpText={() => {
              return (
                <HelperTextContainer>
                  <Box>
                    <Trans
                      components={{ strong: <strong /> }}
                      i18nKey={`page.organizationDetails.defaultPricingStrategy.options.${organization.defaultPricingStrategySettings?.defaultPricingStrategy}.description`}
                    />
                  </Box>
                </HelperTextContainer>
              )
            }}
            showBottomDivider
            value={
              <Box>
                {organization.defaultPricingStrategySettings?.defaultPricingStrategy ===
                PricingStrategy.DYNAMIC
                  ? t(
                      `page.organizationDetails.defaultPricingStrategy.options.${organization.defaultPricingStrategySettings?.defaultPricingStrategy}.labelWithMarkup`,
                      {
                        markup:
                          (organization.defaultPricingStrategySettings
                            ?.defaultUnitSalePriceMarkup ?? 0.0) * 100.0,
                      }
                    )
                  : t(
                      `page.organizationDetails.defaultPricingStrategy.options.${organization.defaultPricingStrategySettings?.defaultPricingStrategy}.label`,
                      {
                        markup:
                          (organization.defaultPricingStrategySettings
                            ?.defaultUnitSalePriceMarkup ?? 0.0) * 100.0,
                      }
                    )}
              </Box>
            }
          />
          <DefaultEstimateSettingsStatic organization={organization} showBottomDivider />
          <DefaultInvoiceSettingsStatic organization={organization} />
        </>
      ) : null}
    </>
  )
}

export default OrganizationPreferencesStatic
