
import gql from "graphql-tag"

export const GET_ESTIMATE_OPTIONS_AND_DEFAULTS = gql`
  query GetEstimateOptionsAndDefaults($id: ID!) {
    getOrganizationById(id: $id) {
      id
      taxRateGroups {
        id
        name
        isLocked
        isArchived
        totalTaxRate
        taxRates {
          id
          name
          rate
        }
      }
      defaultEstimateSettings {
        expirationDays
        showExpirationDate
        showContract
        showLineItems
        showLineItemQuantity
        showLineItemUnitPrice
        showLineItemSubtotal
        showLineItemCode
        showLineItemName
        showLineItemDescription
        showLineItemBundleComponents
        showLineItemBundleComponentUnitPrice
        showLineItemBundleComponentQuantity
        showFooter
        showFooterSubtotal
        showFooterDiscount
        showFooterTaxes
        showFooterTotal
      }
    }
  }
`