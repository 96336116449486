import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import OpenIcon from "@mui/icons-material/DraftsOutlined"
import ClosedIcon from "@mui/icons-material/MarkEmailReadOutlined"
import MenuItem from "@mui/material/MenuItem"

import FilterMenu from "~/components/FilterMenu"
import { InboxConversationStatus, SortDirection } from "~/types/apiTypes"

interface Props {
  readonly onChangeStatusFilter: (status: InboxConversationStatus) => void
  readonly onChangeSort: (sort: SortDirection) => void
  readonly selectedStatus: InboxConversationStatus
  readonly selectedSort: SortDirection
}

export default function ConversationListMenuBar({
  onChangeStatusFilter,
  onChangeSort,
  selectedStatus,
  selectedSort,
}: Props) {
  const { t } = useTranslation()

  const [statusFilterMenuAnchor, setStatusFilterMenuAnchor] = useState<
    EventTarget & HTMLButtonElement
  >()
  const showStatusFiltersMenu = Boolean(statusFilterMenuAnchor)
  const [sortMenuAnchor, setSortMenuAnchor] = useState<EventTarget & HTMLButtonElement>()
  const showSortMenu = Boolean(sortMenuAnchor)

  const statusOptions = useMemo(
    () => [
      {
        value: InboxConversationStatus.OPEN,
        label: t(`page.inbox.statusOption.${InboxConversationStatus.OPEN}`),
      },
      {
        value: InboxConversationStatus.CLOSED,
        label: t(`page.inbox.statusOption.${InboxConversationStatus.CLOSED}`),
      },
    ],
    [t]
  )

  const sortByOptions = useMemo(
    () => [
      { value: SortDirection.DESC, label: t("page.inbox.sortByOption.NEWEST") },
      { value: SortDirection.ASC, label: t("page.inbox.sortByOption.OLDEST") },
    ],
    [t]
  )

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        backgroundColor: "white",
        padding: "0.25rem 1rem",
      }}
    >
      <FilterMenu
        anchorEl={statusFilterMenuAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        id="status-filter-menu"
        isOpen={showStatusFiltersMenu}
        label={statusOptions.find((o) => o.value === selectedStatus)?.label as string}
        labelStyle={{ fontWeight: "600" }}
        mutuallyExclusive
        onClickOption={(option) => {
          onChangeStatusFilter(option.value as InboxConversationStatus)
          setStatusFilterMenuAnchor(undefined)
        }}
        onClose={() => setStatusFilterMenuAnchor(undefined)}
        onOpen={(event) => setStatusFilterMenuAnchor(event.currentTarget)}
        options={statusOptions}
        renderOption={(option) => (
          <MenuItem
            key={option.value}
            onClick={() => {
              onChangeStatusFilter(option.value as InboxConversationStatus)
              setStatusFilterMenuAnchor(undefined)
            }}
            value={option.value}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {option.value === InboxConversationStatus.OPEN ? (
                <OpenIcon sx={{ fontSize: "1rem" }} />
              ) : (
                <ClosedIcon sx={{ fontSize: "1rem" }} />
              )}
              <Box>{option.label}</Box>
            </Box>
          </MenuItem>
        )}
        selectedOptions={[selectedStatus]}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      />
      <FilterMenu
        anchorEl={sortMenuAnchor}
        id="sort-menu"
        isOpen={showSortMenu}
        label={sortByOptions.find((o) => o.value === selectedSort)?.label as string}
        labelStyle={{ fontWeight: "600" }}
        menuSectionLabel={t("page.inbox.sortBy") as string}
        mutuallyExclusive
        onClickOption={(option) => {
          onChangeSort(option.value as SortDirection)
        }}
        onClose={() => setSortMenuAnchor(undefined)}
        onOpen={(event) => setSortMenuAnchor(event.currentTarget)}
        options={sortByOptions}
        selectedOptions={[selectedSort]}
      />
    </Box>
  )
}
