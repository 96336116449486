import gql from "graphql-tag"
import FullAddress from "./fragments/fullAddress"

export const EDIT_ORGANIZATION = gql`
  mutation EditOrganization(
    $id: ID!
    $tradeName: String
    $legalName: String
    $phoneNumber: String
    $email: String
    $websiteURL: String
    $address: AddressInput
    $timeZone: String
    $ownerUserId: ID
    $accountManagerUserId: ID
    $accountNumber: String
    $languageCode: LanguageCode
    $allowJobAssignmentOverlap: Boolean
    $bccJobEmailToOrganizationEmail: Boolean
    $allowBundleModsOnTxns: Boolean
    $defaultEstimateSettings: OrganizationDefaultEstimateSettingsInput
    $defaultInvoiceSettings: OrganizationDefaultInvoiceSettingsInput
    $defaultPricingStrategySettings: OrganizationDefaultPricingStrategySettingsInput
  ) {
    editOrganization(
      input: {
        id: $id
        tradeName: $tradeName
        legalName: $legalName
        phoneNumber: $phoneNumber
        email: $email
        websiteURL: $websiteURL
        address: $address
        timeZone: $timeZone
        ownerUserId: $ownerUserId
        accountManagerUserId: $accountManagerUserId
        accountNumber: $accountNumber
        languageCode: $languageCode
        allowBundleModsOnTxns: $allowBundleModsOnTxns
        allowJobAssignmentOverlap: $allowJobAssignmentOverlap
        bccJobEmailToOrganizationEmail: $bccJobEmailToOrganizationEmail
        defaultEstimateSettings: $defaultEstimateSettings
        defaultInvoiceSettings: $defaultInvoiceSettings
        defaultPricingStrategySettings: $defaultPricingStrategySettings
      }
    ) {
      organization {
        id
        tradeName
        legalName
        level
        timeZone
        currencyCode
        status
        email
        phoneNumber
        websiteURL
        timeZone
        languageCode
        allowBundleModsOnTxns
        allowJobAssignmentOverlap
        bccJobEmailToOrganizationEmail
        address {
          ...FullAddress
        }
        accountNumber
        accountManager {
          id
          email
          firstName
          lastName
        }
        owner {
          id
          firstName
          lastName
          email
          mobilePhoneNumber
          status
          languageCode
        }
        defaultPricingStrategySettings {
          defaultPricingStrategy
          defaultUnitSalePriceMarkup
        }
        defaultEstimateSettings {
          expirationDays
          showExpirationDate
          showContract
          showLineItems
          showLineItemQuantity
          showLineItemUnitPrice
          showLineItemSubtotal
          showLineItemCode
          showLineItemName
          showLineItemDescription
          showLineItemBundleComponents
          showLineItemBundleComponentUnitPrice
          showLineItemBundleComponentQuantity
          showFooter
          showFooterSubtotal
          showFooterDiscount
          showFooterTaxes
          showFooterTotal
        }
        defaultInvoiceSettings {
          netTerms
          showDueDate
          showLineItems
          showLineItemQuantity
          showLineItemUnitPrice
          showLineItemSubtotal
          showLineItemCode
          showLineItemName
          showLineItemDescription
          showLineItemBundleComponents
          showLineItemBundleComponentUnitPrice
          showLineItemBundleComponentQuantity
          showFooter
          showFooterSubtotal
          showFooterDiscount
          showFooterTaxes
          showFooterTotal
        }
        createdAt
        createdBy {
          id
          email
        }
        updatedAt
        updatedBy {
          id
          email
        }
      }
    }
  }
  ${FullAddress}
`
