import gql from "graphql-tag"

export const UPDATE_INBOX_CONVERSATION_STATUS = gql`
  mutation UpdateInboxConversationStatus(
    $conversationIds:[ID!]!
    $status:InboxConversationStatus!
  ) {
    updateInboxConversationStatus(
      input: {
          conversationIds:$conversationIds
          status: $status                      
      }
    ) {
      inboxConversations {
        id
        status
        isArchived
      }
    }  
  }
`