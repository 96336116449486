import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import TextField from "@mui/material/TextField"
import Select from "@mui/material/Select"

import PositiveIntegerInput from "./PositiveIntegerInput"
import MoneyInput from "./MoneyInput"
import {
  asFloat,
  asInt,
  calculateSubtotal,
  calculateTaxSummary,
  calculateTotal,
  formatMoney,
  formatTaxRate,
  getCurrencySymbol,
} from "~/util"
import { TransactionLineItemFormInput, DiscountType } from "~/types"

interface Props {
  readonly currencyCode: string
  readonly discount: number | string
  readonly discountType: DiscountType
  readonly lineItems: TransactionLineItemFormInput[]
  readonly onChangeDiscount: (value: string) => void
  readonly onChangeDiscountType: (value: DiscountType) => void
}

function TransactionFooter({
  currencyCode,
  discount,
  discountType,
  lineItems,
  onChangeDiscount,
  onChangeDiscountType,
}: Props) {
  const { t } = useTranslation()
  const taxSummary = calculateTaxSummary(lineItems, discount, discountType)

  return (
    <Box sx={classes.footer}>
      <Box sx={classes.footerRow}>
        <Box sx={classes.footerRowContent}>
          <Box sx={classes.footerLabel}>{t("subTotal")}</Box>
          <Box data-testid="txn_subTotal" sx={classes.footerValue}>
            {formatMoney(currencyCode, calculateSubtotal(lineItems))}
          </Box>
        </Box>
      </Box>
      <Box sx={classes.footerRow}>
        <Box style={{ alignItems: "center" }} sx={classes.footerRowContent}>
          <Box sx={classes.footerLabel}>{t("discount")}</Box>
          <Box sx={classes.footerValue}>
            <Box sx={classes.discountFieldContainer}>
              {discountType === DiscountType.AMOUNT ? (
                <MoneyInput
                  aria-label={t("discount")}
                  currencyCode={currencyCode}
                  customInput={TextField}
                  data-testid="txn_discount"
                  fullWidth={false}
                  inputProps={{
                    min: 0,
                    size: 3,
                  }}
                  max={999999999}
                  min={0}
                  onChange={(e) => {
                    const val = asFloat(e.target.value)
                    if (val === 0) {
                      onChangeDiscount("")
                    } else {
                      onChangeDiscount(String(val))
                    }
                  }}
                  size="small"
                  style={{ width: "100px", marginRight: "0.25rem" }}
                  value={asFloat(discount) > 0 ? String(discount) : ""}
                  variant="outlined"
                />
              ) : (
                <PositiveIntegerInput
                  aria-label={t("discount") as string}
                  customInput={TextField}
                  data-testid="txn_discount"
                  fullWidth={false}
                  inputProps={{
                    min: 0,
                    maxLength: 12,
                    size: 3,
                  }}
                  max={999999999}
                  min={0}
                  name="discount"
                  onChange={(e) => {
                    const val = asInt(e.target.value)
                    if (val === 0) {
                      onChangeDiscount("")
                    } else {
                      onChangeDiscount(String(val))
                    }
                  }}
                  size="small"
                  style={{ width: "100px", marginRight: "0.25rem" }}
                  value={asInt(discount) > 0 ? String(discount) : ""}
                  variant="outlined"
                />
              )}

              <FormControl size="small" sx={classes.discountTypeFormControl} variant="outlined">
                <Select
                  data-testid="txn_discountType"
                  onChange={(e) => {
                    const val = e.target.value as DiscountType
                    onChangeDiscountType(val)
                  }}
                  value={discountType}
                  variant="outlined"
                >
                  <MenuItem value={DiscountType.PERCENTAGE}>% {t("percent")} </MenuItem>
                  <MenuItem value={DiscountType.AMOUNT}>
                    {`${getCurrencySymbol(currencyCode)} ${currencyCode}`}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </Box>
      {taxSummary?.taxRateAmounts?.map((taxRateAmount) => (
        <Box key={taxRateAmount.taxRate.id} sx={classes.footerRow}>
          <Box sx={classes.footerRowContent}>
            <Box sx={classes.footerLabel}>
              <Box>{taxRateAmount.taxRate.name}</Box>
              <Box style={{ fontSize: "0.75rem", color: "#747474" }}>
                <em>{formatTaxRate(taxRateAmount.taxRate.rate)}</em>
              </Box>
            </Box>
            <Box data-testid="txn_totalTax" sx={classes.footerValue}>
              {formatMoney(currencyCode, taxRateAmount.amount)}
            </Box>
          </Box>
        </Box>
      ))}
      <Box sx={classes.footerRow}>
        <Box sx={classes.footerRowContent}>
          <Box sx={classes.footerLabel}>{t("totalTax")}</Box>
          <Box data-testid="txn_totalTax" sx={classes.footerValue}>
            {formatMoney(currencyCode, taxSummary.total)}
          </Box>
        </Box>
      </Box>
      <Box sx={classes.footerRow}>
        <Box
          sx={[
            classes.footerRowContent,
            {
              borderTop: "1px solid #222",
              paddingTop: "0.625rem",
              marginTop: "0.625rem",
              fontWeight: "bold",
            },
          ]}
        >
          <Box sx={classes.footerLabel}>{t("total")}</Box>
          <Box data-testid="txn_total" sx={classes.footerValue}>
            {formatMoney(currencyCode, calculateTotal(lineItems, discount, discountType))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const classes = {
  footer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    margin: "0.9375rem",
  },
  footerRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0.3125rem",
  },
  footerRowContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // width: "350px",
    width: "100%",
  },
  footerLabel: {
    marginRight: "0.9375rem",
    textAlign: "left",
  },
  footerValue: {
    textAlign: "right",
  },
  discountFieldContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    textAlign: "left",
  },
  discountTypeFormControl: {
    minWidth: "120px",
  },
} as const

export default TransactionFooter
