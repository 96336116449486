import React, { useState, useMemo, useEffect } from "react"
import * as Sentry from "@sentry/react"
import dayjs from "dayjs"
import gql from "graphql-tag"
import { useQuery, useMutation } from "@apollo/client"
import { DragDropContext } from "react-beautiful-dnd"
import { useTranslation } from "react-i18next"
import { useNavigate, useLocation } from "react-router-dom"
import FormControlLabel from "@mui/material/FormControlLabel"
import CircularProgress from "@mui/material/CircularProgress/CircularProgress"
import Checkbox from "@mui/material/Checkbox"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Alert from "@mui/material/Alert"

import EmptyState from "~/components/EmptyState"
import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import SearchField from "~/components/SearchField"
import ProductOrderStatusColumn from "~/components/ProductOrderStatusColumn"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import { EDIT_PRODUCT_ORDER } from "~/queries/editProductOrder"
import {
  getStatusBackgroundColor,
  getStatusForegroundColor,
  isBlank,
  MAX_INTEGER,
  parseGraphQLErrorCode,
  PRODUCT_ORDERS_HQ,
  useDebounce,
} from "~/util"
import { useAuth } from "~/context/AuthContext"
import {
  ProductOrderStatusColumnOption,
  ProductOrderStatusColumnData,
  Snack,
  ProductOrderStatus,
  ProductOrder,
  DefaultPermission,
  User,
} from "~/types"
import UserSelect from "~/components/UserSelect"
import useStore, {
  filterOrdersByAccountManagerIdSelector,
  setFilterOrdersByAccountManagerIdSelector,
  showArchivedProductOrdersToggleSelector,
  setShowArchivedProductOrdersToggleSelector,
} from "~/store"

const ALL_PRODUCT_ORDERS = gql`
  query AllProductOrders(
    $filter: String
    $sortBy: String
    $sortDir: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $orderOrganizationId: ID
    $statuses: [ProductOrderStatus]
    $showArchived: Boolean
    $updatedSinceDate: LocalDateTime
  ) {
    allProductOrders(
      input: {
        filter: $filter
        sortBy: $sortBy
        sortDir: $sortDir
        first: $first
        last: $last
        after: $after
        before: $before
      }
      filter: {
        orderOrganizationId: $orderOrganizationId
        statuses: $statuses
        showArchived: $showArchived
        updatedSinceDate: $updatedSinceDate
      }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          number
          status
          boardPosition
          currencyCode
          dateSubmitted
          total
          organization {
            id
            tradeName
            accountManager {
              id
              firstName
              lastName
              avatar {
                id
                signedUrl
              }
            }
          }
        }
      }
    }
  }
`

const UPDATED_SINCE_DATE = dayjs().subtract(30, "days").toISOString()

function FranchisorProductOrderBoard() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { hasPermissions, user } = useAuth()
  const filterOrdersByAccountManagerId = useStore(filterOrdersByAccountManagerIdSelector)
  const setFilterOrdersByAccountManagerId = useStore(setFilterOrdersByAccountManagerIdSelector)
  const showArchivedProductOrdersToggle = useStore(showArchivedProductOrdersToggleSelector)
  const setShowArchivedProductOrdersToggle = useStore(setShowArchivedProductOrdersToggleSelector)
  const [columns, setColumns] = useState<ProductOrderStatusColumnData[]>([])
  const statuses = useMemo(
    () => [
      {
        id: ProductOrderStatus.SUBMITTED,
        name: t("productOrderStatus.SUBMITTED"),
        backgroundColor: getStatusBackgroundColor(ProductOrderStatus.SUBMITTED),
        foregroundColor: getStatusForegroundColor(ProductOrderStatus.SUBMITTED),
        position: 0,
      },
      {
        id: ProductOrderStatus.IN_PROGRESS,
        name: t("productOrderStatus.IN_PROGRESS"),
        backgroundColor: getStatusBackgroundColor(ProductOrderStatus.IN_PROGRESS),
        foregroundColor: getStatusForegroundColor(ProductOrderStatus.IN_PROGRESS),
        position: 1,
      },
      {
        id: ProductOrderStatus.SHIPPING,
        name: t("productOrderStatus.SHIPPING"),
        backgroundColor: getStatusBackgroundColor(ProductOrderStatus.SHIPPING),
        foregroundColor: getStatusForegroundColor(ProductOrderStatus.SHIPPING),
        position: 2,
      },
      {
        id: ProductOrderStatus.INVOICING,
        name: t("productOrderStatus.INVOICING"),
        backgroundColor: getStatusBackgroundColor(ProductOrderStatus.INVOICING),
        foregroundColor: getStatusForegroundColor(ProductOrderStatus.INVOICING),
        position: 3,
      },
      {
        id: ProductOrderStatus.COMPLETED,
        name: t("productOrderStatus.COMPLETED"),
        backgroundColor: getStatusBackgroundColor(ProductOrderStatus.COMPLETED),
        foregroundColor: getStatusForegroundColor(ProductOrderStatus.COMPLETED),
        position: 4,
      },
    ],
    [t]
  )
  const [productOrders, setProductOrders] = useState<ProductOrder[]>([])
  const [snack, setSnack] = useState<Snack | undefined>(() => location?.state?.snack)
  const [filter, setFilter] = useState<string>("")
  const canUpdate = !hasPermissions?.([DefaultPermission.UpdateProductOrder])
  const debouncedSearchTerm = useDebounce(filter, 500)

  if (user?.organization?.level === 2) {
    navigate("/app/orders", { replace: true })
  }

  const { data, loading, error } = useQuery(ALL_PRODUCT_ORDERS, {
    variables: {
      filter: debouncedSearchTerm,
      first: MAX_INTEGER,
      showArchived: showArchivedProductOrdersToggle,
      updatedSinceDate: UPDATED_SINCE_DATE,
    },
    fetchPolicy: "cache-and-network",
    context: {
      debounceKey: "ALL_PRODUCT_ORDERS",
      debounceTimeout: 50,
    },
    onCompleted: (data) => {
      const nodes = data?.allProductOrders?.edges
        ?.map((e) => e.node)
        ?.filter((n) => n.status !== ProductOrderStatus.DRAFT)
        ?.sort((a, b) => a.boardPosition - b.boardPosition)
      setProductOrders(nodes)

      if (nodes) {
        const updatedColumns = statuses
          .map((c) => {
            return {
              ...c,
              productOrderIds: nodes.filter((n) => n.status === c.id).map((p) => p.id),
            }
          })
          .sort((a, b) => a.position - b.position)
        setColumns(updatedColumns)
      }
    },
  })

  const [editProductOrder] = useMutation(EDIT_PRODUCT_ORDER, {
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const handleDragEnd = (result) => {
    const { destination, source, draggableId } = result

    if (!destination) {
      return
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    const startStatus = columns.find((s) => s.id === source.droppableId)
    const endStatus = columns.find((s) => s.id === destination.droppableId)
    const targetOrder = productOrders.find((j) => j.id === draggableId)

    if (
      typeof startStatus === "undefined" ||
      typeof endStatus === "undefined" ||
      typeof targetOrder === "undefined"
    ) {
      return
    }

    const startStatusOrders = productOrders.filter((o) => o.status === startStatus.id)
    if (!startStatusOrders) {
      return
    }

    if (startStatus.id === endStatus.id) {
      const startStatusOrderIds = Array.from(startStatus.productOrderIds)

      let boardPosition = 0

      if (destination.index === 0) {
        const topOrderId = startStatusOrderIds[0]
        const topOrder = productOrders.find((o) => o.id === topOrderId)
        boardPosition = (topOrder?.boardPosition ?? 0) / 2 || 1
      } else if (destination.index === startStatusOrders.length - 1) {
        boardPosition =
          (startStatusOrders[startStatusOrders.length - 1]?.boardPosition ?? 0) + 10000
      } else {
        // get the order above the dropped order's new position
        const topOrderIndex =
          source.index > destination.index ? Math.max(destination.index - 1, 0) : destination.index
        const topOrderId = startStatusOrderIds[topOrderIndex]
        const topOrder = productOrders.find((o) => o.id === topOrderId)

        // get the order below the dropped order's new position
        const bottomOrderIndex = Math.max(
          Math.min(topOrderIndex + 1, startStatusOrderIds.length - 1),
          0
        )
        const bottomOrderId = startStatusOrderIds[bottomOrderIndex]
        const bottomOrder = productOrders.find((o) => o.id === bottomOrderId)

        const topPos = topOrder?.boardPosition || 0
        const bottomPos = bottomOrder?.boardPosition || 10000
        boardPosition = (bottomPos - topPos) / 2 + topPos
      }

      const updatedProductOrders = productOrders.map((o) => {
        if (o.id === targetOrder.id) {
          return {
            ...targetOrder,
            boardPosition,
          }
        }
        return o
      })

      startStatusOrderIds.splice(source.index, 1)
      startStatusOrderIds.splice(destination.index, 0, targetOrder.id)

      const updatedColumn = {
        ...startStatus,
        productOrderIds: startStatusOrderIds,
      }

      const updatedColumns = columns.filter((c) => c.id !== startStatus.id)
      updatedColumns.push(updatedColumn)
      updatedColumns.sort((a, b) => a.position - b.position)

      setProductOrders(updatedProductOrders)
      setColumns(updatedColumns)

      editProductOrder({
        variables: {
          id: targetOrder.id,
          boardPosition,
        },
      })
    } else {
      // move from one status to another
      const endStatusOrders = productOrders.filter((o) => o.status === endStatus.id) ?? []
      const endStatusOrderIds = endStatusOrders.map((o) => o.id)

      // remove the target order from the start status
      const startOrderIndex = startStatusOrders.findIndex((o) => o.id === targetOrder.id)
      startStatusOrders.splice(startOrderIndex, 1)
      const updatedStartStatusColumn = {
        ...startStatus,
        productOrderIds: startStatusOrders.map((o) => o.id),
      }

      // calculate the targetOrder's new board position
      let boardPosition = 0
      if (destination.index === 0) {
        boardPosition = (endStatusOrders[0]?.boardPosition ?? 1) / 2
      } else if (destination.index === endStatusOrders.length) {
        const lastOrder = endStatusOrders[endStatusOrders.length - 1]
        boardPosition = (lastOrder?.boardPosition ?? 0) + 10000
      } else {
        // we must be dropping the targetOrder somewhere in the middle
        const topOrderIndex = Math.min(destination.index - 1, endStatusOrderIds.length - 1)
        const topOrder = endStatusOrders[topOrderIndex]

        const bottomOrderIndex = Math.max(
          Math.min(topOrderIndex + 1, endStatusOrderIds.length - 1),
          0
        )
        const topPos = topOrder?.boardPosition ?? 0
        const bottomOrder = endStatusOrders[bottomOrderIndex]
        const bottomPos = bottomOrder?.boardPosition ?? 10000
        boardPosition = (bottomPos - topPos) / 2 + topPos
      }

      const updatedProductOrders = productOrders.map((o) => {
        if (o.id === targetOrder.id) {
          return {
            ...targetOrder,
            boardPosition,
          }
        }
        return o
      })

      endStatusOrders.splice(destination.index, 0, targetOrder)

      const updatedEndStatusColumn = {
        ...endStatus,
        productOrderIds: endStatusOrders.map((o) => o.id),
      }

      const updatedColumns = columns.filter((c) => c.id !== startStatus.id && c.id !== endStatus.id)
      updatedColumns.push(updatedStartStatusColumn)
      updatedColumns.push(updatedEndStatusColumn)
      updatedColumns.sort((a, b) => a.position - b.position)

      setProductOrders(updatedProductOrders)
      setColumns(updatedColumns)

      editProductOrder({
        variables: {
          id: targetOrder.id,
          status: endStatus.id,
          boardPosition,
        },
      })
    }
  }

  return (
    <>
      <Seo title={t(PRODUCT_ORDERS_HQ.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={PRODUCT_ORDERS_HQ}>
        <Box
          sx={{
            margin: "0 1.25rem",
          }}
        >
          <PageHeader icon={PRODUCT_ORDERS_HQ.icon} leafTitleKey={PRODUCT_ORDERS_HQ.titleKey} />
          <Box
            sx={(theme) => ({
              marginBottom: "1.25rem",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              [theme.breakpoints.down(600)]: {
                width: "100%",
                flexDirection: "column",
              },
            })}
          >
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
                flex: 3,
                maxWidth: "1000px",
                [theme.breakpoints.down(600)]: {
                  width: "100%",
                  flexDirection: "column",
                },
              })}
            >
              <Box
                sx={{
                  flex: 3,
                }}
              >
                <SearchField
                  onChange={setFilter}
                  placeholder={t("searchOrders")}
                  sx={{ borderRadius: "4px", height: "100%" }}
                  term={filter}
                  testID="SearchField"
                />
              </Box>
              <Box
                sx={{
                  flex: 2,
                }}
              >
                <UserSelect
                  aria-label={t("accountManager") as string}
                  label={t("accountManager") as string}
                  name="accountManager"
                  onChange={(selectedUser: User | null) => {
                    setFilterOrdersByAccountManagerId(selectedUser?.id ?? null)
                  }}
                  roleNames={["ACCOUNT_MANAGER_LEVEL_1"]}
                  selectedUser={{ id: filterOrdersByAccountManagerId } as User}
                />
              </Box>
              <Box sx={{ alignSelf: "center", marginLeft: "2rem" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(showArchivedProductOrdersToggle)}
                      onChange={(event, checked) => {
                        setShowArchivedProductOrdersToggle(checked)
                      }}
                      value="showArchived"
                    />
                  }
                  label={t("showArchivedProductOrders") as string}
                  sx={{
                    marginRight: "1.5rem",
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                justifySelf: "flex-end",
                alignSelf: "center",
              }}
            >
              <Button
                onClick={() => navigate("/app/orders/hq/list")}
                style={{ marginRight: "0.5rem" }}
              >
                {t("switchToListView")}
              </Button>
            </Box>
          </Box>
          {error ? (
            <Alert severity="error">
              {`${t("error.general.title")} ${t("error.general.message")}`}
            </Alert>
          ) : null}
          {loading && (!productOrders || productOrders?.length === 0) ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10%",
              }}
            >
              <CircularProgress color="secondary" size={40} thickness={6.0} />
            </Box>
          ) : productOrders?.length === 0 ? (
            <Box style={{ paddingTop: "1.875rem", paddingBottom: "1.875rem" }}>
              {isBlank(filter) && (
                <EmptyState title={t("page.productOrderList.emptyState.franchisor.title")}>
                  <Box>{t("page.productOrderList.emptyState.franchisor.message")}</Box>
                </EmptyState>
              )}
              {!isBlank(filter) && (
                <EmptyState title={t("page.productOrderList.noMatchingResults.title")}>
                  <Box>{t("page.productOrderList.noMatchingResults.message")}</Box>
                </EmptyState>
              )}
            </Box>
          ) : (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  maxWidth: "100%",
                  overflowX: "auto",
                  padding: "0rem",
                  paddingBottom: "3.125rem",
                  height: "100%",
                }}
              >
                {columns?.map((status: ProductOrderStatusColumnOption) => {
                  let statusOrders = productOrders.filter((o) =>
                    (status.productOrderIds ?? []).includes(o.id)
                  )

                  if (filterOrdersByAccountManagerId) {
                    statusOrders = statusOrders.filter(
                      (o) => o.organization.accountManager?.id === filterOrdersByAccountManagerId
                    )
                  }

                  return (
                    <ProductOrderStatusColumn
                      backgroundColor={status.backgroundColor}
                      foregroundColor={status.foregroundColor}
                      isDragDisabled={canUpdate}
                      key={status.id}
                      onClick={(id) => navigate(`/app/orders/hq/edit/${id}`)}
                      productOrders={statusOrders}
                      status={status}
                    />
                  )
                })}
              </Box>
            </DragDropContext>
          )}
        </Box>
      </MainLayout>
    </>
  )
}

export default FranchisorProductOrderBoard
