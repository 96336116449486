import gql from "graphql-tag"

export const SEND_ESTIMATE_TO_PLUGIN = gql`
  mutation SendEstimateToPlugin($estimateId: ID!) {
    sendEstimateToPlugin(input: { estimateId: $estimateId }) {
      wasSent
      estimate {
        id
        issuedDate
        pluginProvider {
          id
          name
          category
        }
        pluginProviderUrl
        externalId
        dateSentToPluginProvider
      }
    }
  }
`
