import { ErrorResponse } from "@apollo/client/link/error"
import { ApolloError } from "@apollo/client/errors"

/**
 * Grabs the first error code returned from a GraphQL call.
 */
export function parseGraphQLErrorCode(error: ApolloError | ErrorResponse): string {
  const code = error?.graphQLErrors?.[0]?.extensions?.code || `general.message`
  return `error.server.${code}`
}
