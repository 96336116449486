import React from "react"
import Box from "@mui/material/Box"
import type { PopoverProps } from "@mui/material/Popover"
import Button from "@mui/material/Button"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import CheckIcon from "@mui/icons-material/Check"
import Checkbox from "@mui/material/Checkbox"
import { styled } from "@mui/material"

const MenuSectionRoot = styled("li")`
  list-style: none;
  & > ul {
    padding-left: 0.25rem;
  }
`

const MenuSectionLabel = styled("span")`
  display: block;
  padding: 0.25rem 0.625rem;
  font-size: 0.75em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: #757575;
`

interface MenuSectionProps {
  readonly children: React.ReactNode
  readonly label?: string | null
}

function MenuSection({ children, label }: MenuSectionProps) {
  return (
    <MenuSectionRoot role="group">
      {label && label.trim().length > 0 ? <MenuSectionLabel>{label}</MenuSectionLabel> : null}
      <ul>{children}</ul>
    </MenuSectionRoot>
  )
}

export interface FilterMenuOption {
  value: string
  label: string
}

interface Props {
  readonly anchorEl?: PopoverProps["anchorEl"]
  readonly anchorOrigin?: PopoverProps["anchorOrigin"]
  readonly id: string
  readonly isOpen: boolean
  readonly label: string
  readonly labelStyle?: React.CSSProperties
  menuSectionLabel?: string
  readonly mutuallyExclusive: boolean
  readonly onOpen: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  readonly onClickOption: (option: FilterMenuOption) => void
  readonly onClose: () => void
  readonly options: FilterMenuOption[]
  readonly renderOption?: (option: FilterMenuOption) => React.ReactNode
  readonly selectedOptions: string[]
  readonly transformOrigin?: PopoverProps["transformOrigin"]
}

export default function FilterMenu({
  anchorEl,
  anchorOrigin,
  id,
  isOpen,
  label,
  labelStyle,
  menuSectionLabel,
  mutuallyExclusive,
  onClickOption,
  onClose,
  onOpen,
  options,
  renderOption,
  selectedOptions,
  transformOrigin,
}: Props) {
  return (
    <>
      <Button
        aria-controls={isOpen ? `${id}-menu` : undefined}
        aria-expanded={isOpen ? "true" : undefined}
        aria-haspopup="true"
        disableElevation
        endIcon={<KeyboardArrowDownIcon />}
        id={`${id}-menu-button`}
        onClick={onOpen}
        variant="text"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ ...labelStyle }}>{label}</Box>
          {!mutuallyExclusive && selectedOptions.length > 0 ? (
            <Box
              component="span"
              sx={{
                display: "inline-block",
              }}
            >
              <Box
                component="span"
                sx={(theme) => {
                  return {
                    backgroundColor: theme.fielderColors.black,
                    boxSizing: "border-box",
                    color: theme.fielderColors.white,
                    fontWeight: "600",
                    fontSize: "0.75rem",
                    right: "8px",
                    textAlign: "center",
                    borderRadius: "8px",
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0.10rem 0.375rem",
                  }
                }}
              >
                <Box
                  component="span"
                  sx={{
                    boxSizing: "border-box",
                    margin: 0,
                    padding: 0,
                    lineHeight: 1,
                    textAlign: "center",
                  }}
                >
                  {selectedOptions.length}
                </Box>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={
          anchorOrigin ?? {
            vertical: "bottom",
            horizontal: "right",
          }
        }
        id={`${id}-menu`}
        keepMounted
        onClose={onClose}
        open={isOpen}
        sx={{
          "& .MuiMenu-list": {
            minWidth: "8rem",
          },
          "& .MuiMenu-list .MuiCheckbox-root": {
            paddingLeft: 0,
          },
        }}
        transformOrigin={
          transformOrigin ?? {
            vertical: "top",
            horizontal: "right",
          }
        }
      >
        <MenuSection label={menuSectionLabel}>
          {options.map((option: FilterMenuOption) => {
            if (renderOption) {
              return renderOption(option)
            } else {
              return (
                <MenuItem
                  key={option.value}
                  onClick={() => onClickOption(option)}
                  value={option.value}
                >
                  {mutuallyExclusive ? (
                    <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                      <Box>
                        {selectedOptions.includes(option.value) ? (
                          <CheckIcon sx={{ fontSize: "1rem" }} />
                        ) : (
                          <Box sx={{ width: "1rem" }} />
                        )}
                      </Box>
                      <Box>{option.label}</Box>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                      <Checkbox
                        checked={selectedOptions.includes(option.value)}
                        sx={{ padding: 0 }}
                      />
                      <Box>{option.label}</Box>
                    </Box>
                  )}
                </MenuItem>
              )
            }
          })}
        </MenuSection>
      </Menu>
    </>
  )
}
