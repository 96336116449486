/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react"
import { Navigate, useLocation, NavigateProps } from "react-router-dom"
import isNil from "lodash/isNil"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import WarningIcon from "@mui/icons-material/Warning"
import TableContainer from "@mui/material/TableContainer"
import TablePagination from "@mui/material/TablePagination"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import AddIcon from "@mui/icons-material/AddOutlined"
import EditIcon from "@mui/icons-material/EditOutlined"
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined"
import Skeleton from "@mui/material/Skeleton"

import EmptyState from "~/components/EmptyState"
import NoResultsRow from "~/components/NoResultsRow"
import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import SearchField from "~/components/SearchField"
import SortableTableHeader from "~/components/SortableTableHeader"
import TablePaginationActions from "~/components/TablePaginationActions"
import FielderIconButton from "~/components/FielderIconButton"
import { ALL_ITEMS } from "~/queries/allItems"
import { isBlank, CATALOG, NOT_SPECIFIED, formatMoney, useDebounce } from "~/util"
import { useAuth } from "~/context/AuthContext"
import { Snack, DefaultPermission, SortDirection as SortDirectionType } from "~/types"

import useStore, {
  includeArchivedItemsToggleSelector,
  setIncludeArchivedItemsToggleSelector,
} from "~/store"

const NUM_COLUMNS = 7
const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100]

function CatalogItemList() {
  const { t } = useTranslation()
  const location = useLocation()
  const { hasPermissions } = useAuth()
  const catalogListSettings = useStore((state) => state.catalogListSettings)
  const setCatalogListSettings = useStore((state) => state.setCatalogListSettings)
  const includeArchivedItemsToggle = useStore(includeArchivedItemsToggleSelector)
  const setIncludeArchivedItemsToggle = useStore(setIncludeArchivedItemsToggleSelector)
  const [snack, setSnack] = useState<Snack | undefined>(() => {
    const { state } = location
    return state?.snack
  })
  const [filter, setFilter] = useState<string>(catalogListSettings.searchTerm)
  const [after, setAfter] = useState<number | null>()
  const [before, setBefore] = useState<number | null>()
  const [first, setFirst] = useState<number | null>(catalogListSettings.rowsPerPage)
  const [last, setLast] = useState<number | null>()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const debouncedSearchTerm = useDebounce(filter, 500)

  useEffect(() => {
    if (debouncedSearchTerm !== catalogListSettings.searchTerm) {
      setCatalogListSettings({
        ...catalogListSettings,
        searchTerm: debouncedSearchTerm,
      })
      setAfter(null)
      setBefore(null)
      setFirst(catalogListSettings.rowsPerPage)
      setLast(null)
    }
  }, [debouncedSearchTerm, catalogListSettings, setCatalogListSettings])

  const { loading, error, data } = useQuery(ALL_ITEMS, {
    variables: {
      filter: debouncedSearchTerm,
      after,
      before,
      first,
      last,
      sortBy: catalogListSettings.sortBy,
      sortDir: catalogListSettings.sortDir,
      includeArchived: Boolean(includeArchivedItemsToggle),
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    context: {
      debounceKey: "ALL_ITEMS",
      debounceTimeout: 50,
    },
  })

  function onGoToFirstPage() {
    setAfter(null)
    setBefore(null)
    setFirst(catalogListSettings.rowsPerPage)
    setLast(null)
  }

  function onGoToLastPage() {
    setAfter(null)
    setBefore(null)
    setFirst(null)
    setLast(catalogListSettings.rowsPerPage)
  }

  function onGoToPrevious() {
    setAfter(null)
    setBefore(pageInfo.startCursor)
    setFirst(null)
    setLast(catalogListSettings.rowsPerPage)
  }

  function onGoToNext() {
    setAfter(pageInfo.endCursor)
    setBefore(null)
    setFirst(catalogListSettings.rowsPerPage)
    setLast(null)
  }

  function handleChangeRowsPerPage(event: any) {
    const newPageSize = +event.target.value
    setCatalogListSettings({
      ...catalogListSettings,
      rowsPerPage: newPageSize,
      currentPage: 0,
    })
    setAfter(null)
    setBefore(null)
    setFirst(newPageSize)
    setLast(null)
  }

  function sort(propertyName: string) {
    const newSortBy = propertyName
    const newSortDir =
      propertyName !== catalogListSettings.sortBy
        ? SortDirectionType.ASC
        : catalogListSettings.sortDir === SortDirectionType.ASC
          ? SortDirectionType.DESC
          : SortDirectionType.ASC

    setCatalogListSettings({
      ...catalogListSettings,
      sortBy: newSortBy,
      sortDir: newSortDir,
      currentPage: 0,
    })

    setAfter(null)
    setBefore(null)
    setFirst(catalogListSettings.rowsPerPage)
    setLast(null)
  }

  const handleSearchChange = (val: string) => {
    setFilter(val)
    setAfter(null)
    setBefore(null)
    setFirst(catalogListSettings.rowsPerPage)
    setLast(null)
  }

  function handleRowClick(id: string) {
    setRedirectTo({
      to: `/app/catalog/edit/${id}`,
      replace: false,
    })
  }

  const pageInfo = data?.allItems?.pageInfo || {}
  const items = data?.allItems?.edges

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t(CATALOG.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={CATALOG}>
        <Box
          sx={(theme) => {
            return {
              margin: "0 0.5rem",
              paddingBottom: "3rem",
              [theme.breakpoints.up("md")]: {
                margin: "0 1.25rem",
                marginRight: "80px", // make sure the intercom chat bubble doesn't block the pagination controls
                paddingBottom: "12.5rem",
              },
            }
          }}
        >
          <PageHeader icon={CATALOG.icon} leafTitleKey={CATALOG.titleKey} />
          <Box
            sx={(theme) => ({
              marginBottom: "1.25rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              [theme.breakpoints.up("xl")]: {
                flexDirection: "row",
                alignContent: "flex-start",
                alignItems: "flex-start",
              },
            })}
          >
            <Box
              sx={(theme) => ({
                width: "100%",
                [theme.breakpoints.up("md")]: {
                  width: "25rem",
                },
              })}
            >
              <SearchField
                onChange={handleSearchChange}
                placeholder={t("searchItems")}
                term={filter}
                testID="SearchField"
              />
            </Box>
            <Box
              sx={(theme) => ({
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "1rem",
                [theme.breakpoints.up("md")]: {
                  flexDirection: "row",
                },
              })}
            >
              <Box
                sx={{
                  alignItems: "center",
                  paddingLeft: "0.875rem",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "1.5rem",
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                  },
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={includeArchivedItemsToggle}
                      onChange={(event, checked) => {
                        setIncludeArchivedItemsToggle(checked)
                      }}
                      value="includeArchived"
                    />
                  }
                  label={t("includeArchivedItems") as string}
                />
              </Box>
              <Box
                sx={(theme) => ({
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  gap: "1rem",
                  [theme.breakpoints.up("md")]: {
                    justifyContent: "flex-end",
                  },
                  [theme.breakpoints.up("lg")]: {
                    marginTop: 0,
                  },
                })}
              >
                {hasPermissions?.([DefaultPermission.BulkImportItems]) ? (
                  <Button
                    aria-label={t("bulkImport") as string}
                    data-testid="bulkImportButton"
                    onClick={() => {
                      setRedirectTo({ to: "/app/catalog/import", replace: false })
                    }}
                    sx={{
                      flex: 1,
                      maxWidth: "18rem",
                      "& svg": {
                        fontSize: "1.0rem",
                      },
                    }}
                    variant="text"
                  >
                    <Box>{t("bulkImport")}</Box>
                  </Button>
                ) : null}
                {hasPermissions?.([DefaultPermission.CreateItem]) ? (
                  <Button
                    aria-label={t("createItem") as string}
                    color="primary"
                    data-testid="createItemButton"
                    onClick={() => {
                      setRedirectTo({ to: `/app/catalog/create`, replace: false })
                    }}
                    sx={{
                      flex: 1,
                      maxWidth: "18rem",
                      fontWeight: "bold",
                      "& svg": {
                        fontSize: "1.0rem",
                      },
                    }}
                    variant="contained"
                  >
                    <AddIcon />
                    <Box>{t("createItem")}</Box>
                  </Button>
                ) : null}
              </Box>
            </Box>
          </Box>
          {!error && !loading && items?.length === 0 && (
            <Box style={{ paddingTop: "1.875rem", paddingBottom: "1.875rem" }}>
              {isBlank(filter) && (
                <EmptyState title={t("page.catalogList.emptyState.title")}>
                  {hasPermissions?.([DefaultPermission.CreateItem]) ? (
                    <Box>{t("page.catalogList.emptyState.message")}</Box>
                  ) : null}
                </EmptyState>
              )}
              {!isBlank(filter) && (
                <EmptyState title={t("page.catalogList.noMatchingResults.title")}>
                  <Box>{t("page.catalogList.noMatchingResults.message")}</Box>
                </EmptyState>
              )}
            </Box>
          )}
          {!error && (loading || items?.length > 0) ? (
            <Paper sx={classes.tableWrapper}>
              <TableContainer
                sx={{
                  maxHeight: "calc(75vh)",
                  height: "calc(100vh - 50px)",
                }}
              >
                <Table
                  size="small"
                  stickyHeader
                  sx={[
                    {
                      width: "100%",
                      minWidth: "1024px",
                    },
                    items?.length === 0 && { height: "100%" },
                  ]}
                >
                  <TableHead>
                    <TableRow>
                      <SortableTableHeader
                        isActiveSort={catalogListSettings.sortBy === "code"}
                        label={t("itemCode")}
                        onClick={() => sort("code")}
                        sortDir={catalogListSettings.sortDir}
                        sx={classes.tableHead}
                        width="15%"
                      />
                      <SortableTableHeader
                        align="left"
                        isActiveSort={catalogListSettings.sortBy === "region"}
                        label={t("region")}
                        onClick={() => sort("region")}
                        sortDir={catalogListSettings.sortDir}
                        sx={classes.tableHead}
                      />
                      <SortableTableHeader
                        isActiveSort={catalogListSettings.sortBy === "name"}
                        label={t("name")}
                        onClick={() => sort("name")}
                        sortDir={catalogListSettings.sortDir}
                        sx={classes.tableHead}
                        width="30%"
                      />
                      <SortableTableHeader
                        align="left"
                        isActiveSort={catalogListSettings.sortBy === "category"}
                        label={t("category")}
                        onClick={() => sort("category")}
                        sortDir={catalogListSettings.sortDir}
                        sx={classes.tableHead}
                      />
                      <SortableTableHeader
                        align="right"
                        isActiveSort={catalogListSettings.sortBy === "wholesalePrice"}
                        label={t("wholesalePrice")}
                        onClick={() => sort("wholesalePrice")}
                        sortDir={catalogListSettings.sortDir}
                        sx={classes.tableHead}
                      />
                      <SortableTableHeader
                        align="left"
                        isActiveSort={catalogListSettings.sortBy === "stockType"}
                        label={t("stockType")}
                        onClick={() => sort("stockType")}
                        sortDir={catalogListSettings.sortDir}
                        sx={classes.tableHead}
                      />
                      <SortableTableHeader
                        align="right"
                        isActiveSort={catalogListSettings.sortBy === "leadTimeDays"}
                        label={t("leadTime")}
                        onClick={() => sort("leadTimeDays")}
                        sortDir={catalogListSettings.sortDir}
                        sx={classes.tableHead}
                      />
                      <TableCell sx={classes.tableHead}> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={items?.length == 0 ? { height: "100%" } : {}}>
                    {!error && !loading && items?.length == 0 && (
                      <NoResultsRow colSpan={NUM_COLUMNS} />
                    )}
                    {!error &&
                      !loading &&
                      items?.map((edge) => {
                        const { node } = edge
                        return (
                          <TableRow
                            key={node.id}
                            onClick={() => {
                              handleRowClick(node.id)
                            }}
                            sx={classes.dataRow}
                          >
                            <TableCell sx={{ padding: "0 0.5rem" }}>
                              <Box style={{ display: "flex", flexDirection: "column" }}>
                                <Box
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {node.isArchived ? (
                                    <WarningIcon
                                      sx={{
                                        fontSize: "1.125rem",
                                        color: "#DA1505",
                                        marginRight: "0.625rem",
                                      }}
                                    />
                                  ) : null}{" "}
                                  {!isNil(node.code) ? node.code : NOT_SPECIFIED}
                                </Box>
                                {node.isArchived ? (
                                  <Box
                                    style={{
                                      fontStyle: "italic",
                                      fontSize: "0.6875rem",
                                      color: "#DA1505",
                                      marginTop: "0.25rem",
                                    }}
                                  >
                                    {t("thisItemHasBeenArchived")}
                                  </Box>
                                ) : null}
                              </Box>
                            </TableCell>
                            <TableCell sx={{ padding: "0 0.5rem" }}>
                              {!isNil(node.region?.name) ? node.region.name : NOT_SPECIFIED}
                            </TableCell>
                            <TableCell sx={{ padding: "0 0.5rem" }}>
                              {!isNil(node.name) ? node.name : NOT_SPECIFIED}
                            </TableCell>
                            <TableCell align="left" sx={{ padding: "0 0.5rem" }}>
                              {!isNil(node.category) ? node.category : NOT_SPECIFIED}
                            </TableCell>
                            <TableCell align="right" sx={{ padding: "0 0.5rem" }}>
                              {!isNil(node.wholesalePrice)
                                ? formatMoney(
                                    node.region?.currencyCode,
                                    node.wholesalePrice,
                                    t("format:currency.long") as string
                                  )
                                : NOT_SPECIFIED}
                            </TableCell>
                            <TableCell align="left" sx={{ padding: "0 0.5rem" }}>
                              {!isNil(node.stockType)
                                ? t(`stockTypeOptions.${node.stockType}`)
                                : NOT_SPECIFIED}
                            </TableCell>
                            <TableCell align="right" sx={{ padding: "0 0.5rem" }}>
                              {!isNil(node.leadTimeDays) ? node.leadTimeDays : NOT_SPECIFIED}
                            </TableCell>
                            <TableCell align="right" sx={{ padding: "0 0.5rem" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-end",
                                  flexWrap: "nowrap",
                                }}
                              >
                                {hasPermissions?.([DefaultPermission.UpdateItem]) ? (
                                  <FielderIconButton
                                    aria-label={t("editItem") as string}
                                    sx={classes.button}
                                    title={t("editItem") as string}
                                  >
                                    <EditIcon />
                                  </FielderIconButton>
                                ) : (
                                  <FielderIconButton
                                    aria-label={t("viewItem") as string}
                                    sx={classes.button}
                                    title={t("viewItem") as string}
                                  >
                                    <VisibilityIcon />
                                  </FielderIconButton>
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    {loading
                      ? [...Array(catalogListSettings.rowsPerPage).keys()].map((i) => (
                          <TableRow key={i} sx={classes.dataRow}>
                            {[...Array(NUM_COLUMNS).keys()].map((j) => (
                              <TableCell key={j}>
                                <Skeleton variant="text" />
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      : null}
                    {error ? (
                      <TableRow>
                        <TableCell align="center" colSpan={NUM_COLUMNS}>
                          <Box
                            style={{
                              color: "rgb(244, 67, 54)",
                              fontWeight: 500,
                              fontSize: "1.25rem",
                              lineHeight: 1.6,
                            }}
                          >
                            {t("errorLabel")}
                            {": "}
                            {error.message}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                ActionsComponent={() => (
                  <TablePaginationActions
                    hasNextPage={pageInfo.hasNextPage ?? false}
                    hasPreviousPage={pageInfo.hasPreviousPage ?? false}
                    onGoToFirstPage={onGoToFirstPage}
                    onGoToLastPage={onGoToLastPage}
                    onGoToNext={onGoToNext}
                    onGoToPrevious={onGoToPrevious}
                  />
                )}
                component="div"
                count={-1}
                labelDisplayedRows={() => ``}
                labelRowsPerPage={t("rowsPerPage") as string}
                onPageChange={() => {}}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={catalogListSettings.currentPage}
                rowsPerPage={catalogListSettings.rowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              />
            </Paper>
          ) : null}
        </Box>
      </MainLayout>
    </>
  )
}

const classes = {
  loadingIndicatorCell: {
    paddingTop: "3.125rem",
    paddingBottom: "3.125rem",
  },
  tableWrapper: {
    overflowX: "auto",
    marginBottom: "80px",
  },
  tableHeaderControls: {
    marginBottom: "1.25rem",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  tableHead: {
    backgroundColor: "#f9f9f9",
    zIndex: 1000,
    position: "sticky",
    top: 0,
    padding: "1rem 0.5rem",
  },
  dataRow: {
    height: "3.75rem",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f8f8f8",
    },
  },
  button: {
    marginLeft: "0.625rem",
    "& span": { whiteSpace: "nowrap" },
  },
} as const

export default CatalogItemList
