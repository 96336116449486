import React, { useEffect, useState } from "react"
import { Formik } from "formik"
import Cookies from "js-cookie"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import { useMutation, useQuery } from "@apollo/client"
import { ErrorResponse } from "@apollo/client/link/error"
import { ApolloError } from "@apollo/client/errors"
import { Theme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import FormHelperText from "@mui/material/FormHelperText"
import * as Sentry from "@sentry/react"

import { PoweredByFielder, HeaderLogo, Seo } from "~/components"
import FielderTextField from "~/components/FielderTextField"
import {
  GET_ORGANIZATION_BY_ID_WITHOUT_PLUGINS,
  GET_ORGANIZATION_BY_ID_WITH_PLUGINS,
} from "~/queries/getOrganizationById"
import { LOGIN } from "~/queries/login"
import { useAuth } from "~/context/AuthContext"
import { formatPersonName, getLandingRoute, parseGraphQLErrorCode } from "~/util"
import { changeLanguage } from "~/i18n"
import { DefaultPermission } from "~/types"

function getErrorMessage(
  error: ApolloError | ErrorResponse,
  t: (key: string, options?: any) => string
): string {
  const errorCode = parseGraphQLErrorCode(error)
  if (
    errorCode.includes("credentials.invalid") ||
    errorCode.includes("user.status.deactivated") ||
    errorCode.includes("user.status.invalid") ||
    errorCode.includes("user.status.locked") ||
    errorCode.includes("user.status.locked-needs-password-change")
  ) {
    return t(errorCode)
  } else {
    Sentry.captureException(error)
    return t("error.general.message")
  }
}

function Error({ message }: { readonly message: string }): JSX.Element {
  return (
    <Box
      sx={{
        border: "1px solid red",
        borderRadius: "4px",
        backgroundColor: "rgba(255, 0, 0, 0.15)",
        color: "red",
        fontWeight: "600",
        padding: "0.5rem",
        marginTop: "1rem",
        marginBottom: "1rem",
      }}
    >
      {message}
    </Box>
  )
}

interface LoginProps {
  readonly location?: {
    state?: {
      tokenExpired?: boolean
    }
  }
}

function Login({ location }: LoginProps) {
  const navigate = useNavigate()
  const { t } = useTranslation("common", {
    useSuspense: typeof window !== "undefined",
  })
  const [landingRoute, setLandingRoute] = useState<string>()
  const {
    login: loginAction,
    setUser: setUserAction,
    hasPermissions,
    user,
    isAuthenticated,
  } = useAuth()

  useEffect(() => {
    if (Boolean(user) && isAuthenticated()) {
      navigate("/app/dashboard")
    }
  }, [isAuthenticated, navigate, user])

  const [login, { loading: loginLoading, error: loginError, called: loginCalled }] = useMutation(
    LOGIN,
    {
      onCompleted: (data) => {
        const { login } = data
        const user = login.user
        changeLanguage(user.languageCode ?? user.organization?.languageCode)
        loginAction?.(login.user, login.authToken)
        if (window?.heap) {
          window.heap.identify(user.email)
          window.heap.addUserProperties({
            username: user.email,
            organization: user.organization?.tradeName,
            name: formatPersonName(user),
            language: user.languageCode,
          })
        }

        Sentry.setUser({ email: login.user.email })

        if (user.organization?.status !== "ACTIVE") {
          const lr = getLandingRoute(user)
          setLandingRoute(lr)
          return
        }
      },
    }
  )

  const { error: getOrganizationByIdError, loading: getOrganizationByIdLoading } = useQuery(
    user && hasPermissions?.([DefaultPermission.ReadOrganizationPlugin])
      ? GET_ORGANIZATION_BY_ID_WITH_PLUGINS
      : GET_ORGANIZATION_BY_ID_WITHOUT_PLUGINS,
    {
      fetchPolicy: "network-only",
      variables: { id: user?.organization?.id },
      skip: !(
        user?.organization?.id &&
        user?.organization?.status === "ACTIVE" &&
        Cookies.get("authToken")
      ),
      onCompleted: (data) => {
        const organization = data.getOrganizationById
        const updatedUser = { ...user, organization }
        setUserAction?.(updatedUser)
        const lr = getLandingRoute(updatedUser)
        setLandingRoute(lr)
      },
      onError: (error) => {
        const errorCode = parseGraphQLErrorCode(error)
        console.error("ERROR: ", errorCode)
      },
    }
  )

  const error = loginError || getOrganizationByIdError
  const loading = loginLoading || getOrganizationByIdLoading

  const FormSchema = Yup.object().shape({
    username: Yup.string().email(t("page.login.validation.username.email") as string),
  })

  const tokenExpired = location?.state?.tokenExpired

  if (landingRoute) {
    return <Navigate replace={false} state={{ from: "/app/login" }} to={landingRoute} />
  }

  return (
    <Box
      sx={{
        height: "100vh",
        backgroundColor: (theme: Theme) => theme.fielderScreens.login.backgroundColor,
      }}
    >
      <Seo title={t("login")} />
      <main>
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          onSubmit={(values) => {
            login({ variables: { username: values.username, password: values.password } })
          }}
          validationSchema={FormSchema}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
            <form data-testid="loginForm" onSubmit={handleSubmit}>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "370px",
                  minWidth: "240px",
                  margin: "0 auto",
                  paddingTop: "10%",
                }}
              >
                <Box
                  data-testid="logo"
                  sx={{
                    maxWidth: "100%",
                    margin: "auto 10px",
                    marginBottom: "20px",
                  }}
                >
                  <HeaderLogo width="100%" />
                </Box>
                <Box
                  sx={{
                    margin: "auto 0.625rem",
                    padding: "1.375rem",
                    paddingTop: "0.625rem",
                    backgroundColor: "#fff",
                    border: (theme: Theme) =>
                      `1px solid ${theme.fielderScreens.login.backgroundColor}`,
                    borderRadius: "4px",
                  }}
                >
                  <h1
                    style={{
                      fontSize: "1.75rem",
                      margin: 0,
                      marginBottom: "1rem",
                    }}
                  >
                    {t("login")}
                  </h1>
                  {!error && !loading && tokenExpired ? (
                    <Error message={t("error.general.tokenExpired")} />
                  ) : null}
                  {error ? <Error message={getErrorMessage(error, t)} /> : null}
                  <Box sx={classes.fieldContainer}>
                    <FielderTextField
                      autoComplete="off"
                      autoFocus
                      data-testid="usernameField"
                      fullWidth
                      id="username"
                      inputProps={{ maxLength: 255 }}
                      label={t("emailAddress")}
                      margin="normal"
                      name="username"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      sx={{
                        backgroundColor: "#fff",
                        "& label.Mui-focused": {
                          color: "#000",
                        },
                      }}
                      type="email"
                      value={values.username}
                    />
                    {errors.username && touched.username ? (
                      <FormHelperText error id="usernameError">
                        {errors.username}
                      </FormHelperText>
                    ) : null}
                  </Box>
                  <Box sx={classes.fieldContainer}>
                    <FielderTextField
                      data-testid="passwordField"
                      fullWidth
                      id="password"
                      inputProps={{ maxLength: 255 }}
                      label={t("password")}
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                    />
                    {errors.password && touched.password ? (
                      <FormHelperText error id="passwordError">
                        {errors.password}
                      </FormHelperText>
                    ) : null}
                  </Box>
                  <Button
                    color="primary"
                    data-testid="loginBtn"
                    disabled={!(values.username && values.password) || loading}
                    fullWidth
                    id="loginBtn"
                    sx={{
                      marginTop: "1.25rem",
                      backgroundColor: (theme: Theme) => theme.palette.primary.main,
                      height: "3.125rem",
                    }}
                    type="submit"
                    variant="contained"
                  >
                    {loginCalled && loading ? (
                      <CircularProgress size={20} thickness={6.0} color="secondary" />
                    ) : (
                      <span>{t("login")}</span>
                    )}
                  </Button>
                  <Box
                    sx={{
                      marginTop: "1.25rem",
                      textAlign: "center",
                      textDecoration: "underline",
                      fontSize: "1rem",
                      "& a": {
                        color: (theme) => theme.palette.text.primary,
                      },
                    }}
                  >
                    <Link
                      id="forgot-password-link"
                      state={{ username: values.username }}
                      to="/app/forgot-password"
                    >
                      {t("page.login.forgotPassword")}
                    </Link>
                  </Box>
                  <Box
                    sx={(theme: Theme) => ({
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "2.5rem",
                      marginBottom: "-0.625rem",
                      fontSize: "0.875rem",
                      color: theme.fielderColors.mutedText,
                      "& a": {
                        color: theme.fielderColors.mutedText,
                      },
                    })}
                  >
                    <a href="/terms" target="_blank">
                      {t("termsOfService", "Terms of Service")}
                    </a>
                    <a href="/privacy" target="_blank">
                      {t("privacyPolicy", "Privacy Policy")}
                    </a>
                  </Box>
                </Box>
              </Box>
              <PoweredByFielder />
            </form>
          )}
        </Formik>
      </main>
    </Box>
  )
}

const classes = {
  fieldContainer: {
    marginBottom: "1.25rem",
  },
}

export default Login
