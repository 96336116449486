/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from "react"
import { Draggable, Droppable } from "react-beautiful-dnd"
import { FixedSizeList as List, areEqual } from "react-window"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"

import JobCard from "./JobCard"
import { capitalize } from "~/util"
import { Job, JobStatus } from "~/types"

interface JobBoardStep {
  id: string
  jobStatus: JobStatus
  jobs: Job[]
}

interface Props {
  readonly step: JobBoardStep
  readonly isDropDisabled: boolean
}

function JobBoardStepColumn({ step, isDropDisabled }: Props) {
  return (
    <Paper
      sx={{
        minWidth: "256px",
        maxWidth: "300px",
        minHeight: "400px",
        height: "100vh",
        maxHeight: "100%",
        flex: 1,
        alignSelf: "flex-start",
        overflowY: "hidden",
        marginRight: "0.625rem",
        borderRadius: "4px",
        "&:last-of-type": {
          marginRight: 0,
        },
        display: "flex",
        flexDirection: "column",
        backgroundColor: isDropDisabled ? `#c0c0c0` : `${step.jobStatus.lightColor}`,
      }}
    >
      <Box
        sx={{
          fontSize: "0.875rem",
          fontWeight: "bold",
          textAlign: "center",
          paddingTop: "0.625rem",
          paddingBottom: "0.625rem",
          textTransform: "uppercase",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          backgroundColor: isDropDisabled ? `#d0d0d0` : step.jobStatus.lightColor,
          color: isDropDisabled ? `#747474` : step.jobStatus.darkColor, //"#fff",
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingLeft: "0.625rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              width: "16px",
              height: "16px",
              borderRadius: "4px",
              backgroundColor: isDropDisabled ? `#747474` : step.jobStatus.mediumColor,
            }}
          />
          <Box sx={{ display: "flex", flexDirection: "row", gap: "0.25rem" }}>
            <span>{capitalize(step.jobStatus.name)}</span>
            <span>{`(${step.jobs.length})`}</span>
          </Box>
        </Box>
      </Box>
      <Droppable
        droppableId={step.id}
        isDropDisabled={isDropDisabled}
        mode="virtual"
        renderClone={(provided, snapshot, rubric) => {
          return (
            <JobCard
              job={step.jobs[rubric.source.index]}
              provided={provided}
              style={{ margin: 0 }}
            />
          )
        }}
      >
        {(provided, snapshot) => {
          // Add an extra item to our list to make space for a dragging item. Usually the
          // DroppableProvided.placeholder does this, but that won't work in a virtual list
          const itemCount = snapshot.isUsingPlaceholder ? step.jobs?.length + 1 : step.jobs?.length

          return (
            <List
              height={890}
              itemCount={itemCount}
              itemData={step.jobs}
              itemSize={145}
              outerRef={provided.innerRef}
              style={{
                padding: "0.5rem",
                // "&::-webkit-scrollbar": {
                //   height: "5px !important",
                //   width: "5px !important",
                // },
                // "&::-webkit-scrollbar-track-piece": {
                //   background: "#d0d0d0 !important",
                // },
                // "&::-webkit-scrollbar-thumb:vertical, *::-webkit-scrollbar-thumb:horizontal": {
                //   background: "#999 !important",
                // },
              }}
            >
              {Row}
            </List>
          )
        }}
      </Droppable>
    </Paper>
  )
}

const Row = React.memo(({ data: jobs, index, style }) => {
  const job = jobs[index]

  if (!job) {
    return null
  }

  // Faking some nice spacing around the items
  const grid = 8

  const patchedStyle = {
    ...style,
    left: style.left + grid,
    top: style.top + grid,
    width: `calc(${style.width} - ${grid * 2}px)`,
    height: style.height - grid,
  }

  return (
    <Draggable draggableId={job.id} index={index} key={job.id}>
      {(provided, snapshot) => {
        return (
          <JobCard
            isDragging={snapshot.isDragging}
            job={job}
            provided={provided}
            style={patchedStyle}
          />
        )
      }}
    </Draggable>
  )
}, areEqual)

export default JobBoardStepColumn
