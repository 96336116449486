import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import CloseIcon from "@mui/icons-material/CloseOutlined"
import Tooltip from "@mui/material/Tooltip"

import { Tag } from "~/types"

interface Props {
  readonly tag: Tag
}

export default function TagChip({ tag }: Props) {
  const { t } = useTranslation()

  return (
    <Box
      id={`tag-${tag.id}`}
      sx={{
        backgroundColor: "#d1d5db",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: "4px",
      }}
    >
      <Tooltip enterDelay={500} title={t("page.inbox.tagChip.search", { tagName: tag.name })}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 0.25rem",
            borderRadius: "4px",
            fontSize: "0.75rem",
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "#c4c4c4",
            },
          }}
        >
          {tag.name}
        </Box>
      </Tooltip>
      <Tooltip enterDelay={500} title={t("page.inbox.tagChip.remove", { tagName: tag.name })}>
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0.15rem",
            borderRadius: "4px",
            textAlign: "center",
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "#c4c4c4",
            },
          }}
        >
          <CloseIcon sx={{ fontSize: "0.875rem" }} />
        </Box>
      </Tooltip>
    </Box>
  )
}
