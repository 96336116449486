/* eslint-disable react/iframe-missing-sandbox */
/* eslint-disable react/no-danger */
import React from "react"
import { Trans } from "react-i18next"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import LeftArrowIcon from "@mui/icons-material/KeyboardArrowLeftOutlined"
import RightArrowIcon from "@mui/icons-material/KeyboardArrowRightOutlined"
import { Modal } from "@mui/material"

import { type Attachment } from "~/types/apiTypes"

function isAttachmentPreviewable(attachment: Attachment): boolean {
  return Boolean(
    attachment.contentType &&
      (attachment.contentType.startsWith("image") ||
        attachment.contentType.startsWith("application/pdf") ||
        attachment.contentType.startsWith("text/plain") ||
        attachment.contentType.startsWith("video"))
  )
}

export default function AttachmentPreview({
  attachment,
  onClose,
  onClickPrevious,
  onClickNext,
}: {
  readonly attachment: Attachment
  readonly onClose: () => void
  readonly onClickPrevious: () => void
  readonly onClickNext: () => void
}) {
  return (
    <Modal
      onClose={onClose}
      onKeyDown={(event) => {
        if (event.key === "Escape") {
          onClose()
        } else if (event.key === "ArrowLeft") {
          onClickPrevious()
        } else if (event.key === "ArrowRight") {
          onClickNext()
        }
      }}
      open={Boolean(attachment)}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "#262626DD",
          },
        },
      }}
      sx={{
        "& .MuiBackdrop-root:focus-visible": {
          outline: "none", // Remove the outline when the modal is focused
        },
        "&:focus-visible": {
          outline: "none", // Remove the outline when the modal is focused
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start", // Align items to the start to ensure header is at the top
          alignItems: "center",
          height: "100%",
          "&:focus-visible": {
            outline: "none", // Remove the outline when the modal is focused
          },
        }}
      >
        <Box
          id="attachmentHeader"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "1rem",
            width: "100%", // Ensure the header takes up the full width
            backgroundColor: "#212121",
            color: "#fff",
            padding: "0.5rem", // Add some padding for better appearance
            position: "sticky", // Make the header sticky
            top: 0, // Stick the header to the top
            zIndex: 1, // Ensure the header is above the image
          }}
        >
          <IconButton onClick={onClose} sx={{ color: "#fff" }}>
            <CloseIcon />
          </IconButton>
          <Box>{attachment.name}</Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "100%",
            width: "100%",
            overflow: "auto", // Allow scrolling if the image is too large
            marginBottom: "1rem",
          }}
        >
          <Box
            sx={{
              minWidth: "96px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton onClick={onClickPrevious} sx={classes.navigationButton}>
              <LeftArrowIcon sx={{ fontSize: "1.5rem" }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {!isAttachmentPreviewable(attachment) ? (
              <Box
                sx={{
                  alignSelf: "center",
                }}
              >
                <Trans
                  components={{
                    anchor: <a href={attachment.signedUrl} />,
                  }}
                  i18nKey="component.emailAttachments.fileTypeNotPreviewable"
                />
              </Box>
            ) : !attachment.contentType?.startsWith("image") ? (
              <iframe
                src={attachment.signedUrl}
                style={{
                  border: "none",
                  height: "100%",
                  width: "80%",
                  objectFit: "contain",
                }}
              />
            ) : (
              <img
                alt={attachment.name}
                src={attachment.signedUrl}
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              minWidth: "96px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton onClick={onClickNext} sx={classes.navigationButton}>
              <RightArrowIcon sx={{ fontSize: "1.5rem" }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

const classes = {
  navigationButton: {
    color: "#fafafa",
    backgroundColor: "#171717",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#262626",
    },
  },
}
