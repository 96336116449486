import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { NetworkStatus, useQuery, useMutation } from "@apollo/client"
import { useNavigate, useParams } from "react-router-dom"
import Box from "@mui/material/Box"

import InboxPanel from "./components/InboxPanel"
import { Seo, SnackbarMessage, MainLayout, PageHeader } from "~/components"
import { InboxConversationStatus, Snack, SortDirection } from "~/types"
import { INBOX, useDebounce, parseGraphQLErrorCode } from "~/util"
import { ALL_INBOX_CONVERSATIONS } from "~/queries/allInboxConversations"
import { GET_INBOX_CONVERSATION_BY_ID } from "~/queries/getInboxConversationById"
import useStore from "~/store"
import { UPDATE_INBOX_CONVERSATION_STATUS } from "~/queries/updateInboxConversationStatus"

interface Props {
  readonly location?: {
    state?: {
      snack?: Snack
    }
  }
}

function InboxPage({ location }: Props) {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const inboxScreenSettings = useStore((state) => state.inboxScreenSettings)
  const setInboxScreenSettings = useStore((state) => state.setInboxScreenSettings)
  const debouncedSearchTerm = useDebounce(inboxScreenSettings.searchTerm, 500)
  const [lastSelectedConversationId, setLastSelectedConversationId] = useState<
    string | null | undefined
  >(null)
  const [selectedConversationIds, setSelectedConversationIds] = useState<
    string[] | null | undefined
  >(null)
  const [activeConversationId, setActiveConversationId] = useState<string | null | undefined>(id)
  const [snack, setSnack] = useState<Snack | null | undefined>(() => location?.state?.snack ?? null)
  const [isShiftPressed, setIsShiftPressed] = useState(false)

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setIsShiftPressed(true)
      }
    }

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setIsShiftPressed(false)
      }
    }

    window.addEventListener("keydown", handleKeyDown)
    window.addEventListener("keyup", handleKeyUp)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
      window.removeEventListener("keyup", handleKeyUp)
    }
  }, [])

  const {
    networkStatus: allInboxConversationsNetworkStatus,
    data: allInboxConversationsData,
    error: allInboxConversationsError,
    fetchMore,
  } = useQuery(ALL_INBOX_CONVERSATIONS, {
    variables: {
      filter: debouncedSearchTerm,
      first: 20,
      sortDir: inboxScreenSettings.sortDir,
      status: inboxScreenSettings.statusFilter,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  })

  const {
    loading: getInboxConversationByIdLoading,
    data: getInboxConversationByIdData,
    error: getInboxConversationByIdError,
  } = useQuery(GET_INBOX_CONVERSATION_BY_ID, {
    variables: {
      id: activeConversationId,
      markAsRead: true,
    },
    skip: !activeConversationId,
    fetchPolicy: "cache-and-network",
  })

  const [updateInboxConversationStatus, { loading: updateInboxConversationStatusLoading }] =
    useMutation(UPDATE_INBOX_CONVERSATION_STATUS, {
      refetchQueries: ["AllInboxConversations"],
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({
          messageKey: errorCode,
          variant: "error",
        })
      },
    })

  const conversations =
    allInboxConversationsData?.allInboxConversations.edges?.map((e: any) => e.node) ?? []
  const activeConversation = getInboxConversationByIdData?.getInboxConversationById

  return (
    <>
      <Seo title={t(INBOX.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={INBOX}>
        <Box sx={{ margin: "1.25rem", marginTop: "0" }}>
          <PageHeader icon={INBOX.icon} leafTitleKey={INBOX.titleKey} />
          <InboxPanel
            activeConversation={activeConversation}
            conversations={conversations}
            isLoadingConversations={allInboxConversationsNetworkStatus === NetworkStatus.loading}
            isLoadingMessages={getInboxConversationByIdLoading}
            isLoadingMoreConversations={
              allInboxConversationsNetworkStatus === NetworkStatus.fetchMore
            }
            onActivateConversation={(id: string) => {
              setActiveConversationId(id)
              setSelectedConversationIds(undefined)
              // Update the URL in the address bar with the selected conversation ID
              navigate(`/app/inbox/${id}`)
            }}
            onChangeSearchTerm={(searchTerm: string) => {
              setLastSelectedConversationId(null)
              setSelectedConversationIds(undefined)
              setInboxScreenSettings({ ...inboxScreenSettings, searchTerm })
            }}
            onChangeSort={(sortDir: SortDirection) =>
              setInboxScreenSettings({ ...inboxScreenSettings, sortDir })
            }
            onChangeStatusFilter={(statusFilter: InboxConversationStatus) => {
              setLastSelectedConversationId(null)
              setSelectedConversationIds(undefined)
              setInboxScreenSettings({ ...inboxScreenSettings, statusFilter })
            }}
            onClickChangeStatus={(status: InboxConversationStatus) => {
              setLastSelectedConversationId(null)

              let ids = [] as string[]
              if (selectedConversationIds) {
                if (
                  activeConversationId &&
                  selectedConversationIds.includes(activeConversationId)
                ) {
                  setActiveConversationId(null)
                }
                ids = selectedConversationIds
              } else if (activeConversationId) {
                ids = [activeConversationId]
                setActiveConversationId(null)
              }

              updateInboxConversationStatus({
                variables: {
                  conversationIds: ids,
                  status,
                },
              })
            }}
            onLoadMoreConversations={() => {
              fetchMore({
                variables: {
                  after: allInboxConversationsData?.allInboxConversations.pageInfo.endCursor,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev
                  return {
                    allInboxConversations: {
                      ...fetchMoreResult.allInboxConversations,
                      edges: [
                        ...prev.allInboxConversations.edges,
                        ...fetchMoreResult.allInboxConversations.edges,
                      ],
                    },
                  }
                },
              })
            }}
            onToggleConversationSelection={(conversationId: string, isSelected: boolean) => {
              // Store the id of the last conversation that was selected
              setLastSelectedConversationId(conversationId)

              setSelectedConversationIds((prev) => {
                if (!prev) {
                  return [conversationId]
                }

                // If the shift key is pressed, toggle all conversations between the last selected conversation and the given conversation
                if (isShiftPressed) {
                  // Find the index of the previously selected conversation in the conversations array
                  const prevIndex = conversations.findIndex(
                    (c) => c.id === lastSelectedConversationId
                  )
                  // Find the index of the newly selected conversation in the conversations array
                  const newIndex = conversations.findIndex((c) => c.id === conversationId)
                  // Get the conversations between the two indexes
                  const conversationsBetween = conversations.slice(
                    Math.min(prevIndex, newIndex),
                    Math.max(prevIndex, newIndex) + 1
                  )
                  // Get the IDs of the conversations between the two indexes
                  const conversationIdsBetween = conversationsBetween.map((c) => c.id)

                  // If the isSelected flag is true, add the IDs of the conversations between the two indexes to the selectedConversationIds array. Otherwise, remove them.
                  return isSelected
                    ? [...prev, ...conversationIdsBetween]
                    : prev.filter((id) => !conversationIdsBetween.includes(id))
                }

                if (prev.includes(conversationId)) {
                  return prev.filter((id) => id !== conversationId)
                }

                return [...prev, conversationId]
              })
            }}
            searchTerm={inboxScreenSettings.searchTerm}
            selectedConversationIds={selectedConversationIds}
            selectedSort={inboxScreenSettings.sortDir}
            selectedStatus={inboxScreenSettings.statusFilter}
            showLoadMore={Boolean(
              allInboxConversationsData?.allInboxConversations?.pageInfo?.hasNextPage
            )}
          />
        </Box>
      </MainLayout>
    </>
  )
}

export default InboxPage
