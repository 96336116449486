/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import Collapse from "@mui/material/Collapse"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import FormGroup from "@mui/material/FormGroup"
import FormHelperText from "@mui/material/FormHelperText"
import FormControlLabel from "@mui/material/FormControlLabel"
import isNil from "lodash/isNil"
import dayjs from "dayjs"

import FieldHelperText from "~/components/FieldHelperText"
import FielderTextField from "~/components/FielderTextField"
import MoneyInput from "~/components/MoneyInput"
import PositiveIntegerInput from "~/components/PositiveIntegerInput"
import RegionSelect from "~/components/RegionSelect"
import SelectorField from "~/components/SelectorField"
import SaveButton from "~/components/SaveButton"
import StaticField from "~/components/StaticField"
import { asFloat, isNumeric } from "~/util"
import { Item, ItemType, Region, StockType } from "~/types"
import { usePrompt } from "~/hooks/usePrompt"
import Sidebar from "./Sidebar"
import FranchiseeMultiSelect from "~/components/FranchiseeMultiSelect"

const TODAY = dayjs().startOf("day")
const MAX_DATE = TODAY.add(5, "years").startOf("day")

interface ValidationErrors {
  region: string | null
  code: string | null
  name: string | null
  description: string | null
  isIntendedForResale: string | null
  isCancelable: string | null
  type: string | null
  category: string | null
  msrp: string | null
  wholesalePrice: string | null
  wholesalePriceExpirationDate: string | null
  stockType: string | null
  leadTimeDays: string | null
  palletQty: string | null
  packSize: string | null
  isPackBreakable: string | null
}

interface Props {
  readonly item?: Item
  readonly loading?: boolean
  readonly onCancel: () => void
  readonly onSave: (payload: any) => void
  readonly onImageUpload?: () => void
}

function CatalogItemBasicInfoForm({ item, loading, onCancel, onSave, onImageUpload }: Props) {
  const { t } = useTranslation()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [state, setState] = useState(() => ({
    region: item?.region,
    isRestricted: Boolean(item?.isRestricted),
    restrictedTo: item?.restrictedTo ?? [], // note - an empty list is not equivalent to null!
    code: item?.code ?? "",
    name: item?.name ?? "",
    description: item?.description ?? "",
    isIntendedForResale: isNil(item?.isIntendedForResale) ? true : item?.isIntendedForResale,
    isCancelable: isNil(item?.isCancelable) ? true : item?.isCancelable,
    type: item?.type ?? ItemType.PRODUCT,
    category: item?.category ?? "",
    msrp: item?.msrp ?? "",
    wholesalePrice: isNumeric(item?.wholesalePrice) ? `${item?.wholesalePrice}` : "",
    wholesalePriceExpirationDate: item?.wholesalePriceExpirationDate
      ? dayjs(item.wholesalePriceExpirationDate)
      : null,
    stockType: item?.stockType ?? StockType.STOCKED,
    leadTimeDays: isNumeric(item?.leadTimeDays) ? `${item?.leadTimeDays}` : "",
    palletQty: isNumeric(item?.palletQty) ? `${item?.palletQty}` : "",
    packSize: isNumeric(item?.packSize) ? `${item?.packSize}` : "",
    isPackBreakable: Boolean(item?.isPackBreakable),
  }))
  const [errors, setErrors] = useState<ValidationErrors>(() => ({
    region: null,
    code: null,
    name: null,
    description: null,
    isIntendedForResale: null,
    isCancelable: null,
    type: null,
    category: null,
    msrp: null,
    wholesalePrice: null,
    wholesalePriceExpirationDate: null,
    stockType: null,
    leadTimeDays: null,
    palletQty: null,
    packSize: null,
    isPackBreakable: null,
  }))
  const ITEM_TYPE_OPTIONS = React.useMemo(
    () => [
      { id: ItemType.PRODUCT, name: t(`itemTypeOptions.PRODUCT`) },
      { id: ItemType.SERVICE, name: t(`itemTypeOptions.SERVICE`) },
    ],
    [t]
  )

  const STOCK_TYPE_OPTIONS = React.useMemo(
    () => [
      { id: StockType.STOCKED, name: t(`stockTypeOptions.STOCKED`) },
      { id: StockType.NON_STOCKED, name: t(`stockTypeOptions.NON_STOCKED`) },
      { id: StockType.SPECIAL_ORDER, name: t(`stockTypeOptions.SPECIAL_ORDER`) },
    ],
    [t]
  )

  usePrompt(t("messages.unsavedChangesNavPrompt"), isDirty)

  const handleBlurRequired = (field) => {
    if (!state[field]) {
      setErrors({
        ...errors,
        [field]: t(`component.itemBasicInfoForm.validation.${field}.required`),
      })
    } else {
      setErrors({
        ...errors,
        [field]: null,
      })
    }
  }

  const handleChange = (field: string, value: any) => {
    const updatedState = {
      ...state,
      [field]: value,
    }

    if (field === "type" && value !== ItemType.PRODUCT) {
      updatedState.stockType = StockType.NON_STOCKED
    }

    if (field === "region") {
      updatedState.restrictedTo = []
    }

    setState(updatedState)
    setIsDirty(true)
    setErrors({
      ...errors,
      [field]: null,
    })
  }

  function isValid() {
    return Object.values(errors).every((o) => o === null)
  }

  function handleSubmit() {
    const variables = {
      ...state,
      isArchived: false,
      msrp: isNumeric(state.msrp) ? asFloat(state.msrp) : null,
      wholesalePrice: isNumeric(state.wholesalePrice) ? asFloat(state.wholesalePrice) : null,
      leadTimeDays: isNumeric(state.leadTimeDays) ? asFloat(state.leadTimeDays) : null,
      palletQty: isNumeric(state.palletQty) ? asFloat(state.palletQty) : null,
      packSize: isNumeric(state.packSize) ? asFloat(state.packSize) : null,
      isPackBreakable: Boolean(state.isPackBreakable),
      isRestricted: Boolean(state.isRestricted),
      restrictedToOrganizationIds: state.isRestricted
        ? (state.restrictedTo ?? [])?.map((o) => o.id)
        : null,
    }
    if (item?.id) {
      variables.id = item.id
    } else {
      variables.regionId = state.region?.id
    }
    onSave?.({
      variables,
    })
  }

  return (
    <Box>
      <Box
        sx={(theme) => {
          return {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            flex: 1,
            [theme.breakpoints.up("md")]: {
              flexDirection: "row",
            },
          }
        }}
      >
        <Sidebar item={item} onImageUpload={onImageUpload} />
        <Box
          sx={(theme) => {
            return {
              flex: 1,
              paddingLeft: "0",
              marginTop: "1rem",
              [theme.breakpoints.up("md")]: {
                padding: "0rem 1rem 1rem 2rem",
                marginTop: 0,
              },
            }
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={[classes.formFieldContainer, { marginBottom: 0 }]}>
              {!item?.id ? (
                <>
                  <RegionSelect
                    error={!!errors.region}
                    label={t("region") as string}
                    name="region"
                    onBlur={() => handleBlurRequired("region")}
                    onChange={(value: Region | null) => {
                      handleChange("region", value)
                    }}
                    required
                    sx={{ marginTop: 0 }}
                    value={state.region}
                  />
                  {errors.region ? (
                    <FieldHelperText error message={errors.region} />
                  ) : (
                    <FieldHelperText message={t("component.itemBasicInfoForm.helperText.region")} />
                  )}
                </>
              ) : (
                <StaticField
                  label={t("region")}
                  style={{ paddingLeft: 0 }}
                  value={item.region?.name}
                />
              )}
            </Box>
            <Box sx={[classes.formFieldContainer, { marginBottom: "1rem" }]}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.isRestricted}
                    name="isRestricted"
                    onChange={(e) => handleChange("isRestricted", e.target.checked)}
                  />
                }
                disabled={!state.region?.id}
                id="isRestrictedLabel"
                label={
                  <Box sx={{ marginTop: "1rem" }}>
                    <Box>{t("component.itemBasicInfoForm.isRestricted.label") as string}</Box>
                    <FormHelperText>
                      {
                        t("component.itemBasicInfoForm.isRestricted.helperText", {
                          regionName: state.region?.name,
                        }) as string
                      }
                    </FormHelperText>
                  </Box>
                }
                labelPlacement="end"
              />
              <Collapse in={state.isRestricted}>
                <Box sx={{ marginTop: "1rem", paddingLeft: "2rem" }}>
                  <FranchiseeMultiSelect
                    disabled={!state.region?.id}
                    fullWidth
                    label={t("component.itemBasicInfoForm.restrictedTo.label") as string}
                    name="restrictedToOrganizationIds"
                    onChange={(selectedFranchisees) =>
                      handleChange("restrictedTo", selectedFranchisees)
                    }
                    regionId={state?.region?.id}
                    selectedFranchisees={state.restrictedTo}
                  />
                </Box>
              </Collapse>
            </Box>
            <Box sx={classes.formFieldContainer}>
              <FielderTextField
                data-testid="code-Field"
                error={!!errors.code}
                fullWidth
                id="code"
                inputProps={{
                  maxLength: 30,
                }}
                label={t("itemCode")}
                name="code"
                onBlur={() => handleBlurRequired("code")}
                onChange={(e) => handleChange("code", e.target.value)}
                onFocus={(e) => e.target.select()}
                required
                value={state.code}
              />
              {errors.code ? (
                <FieldHelperText error message={errors.code} />
              ) : (
                <FieldHelperText message={t("component.itemBasicInfoForm.helperText.itemCode")} />
              )}
            </Box>
            <Box sx={classes.formFieldContainer}>
              <FielderTextField
                data-testid="name-Field"
                error={!!errors.name}
                fullWidth
                id="name"
                inputProps={{
                  maxLength: 255,
                }}
                label={t("name")}
                name="name"
                onBlur={() => handleBlurRequired("name")}
                onChange={(e) => handleChange("name", e.target.value)}
                onFocus={(e) => e.target.select()}
                required
                value={state.name}
              />
              {errors.name ? (
                <FieldHelperText error message={errors.name} />
              ) : (
                <FieldHelperText message={t("component.itemBasicInfoForm.helperText.name")} />
              )}
            </Box>
            <Box sx={classes.formFieldContainer}>
              <FielderTextField
                data-testid="description-Field"
                fullWidth
                id="description"
                inputProps={{
                  maxLength: 1000,
                }}
                label={t("description")}
                maxRows="2"
                minRows="2"
                multiline
                name="description"
                onChange={(e) => handleChange("description", e.target.value)}
                value={state.description}
              />
              <FieldHelperText message={t("component.itemBasicInfoForm.helperText.description")} />
            </Box>
            <Box sx={classes.formFieldContainer}>
              <FielderTextField
                data-testid="category-Field"
                fullWidth
                id="category"
                inputProps={{
                  maxLength: 255,
                }}
                label={t("category")}
                name="category"
                onChange={(e) => handleChange("category", e.target.value)}
                onFocus={(e) => e.target.select()}
                value={state.category}
              />
              <FieldHelperText message={t("component.itemBasicInfoForm.helperText.category")} />
            </Box>
            {item?.id ? null : (
              <Box sx={classes.formFieldContainer}>
                <SelectorField
                  label={t("type") as string}
                  name="type"
                  onChange={(value) => {
                    handleChange("type", value.id)
                  }}
                  options={ITEM_TYPE_OPTIONS}
                  value={state.type}
                  variant="outlined"
                />
                <Collapse in={state.type === "PRODUCT"} timeout="auto" unmountOnExit>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.isIntendedForResale}
                          name="isIntendedForResale"
                          onChange={(e) => handleChange("isIntendedForResale", e.target.checked)}
                        />
                      }
                      label={t("isIntendedForResale") as string}
                    />
                  </FormGroup>
                </Collapse>
              </Box>
            )}
            <Box sx={classes.formFieldContainer}>
              <SelectorField
                disabled={state.type !== ItemType.PRODUCT}
                label={t("stockType") as string}
                name="stockType"
                onChange={(value) => handleChange("stockType", value.id)}
                options={STOCK_TYPE_OPTIONS}
                value={state.stockType}
                variant="outlined"
              />
            </Box>
            <Box sx={classes.formFieldContainer}>
              <PositiveIntegerInput
                data-testid="leadTimeDays-Field"
                fullWidth
                inputProps={{
                  maxLength: 12,
                }}
                label={t("leadTimeDays")}
                name="leadTimeDays"
                onChange={(e) => handleChange("leadTimeDays", e.target.value)}
                value={state.leadTimeDays}
              />
              <FieldHelperText message={t("component.itemBasicInfoForm.helperText.leadTimeDays")} />
            </Box>
            <Box sx={[classes.formFieldContainer, { paddingTop: 0 }]}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.isCancelable}
                    name="isCancelable"
                    onChange={(e) => handleChange("isCancelable", e.target.checked)}
                  />
                }
                label={t("isCancelable") as string}
              />
            </Box>
            <Box sx={classes.formFieldContainer}>
              <MoneyInput
                currencyCode={state.region?.currencyCode}
                data-testid="wholesalePrice-Field"
                fullWidth
                label={t("wholesalePrice") as string}
                max={999999999}
                name="wholesalePrice"
                onChange={(e) => handleChange("wholesalePrice", e.target.value)}
                value={state.wholesalePrice}
              />
              <FieldHelperText
                message={t("component.itemBasicInfoForm.helperText.wholesalePrice")}
              />
            </Box>
            <Box sx={classes.formFieldContainer}>
              <DatePicker
                aria-label={t("wholesalePriceExpirationDate")}
                disablePast
                format={t("format:dateFormat.short") as string}
                label={t("wholesalePriceExpirationDate")}
                maxDate={MAX_DATE}
                minDate={TODAY}
                onChange={(val) => {
                  if (!val || (val?.isValid() && val?.isBetween(TODAY, MAX_DATE, "day", "[]"))) {
                    setErrors({
                      ...errors,
                      wholesalePriceExpirationDate: null,
                    })
                    handleChange("wholesalePriceExpirationDate", val)
                  }
                }}
                onError={(reason) => {
                  if (reason === "minDate" || reason === "disablePast") {
                    setErrors({
                      ...errors,
                      wholesalePriceExpirationDate: t(
                        "component.itemBasicInfoForm.validation.wholesalePriceExpirationDate.minDate"
                      ),
                    })
                  } else if (reason === "maxDate") {
                    setErrors({
                      ...errors,
                      wholesalePriceExpirationDate: t(
                        "component.itemBasicInfoForm.validation.wholesalePriceExpirationDate.maxDate"
                      ),
                    })
                  } else if (reason === "invalidDate") {
                    setErrors({
                      ...errors,
                      wholesalePriceExpirationDate: t(
                        "component.itemBasicInfoForm.validation.wholesalePriceExpirationDate.invalid"
                      ),
                    })
                  }
                }}
                slotProps={{
                  field: {
                    clearable: true,
                  },
                  textField: {
                    InputProps: {
                      disableUnderline: true,
                    },
                    fullWidth: true,
                    required: false,
                  },
                }}
                slots={{
                  textField: FielderTextField,
                }}
                value={state.wholesalePriceExpirationDate}
              />
              {errors.wholesalePriceExpirationDate ? (
                <FieldHelperText error message={errors.wholesalePriceExpirationDate} />
              ) : (
                <FieldHelperText
                  message={t("component.itemBasicInfoForm.helperText.wholesalePriceExpirationDate")}
                />
              )}
            </Box>
            <Box sx={classes.formFieldContainer}>
              <MoneyInput
                currencyCode={state.region?.currencyCode}
                data-testid="msrp-Field"
                fullWidth
                label={t("msrp") as string}
                max={999999999}
                name="msrp"
                onChange={(e) => handleChange("msrp", e.target.value)}
                value={state.msrp}
              />
              <FieldHelperText
                message={t("component.itemBasicInfoForm.helperText.suggestedRetailPrice")}
              />
            </Box>
            <Box sx={classes.formFieldContainer}>
              <PositiveIntegerInput
                data-testid="packSize-Field"
                fullWidth
                label={t("packSize") as string}
                max={9999999}
                name="packSize"
                onChange={(e: any) => handleChange("packSize", e.target.value)}
                value={state.packSize}
              />
              <FieldHelperText message={t("component.itemBasicInfoForm.helperText.packSize")} />
            </Box>
            <Collapse in={Number(state.packSize) > 1}>
              <Box sx={[classes.formFieldContainer, { paddingTop: 0 }]}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.isPackBreakable}
                      name="isPackBreakable"
                      onChange={(e) => handleChange("isPackBreakable", e.target.checked)}
                    />
                  }
                  label={t("isPackBreakable") as string}
                />
                <FieldHelperText
                  message={t("component.itemBasicInfoForm.helperText.isPackBreakable")}
                />
              </Box>
            </Collapse>
            <Box sx={classes.formFieldContainer}>
              <PositiveIntegerInput
                data-testid="palletQty-Field"
                fullWidth
                label={t("palletQty") as string}
                max={9999999}
                name="palletQty"
                onChange={(e: any) => handleChange("palletQty", e.target.value)}
                value={state.palletQty}
              />
              <FieldHelperText message={t("component.itemBasicInfoForm.helperText.palletQty")} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => {
          return {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "1rem 0 0.25rem 0",
            [theme.breakpoints.up("md")]: {
              marginTop: "2rem",
              padding: 0,
            },
          }
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onCancel}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <SaveButton disabled={!isValid()} loading={loading} onClick={handleSubmit} />
      </Box>
    </Box>
  )
}

const classes = {
  formContainer: {
    padding: "0.5rem",
    marginBottom: "1.25rem",
    flexGrow: 2,
  },
  formFieldContainer: {
    paddingTop: "0.3125rem",
    paddingBottom: "0.3125rem",
    marginBottom: "0.75rem",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1.25rem",
    paddingBottom: "0.625rem",
  },
} as const

export default CatalogItemBasicInfoForm
