import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import MenuItem from "@mui/material/MenuItem"

import SelectorField from "~/components/SelectorField"
import { ALL_ESTIMATES_FOR_JOB } from "~/queries/allEstimatesForJob"
import { capitalize, formatMoney, getJobDocumentDisplayNumber } from "~/util"
import type { Estimate } from "~/types"

interface EstimateOptionProps {
  readonly estimate: Estimate
}

function EstimateOption({ estimate }: EstimateOptionProps) {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0",
        }}
      >
        <Box sx={{ fontSize: "0.875rem", fontWeight: "600" }}>
          {t("component.loadFromEstimateDialog.estimateNbr")}
          {getJobDocumentDisplayNumber(estimate)}
        </Box>
        <Box
          sx={{
            fontSize: "0.75rem",
            lineHeight: "0.88",
          }}
        >
          {t("status")}: {capitalize(estimate.status)}
        </Box>
      </Box>
      <Box
        sx={{
          textAlign: "right",
          alignSelf: "center",
          fontSize: "0.875rem",
          marginRight: "1rem",
        }}
      >
        <Box component="span" sx={{ fontWeight: "600" }}>
          {t("total")}
          {": "}
        </Box>
        {formatMoney(estimate.currencyCode, estimate.total ?? 0)}
      </Box>
    </Box>
  )
}

interface Props {
  readonly jobId: string
  readonly onCancel: () => void
  readonly onSelectEstimate: (estimate?: Estimate | null) => void
  readonly targetType: "invoice" | "workOrder"
}

function LoadFromEstimateDialog({ jobId, onCancel, onSelectEstimate, targetType }: Props) {
  const { t } = useTranslation()
  const [selectedEstimate, setSelectedEstimate] = useState<Estimate | null>(null)

  const { loading, data: allEstimatesData } = useQuery(ALL_ESTIMATES_FOR_JOB, {
    variables: {
      id: jobId,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (!selectedEstimate && data?.getJobById?.estimates?.length >= 1) {
        const estimate = data?.getJobById?.estimates[0]
        setSelectedEstimate(estimate)
      }
    },
  })

  const estimates = allEstimatesData?.getJobById?.estimates ?? []

  function handleSelect() {
    selectedEstimate && onSelectEstimate(selectedEstimate)
  }

  return (
    <Dialog
      aria-labelledby="load-from-estimate-dialog"
      data-testid="LoadFromEstimateDialog"
      fullWidth
      maxWidth="sm"
      onClose={onCancel}
      open
    >
      <DialogTitle
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        {t("component.loadFromEstimateDialog.title")}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            paddingBottom: 0,
            paddingTop: "1.5rem",
            px: "1rem",
          }}
        >
          <Box
            sx={{
              color: (theme) => theme.fielderColors.mutedText,
              marginTop: "0.625rem",
              marginBottom: "0.625rem",
            }}
          >
            {t(`component.loadFromEstimateDialog.description.${targetType}`)}
          </Box>
          <Box sx={classes.fieldContainer}>
            {estimates?.length === 0 ? (
              <Box
                sx={(theme) => ({
                  color: theme.fielderColors.mutedText,
                  border: `1px solid ${theme.fielderColors.mutedText}`,
                  borderRadius: "4px",
                  padding: "0.625rem",
                  backgroundColor: "#fff",
                })}
              >
                {t("component.loadFromEstimateDialog.noResults")}
              </Box>
            ) : (
              <SelectorField
                label={t("component.loadFromEstimateDialog.selectLabel") as string}
                name="estimate"
                onChange={(selection) => {
                  const estimate = estimates?.find((t) => t.id === selection.id)
                  setSelectedEstimate(estimate)
                }}
                options={estimates}
                renderOption={(estimate: Estimate) => {
                  return (
                    <MenuItem key={estimate.id} value={estimate.id}>
                      <EstimateOption estimate={estimate} />
                    </MenuItem>
                  )
                }}
                renderValue={(estimateId) => {
                  const estimate = estimates.find((q) => q.id === estimateId)
                  return <EstimateOption estimate={estimate} />
                }}
                value={selectedEstimate?.id ?? ""}
                variant="outlined"
              />
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          px: "1.5rem",
          paddingBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onCancel}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <Button
          color="primary"
          data-testid="loadButton"
          disabled={loading || !selectedEstimate}
          onClick={handleSelect}
          variant="contained"
        >
          {loading ? (
            <CircularProgress size={20} thickness={6.0} />
          ) : (
            <Box>{t("component.loadFromEstimateDialog.saveButtonLabel")}</Box>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const classes = {
  fieldContainer: {
    marginTop: "1.25rem",
  },
}

export default LoadFromEstimateDialog
