import gql from "graphql-tag"

export const EDIT_JOB_NOTE = gql`
  mutation EditJobNote($id: ID!, $text: String!) {
    editJobNote(input: { id: $id, text: $text }) {
      jobNote {
        id
        text
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`

