import React from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import { Theme } from "@mui/material/styles"
import Badge, { BadgeProps } from "@mui/material/Badge"
import Drawer from "@mui/material/Drawer"
import Hidden from "@mui/material/Hidden"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { SECTIONS } from "~/util"
import { useAuth } from "~/context/AuthContext"
import { AppSection, BadgeData } from "~/types"
import useStore, { unreadNotificationCountSelector, estimateRequestCountSelector } from "~/store"
import HeaderLogo from "./HeaderLogo"
import PoweredByFielder from "./PoweredByFielder"

const DrawerBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -24,
    top: "50%",
    backgroundColor: theme.fielderColors.brandPrimary,
    color: theme.fielderColors.black,
    fontWeight: "600",
  },
}))

interface ResponsiveDrawerProps {
  readonly activeSection?: AppSection
  readonly handleDrawerToggle: () => void
  readonly isOpen: boolean
}

function ResponsiveDrawer({
  activeSection = SECTIONS.find((s) => s.name === "dashboard"),
  handleDrawerToggle,
  isOpen,
}: ResponsiveDrawerProps) {
  const { t } = useTranslation("common", { useSuspense: typeof window !== "undefined" })
  const unreadNotificationCount = useStore(unreadNotificationCountSelector)
  const estimateRequestCount = useStore(estimateRequestCountSelector)
  const { user, hasPermissions, isImpersonating } = useAuth()

  const badgeData: BadgeData = {
    unreadNotificationCount,
    estimateRequestCount,
  }

  const menu = (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {isImpersonating ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "2rem",
            width: "100%",
            backgroundColor: (theme) => theme.fielderColors.brandPrimary,
            color: (theme) => theme.fielderColors.black,
            fontSize: "0.875rem",
            fontWeight: 600,
          }}
        />
      ) : null}
      <Box
        sx={(theme) => ({
          ...theme.mixins.toolbar,
          px: "0.3125rem",
        })}
      >
        <Box
          sx={{
            margin: "1.25rem",
            marginTop: "0.625rem",
            marginLeft: "0.625rem",
            maxWidth: "100%",
          }}
        >
          <HeaderLogo height="auto" width="100%" />
        </Box>
      </Box>
      <List
        sx={{
          paddingTop: 0,
        }}
      >
        {user?.organization?.level
          ? SECTIONS.filter((section) => section.orgLevels.includes(user.organization.level))
              .filter((section) => {
                return section.features.some((feature) => {
                  return hasPermissions?.(feature.requiredPermissions)
                })
              })
              .sort((a, b) => a.position - b.position)
              .map((section) => {
                const isActive = section === activeSection
                const badgeContent = section.getBadgeContent?.(badgeData)

                return (
                  <Link
                    css={{
                      textDecoration: "none",
                    }}
                    key={section.titleKey}
                    to={section.path}
                  >
                    <ListItem
                      button
                      data-testid={`MenuItem-${section.name}`}
                      sx={(theme: Theme) => ({
                        textDecoration: "none",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",

                        paddingLeft: "1.5rem",
                        color: isActive
                          ? theme.fielderComponents.drawer.activeColor
                          : theme.fielderComponents.drawer.color,
                        backgroundColor: isActive
                          ? theme.fielderComponents.drawer.activeBackgroundColor
                          : theme.fielderComponents.drawer.backgroundColor,
                      })}
                    >
                      {isActive ? (
                        <Box
                          sx={{
                            height: "100%",
                            width: "0.25rem",
                            position: "absolute",
                            left: 0,
                            top: 0,
                            backgroundColor: (theme) => theme.fielderComponents.drawer.activeColor,
                          }}
                        />
                      ) : null}
                      <ListItemIcon
                        sx={(theme) => ({
                          color: isActive
                            ? theme.fielderComponents.drawer.activeColor
                            : theme.fielderComponents.drawer.color,
                          minWidth: 48,
                        })}
                      >
                        {section.icon}
                      </ListItemIcon>
                      <DrawerBadge badgeContent={badgeContent} invisible={!badgeContent}>
                        <ListItemText primary={t(section.titleKey)} />
                      </DrawerBadge>
                    </ListItem>
                  </Link>
                )
              })
          : null}
      </List>

      <Box
        sx={{
          position: "absolute",
          bottom: "1.25rem",
          width: "100%",
        }}
      >
        {process.env.GATSBY_ACTIVE_ENV === "test" ? (
          <Box
            sx={{
              backgroundColor: "hsla(51, 91%, 55%, 1)",
              color: "hsla(52, 50%, 20%, 1)",
              borderRadius: "4px",
              padding: "1rem",
              margin: "1rem",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            This is the TEST environment
          </Box>
        ) : null}
        <PoweredByFielder />
      </Box>
    </Box>
  )

  return (
    <Box
      component="nav"
      sx={(theme) => ({
        width: {
          lg: theme.fielderComponents.drawer.width,
        },
        flexShrink: {
          lg: 0,
        },
      })}
    >
      <Hidden implementation="css" lgUp>
        <Drawer
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          anchor="left"
          aria-label="main navigation"
          onClose={handleDrawerToggle}
          open={isOpen}
          role="navigation"
          sx={(theme) => ({
            "& .MuiDrawer-paper": {
              width: theme.fielderComponents.drawer.width,
              background: theme.fielderComponents.drawer.backgroundColor,
              color: theme.fielderComponents.drawer.color,
              border: "none",
            },
          })}
          variant="temporary"
        >
          {menu}
        </Drawer>
      </Hidden>
      <Hidden implementation="css" lgDown>
        <Drawer
          anchor="left"
          aria-label="main navigation"
          onClose={handleDrawerToggle}
          open
          role="navigation"
          sx={(theme) => ({
            "& .MuiDrawer-paper": {
              width: theme.fielderComponents.drawer.width,
              background: theme.fielderComponents.drawer.backgroundColor,
              color: theme.fielderComponents.drawer.color,
              border: "none",
            },
          })}
          variant="permanent"
        >
          {menu}
        </Drawer>
      </Hidden>
    </Box>
  )
}

export default ResponsiveDrawer
