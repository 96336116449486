import React from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import PhoneIcon from "@mui/icons-material/Phone"
import EmailIcon from "@mui/icons-material/Email"
import Divider from "@mui/material/Divider"
import Button from "@mui/material/Button"
import FlagIcon from "@mui/icons-material/Flag"

import {
  capitalize,
  formatAddress,
  formatDate,
  formatPersonName,
  formatPhoneNumber,
  NOT_SPECIFIED,
} from "~/util"
import { Job, User } from "~/types"
import { useAuth } from "~/context/AuthContext"
import PERMISSION_REQUIREMENTS from "~/util/permissionRequirements"

interface Props {
  readonly job: Job
  readonly user: User
}

function JobBasicInfoStatic({ job, user }: Props) {
  const { t } = useTranslation()
  const { hasPermissions } = useAuth()
  const navigate = useNavigate()

  return (
    <>
      <Box sx={classes.staticFieldContainer}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box>
            <label>{t("customer")}</label>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ wordBreak: "break-word" }}>{job.customer?.name ?? NOT_SPECIFIED}</Box>
              {job.customer?.type === "INDIVIDUAL" && (
                <>
                  {job.siteContact?.phoneNumber ? (
                    <Box sx={classes.contactInfoContainer}>
                      <PhoneIcon sx={classes.contactInfoIcon} />{" "}
                      {formatPhoneNumber(job.siteContact.phoneNumber)}
                    </Box>
                  ) : null}
                  {job.siteContact?.email ? (
                    <Box sx={classes.contactInfoContainer}>
                      <EmailIcon sx={classes.contactInfoIcon} /> {job.siteContact.email}
                    </Box>
                  ) : null}
                </>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "1rem",
              maxWidth: "180px",
            }}
          >
            {job.customer.flags?.length > 0 ? (
              <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0" }}>
                <FlagIcon
                  style={{
                    color: job.customer.flags[0].colorCode,
                    fontSize: "20px",
                    marginTop: "2px",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      color: (theme) => theme.fielderColors.black,
                      fontWeight: "600",
                      fontSize: "0.875rem",
                    }}
                  >
                    {job.customer.flags[0].name}
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      color: (theme) => theme.fielderColors.mutedText,
                      lineHeight: "1",
                      fontSize: "0.75rem",
                    }}
                  >
                    {job.customer.flags[0].description}
                  </Box>
                </Box>
              </Box>
            ) : null}
            {job.customer && hasPermissions?.(PERMISSION_REQUIREMENTS.customers.read) ? (
              <Button
                color="secondary"
                data-testid="viewCustomerButton"
                onClick={() => {
                  navigate(`/app/customers/edit/${job.customer.id}`)
                }}
                sx={{ fontSize: "0.875rem" }}
                variant="outlined"
              >
                {t("viewCustomer")}
              </Button>
            ) : null}
          </Box>
        </Box>
        <Divider />
      </Box>
      {job.customer?.type === "BUSINESS" && (
        <Box sx={classes.staticFieldContainer}>
          <label>{t("siteContact")}</label>
          {job.siteContact ? (
            <Box>
              {formatPersonName(job.siteContact)}
              {job.siteContact.jobTitle ? (
                <Box>
                  <i>{job.siteContact.jobTitle}</i>
                </Box>
              ) : null}
              {job.siteContact.phoneNumber ? (
                <Box sx={classes.contactInfoContainer}>
                  <PhoneIcon sx={classes.contactInfoIcon} />{" "}
                  {formatPhoneNumber(job.siteContact.phoneNumber)}
                </Box>
              ) : null}
              {job.siteContact.email ? (
                <Box sx={classes.contactInfoContainer}>
                  <EmailIcon sx={classes.contactInfoIcon} /> {job.siteContact.email}
                </Box>
              ) : null}
            </Box>
          ) : null}
          {!job.siteContact && <Box>{NOT_SPECIFIED}</Box>}
          <Divider />
        </Box>
      )}
      <Box sx={classes.staticFieldContainer}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <label>{t("workflow")}</label>
            <Box>{job.workflow.name}</Box>
          </Box>
        </Box>
        <Divider />
      </Box>
      <Box sx={classes.staticFieldContainer}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <label>{t("status")}</label>
            <Box>
              {job.workflowStep?.jobStatus
                ? capitalize(job.workflowStep.jobStatus.name)
                : NOT_SPECIFIED}
            </Box>
          </Box>
          {job.workflowStep?.jobStatus?.mediumColor ? (
            <Box
              style={{
                width: 30,
                height: 30,
                borderRadius: 6,
                backgroundColor: job.workflowStep.jobStatus.mediumColor,
              }}
            />
          ) : null}
        </Box>
        <Divider />
      </Box>
      <Box sx={classes.staticFieldContainer}>
        <label>{t("categories")}</label>
        {job.categories?.map((cat) => <Box key={cat.id}>{cat.name}</Box>)}
        {!job.categories || (job.categories.length === 0 && <Box>{NOT_SPECIFIED}</Box>)}
        <Divider />
      </Box>
      <Box sx={classes.staticFieldContainer}>
        <label>{t("address")}</label>
        <Box>{formatAddress(job.address?.addressString)}</Box>
        <Divider />
      </Box>
      <Box sx={classes.staticFieldContainer}>
        <label>{t("description")}</label>
        <Box
          sx={{
            whiteSpace: "pre-wrap",
          }}
        >
          {job.description || NOT_SPECIFIED}
        </Box>
        <Divider />
      </Box>
      <Box sx={classes.staticFieldContainer}>
        <label>{t("desiredCompletionDate")}</label>
        <Box>
          {formatDate(
            job.desiredCompletionDate,
            t("format:dateFormat.long"),
            user?.organization?.timeZone,
            user
          )}
        </Box>
      </Box>
    </>
  )
}

const classes = {
  staticFieldContainer: {
    padding: "1.25rem",
    paddingTop: "0.3125rem",
    paddingBottom: "0.3125rem",
    "& label": {
      fontWeight: 700,
      marginBottom: "0.25rem",
    },
    "& hr": {
      marginTop: "0.625rem",
    },
  },
  contactInfoContainer: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
  },
  contactInfoIcon: {
    marginTop: "0.125rem",
    marginRight: "0.25rem",
    fontSize: "1rem",
  },
} as const

export default JobBasicInfoStatic
