import * as React from "react"
import { SVGProps } from "react"

interface Props extends SVGProps<SVGSVGElement> {
  readonly color?: string
  readonly width?: number
  readonly height?: number
}

const ReplyIcon = ({ color = "#000", width = 24, height = 24, ...other }: Props) => (
  <svg
    fill={color}
    height={height}
    viewBox="0 -960 960 960"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    {...other}
  >
    <path d="M760-200v-160q0-50-35-85t-85-35H273l144 144-57 56-240-240 240-240 57 56-144 144h367q83 0 141.5 58.5T840-360v160h-80Z" />
  </svg>
)

export default ReplyIcon
