import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import CircularProgress from "@mui/material/CircularProgress"

import HeaderBar from "./HeaderBar"
import ConversationList from "./ConversationList"
import { InboxConversation, InboxConversationStatus, SortDirection } from "~/types/apiTypes"
import TagChip from "./TagChip"
import ConversationMessage from "./ConversationMessage"
import inboxEmptyStateImage from "~/images/inbox-empty-state.png"
import inboxNoMatchImage from "~/images/inbox-no-match.png"

interface Props {
  readonly activeConversation?: InboxConversation | null
  readonly conversations: InboxConversation[]
  readonly isLoadingConversations: boolean
  readonly isLoadingMessages: boolean
  readonly isLoadingMoreConversations: boolean
  readonly onChangeSearchTerm: (term: string) => void
  readonly onChangeSort: (sort: SortDirection) => void
  readonly onChangeStatusFilter: (status: InboxConversationStatus) => void
  readonly onClickChangeStatus: (newStatus: InboxConversationStatus) => void
  readonly onLoadMoreConversations: () => void
  readonly onActivateConversation: (conversationId: string) => void
  readonly onToggleConversationSelection: (conversationId: string, isSelected: boolean) => void
  readonly searchTerm: string
  readonly selectedConversationIds?: string[] | null
  readonly selectedSort: SortDirection
  readonly selectedStatus: InboxConversationStatus
  readonly showLoadMore: boolean
}

export default function InboxPanel({
  activeConversation,
  conversations,
  isLoadingConversations,
  isLoadingMessages,
  isLoadingMoreConversations,
  onChangeSearchTerm,
  onChangeStatusFilter,
  onChangeSort,
  onClickChangeStatus,
  onLoadMoreConversations,
  onActivateConversation,
  onToggleConversationSelection,
  searchTerm,
  selectedConversationIds,
  selectedStatus,
  selectedSort,
  showLoadMore,
}: Props) {
  const { t } = useTranslation()

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "calc(100vh - 8.5rem)",
        borderRadius: "4px",
        overflow: "hidden",
      }}
    >
      <Box sx={{ padding: "0.5rem" }}>
        <HeaderBar
          currentStatusFilter={selectedStatus}
          onChangeSearchTerm={onChangeSearchTerm}
          onClickChangeStatus={onClickChangeStatus}
          onClickConnectWithJob={function (): void {
            throw new Error("Function not implemented.")
          }}
          onClickLabel={function (): void {
            throw new Error("Function not implemented.")
          }}
          searchTerm={searchTerm}
        />
      </Box>
      <Divider />
      <Box sx={{ display: "flex", flexDirection: "row", flex: 1, height: "calc(100vh - 13rem)" }}>
        {isLoadingConversations ? (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <CircularProgress color="secondary" size={32} thickness={6.0} />
            <Box>{t("page.inbox.loadingConversations")}...</Box>
          </Box>
        ) : (
          <>
            <ConversationList
              activeConversation={activeConversation}
              conversations={conversations}
              isLoadingMoreConversations={isLoadingMoreConversations}
              onActivateConversation={onActivateConversation}
              onChangeSort={onChangeSort}
              onChangeStatusFilter={onChangeStatusFilter}
              onLoadMoreConversations={onLoadMoreConversations}
              onToggleConversationSelection={onToggleConversationSelection}
              selectedConversationIds={selectedConversationIds}
              selectedSort={selectedSort}
              selectedStatus={selectedStatus}
              showLoadMore={showLoadMore}
            />
            <Box
              sx={{
                padding: "0.5rem",
                paddingBottom: "3rem",
                backgroundColor: "#fff",
                flex: 1,
                borderLeft: (theme) => `1px solid ${theme.fielderColors.lightGrey}`,
                height: "100%",
                overflowY: "scroll",
              }}
            >
              {isLoadingMessages ? (
                <Box
                  sx={{
                    flex: 1,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <CircularProgress color="secondary" size={32} thickness={6.0} />
                  <Box>{t("page.inbox.loadingMessages")}...</Box>
                </Box>
              ) : !conversations || conversations.length === 0 ? (
                <Box
                  sx={{
                    flex: 1,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Box sx={{ textAlign: "center" }}>
                    <img
                      alt="empty inbox"
                      height="auto"
                      src={
                        searchTerm?.trim()?.length === 0 ? inboxEmptyStateImage : inboxNoMatchImage
                      }
                      width="208px"
                    />
                    <Box sx={{ fontSize: "2rem" }}>
                      {searchTerm?.trim()?.length === 0
                        ? t("page.inbox.emptyState.title")
                        : t("page.inbox.noMatch.title")}
                    </Box>
                    <Box
                      sx={{ fontSize: "1.1rem", color: (theme) => theme.fielderColors.mutedText }}
                    >
                      {searchTerm?.trim()?.length === 0
                        ? t("page.inbox.emptyState.description")
                        : t("page.inbox.noMatch.description")}
                    </Box>
                  </Box>
                </Box>
              ) : activeConversation ? (
                <>
                  <Box
                    sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.25rem",
                        padding: "0.25rem",
                        marginLeft: "2.5rem",
                      }}
                    >
                      {activeConversation?.tags?.map((t) => <TagChip key={t.id} tag={t} />)}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "1rem",
                        padding: "0.25rem",
                        marginLeft: "2.5rem",
                      }}
                    >
                      {activeConversation?.job ? (
                        <Link to={`/app/jobs/edit/${activeConversation.job.id}`}>
                          <Box
                            sx={{
                              color: (theme) => theme.fielderColors.text,
                              textDecoration: "underline",
                              textDecorationColor: (theme) => theme.fielderColors.text,
                              fontWeight: 600,
                            }}
                          >
                            {t("page.inbox.jobNumberLink", {
                              jobNumber: activeConversation.job.number,
                            })}
                          </Box>
                        </Link>
                      ) : null}
                      {activeConversation?.customer ? (
                        <Link to={`/app/customers/edit/${activeConversation.customer.id}`}>
                          <Box
                            sx={{
                              color: (theme) => theme.fielderColors.text,
                              textDecoration: "underline",
                              textDecorationColor: (theme) => theme.fielderColors.text,
                              fontWeight: 600,
                            }}
                          >
                            {activeConversation.customer.name}
                          </Box>
                        </Link>
                      ) : null}
                    </Box>
                  </Box>
                  {activeConversation?.messages?.map((message, index) => (
                    <Fragment key={message.id}>
                      <ConversationMessage
                        isLatestMessage={message.id === activeConversation.latestMessage.id}
                        message={message}
                      />
                      {index < activeConversation.messages.length - 1 ? (
                        <Divider
                          sx={{ backgroundColor: "#f9fafb", margin: "0.5rem 0 0.5rem 2.5rem" }}
                        />
                      ) : null}
                    </Fragment>
                  ))}
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                    height: "100%",
                    fontWeight: "600",
                    fontSize: "1.25rem",
                    color: (theme) => theme.fielderColors.mutedText,
                  }}
                >
                  {t("page.inbox.noConversationSelected")}
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
    </Paper>
  )
}
