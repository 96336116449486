import React, { Fragment } from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"

import { InboxConversation, InboxConversationStatus, SortDirection } from "~/types/apiTypes"
import ConversationSummary from "./ConversationSummary"
import ConversationListMenuBar from "./ConversationListMenuBar"
import { t } from "i18next"
import { CircularProgress } from "@mui/material"

interface Props {
  readonly conversations: InboxConversation[]
  readonly isLoadingMoreConversations: boolean
  readonly activeConversation?: InboxConversation | null
  readonly onChangeStatusFilter: (status: InboxConversationStatus) => void
  readonly onChangeSort: (sort: SortDirection) => void
  readonly onLoadMoreConversations: () => void
  readonly onActivateConversation: (conversationId: string) => void
  readonly onToggleConversationSelection: (conversationId: string, isSelected: boolean) => void
  readonly selectedConversationIds?: string[] | null
  readonly selectedStatus: InboxConversationStatus
  readonly selectedSort: SortDirection
  readonly showLoadMore: boolean
}

export default function ConversationList({
  activeConversation,
  conversations,
  isLoadingMoreConversations,
  onChangeStatusFilter,
  onChangeSort,
  onLoadMoreConversations,
  onActivateConversation,
  onToggleConversationSelection,
  selectedConversationIds,
  selectedStatus,
  selectedSort,
  showLoadMore,
}: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "25rem",
        maxWidth: "500px",
      }}
    >
      <ConversationListMenuBar
        onChangeSort={onChangeSort}
        onChangeStatusFilter={onChangeStatusFilter}
        selectedSort={selectedSort}
        selectedStatus={selectedStatus}
      />
      <Box
        sx={{
          height: "100%",
          maxHeight: "calc(100vh - 6rem)",
          overflowY: "scroll",
          paddingBottom: "4rem",
        }}
      >
        {conversations.map((conversation) => (
          <Fragment key={conversation.id}>
            <ConversationSummary
              conversation={conversation}
              isActive={activeConversation?.id === conversation.id}
              isSelected={selectedConversationIds?.includes(conversation.id) ?? false}
              onActivate={onActivateConversation}
              onToggleConversationSelection={onToggleConversationSelection}
            />
            <Divider />
          </Fragment>
        ))}
        {showLoadMore ? (
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Button
              disabled={isLoadingMoreConversations}
              onClick={onLoadMoreConversations}
              sx={{ flex: "1", padding: "2rem 0" }}
              variant="text"
            >
              {isLoadingMoreConversations ? (
                <CircularProgress size={20} thickness={6.0} />
              ) : (
                t("loadMore")
              )}
            </Button>
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}
