/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react"
import { Navigate, useLocation, NavigateProps } from "react-router-dom"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FlagIcon from "@mui/icons-material/Flag"
import CircularProgress from "@mui/material/CircularProgress"
import Paper from "@mui/material/Paper"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import TableContainer from "@mui/material/TableContainer"
import TablePagination from "@mui/material/TablePagination"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import AddIcon from "@mui/icons-material/AddOutlined"
import WarningIcon from "@mui/icons-material/WarningOutlined"
import EditIcon from "@mui/icons-material/EditOutlined"
import SyncIcon from "@mui/icons-material/SyncOutlined"
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined"
import Skeleton from "@mui/material/Skeleton"
import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import EmptyState from "~/components/EmptyState"
import SortableTableHeader from "~/components/SortableTableHeader"
import FielderIconButton from "~/components/FielderIconButton"
import TablePaginationActions from "~/components/TablePaginationActions"
import SearchField from "~/components/SearchField"
import { ALL_CUSTOMERS } from "~/queries/allCustomers"
import { SYNC_CUSTOMERS } from "~/queries/syncCustomers"
import { isBlank, NOT_SPECIFIED, CUSTOMERS, parseGraphQLErrorCode, useDebounce } from "~/util"
import { useAuth } from "~/context/AuthContext"
import useStore, {
  includeArchivedCustomersToggleSelector,
  setIncludeArchivedCustomersToggleSelector,
} from "~/store"
import { Snack, DefaultPermission, SortDirection as SortDirectionType } from "~/types"
import PERMISSION_REQUIREMENTS from "~/util/permissionRequirements"
import useGetAccountingPlugin from "~/hooks/useGetAccountingPlugin"
import { AddressDisplay } from "~/components/AddressDisplay"

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100]

function CustomerList() {
  const { t } = useTranslation()
  const location = useLocation()
  const { hasPermissions, user } = useAuth()
  const customerListSettings = useStore((state) => state.customerListSettings)
  const setCustomerListSettings = useStore((state) => state.setCustomerListSettings)
  const includeArchivedCustomersToggle = useStore(includeArchivedCustomersToggleSelector)
  const setIncludeArchivedCustomersToggle = useStore(setIncludeArchivedCustomersToggleSelector)
  const [snack, setSnack] = useState<Snack | undefined>(() => {
    const { state } = location
    return state?.snack
  })
  const [filter, setFilter] = useState<string>(customerListSettings.searchTerm)
  const [after, setAfter] = useState<number | null>()
  const [before, setBefore] = useState<number | null>()
  const [first, setFirst] = useState<number | null>(customerListSettings.rowsPerPage)
  const [last, setLast] = useState<number | null>()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const canViewDetails = hasPermissions?.(PERMISSION_REQUIREMENTS.customers.edit)
  const { accountingPlugin } = useGetAccountingPlugin(user?.organization?.id)
  const debouncedSearchTerm = useDebounce(filter, 500)

  useEffect(() => {
    if (debouncedSearchTerm !== customerListSettings.searchTerm) {
      setCustomerListSettings({
        ...customerListSettings,
        searchTerm: debouncedSearchTerm,
      })
      setAfter(null)
      setBefore(null)
      setFirst(customerListSettings.rowsPerPage)
      setLast(null)
    }
  }, [debouncedSearchTerm, customerListSettings, setCustomerListSettings])

  const { loading, error, data, refetch } = useQuery(ALL_CUSTOMERS, {
    variables: {
      filter: debouncedSearchTerm,
      after,
      before,
      first,
      last,
      sortBy: customerListSettings.sortBy,
      sortDir: customerListSettings.sortDir,
      includeArchived: Boolean(includeArchivedCustomersToggle),
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    context: {
      debounceKey: "ALL_CUSTOMERS",
      debounceTimeout: 50,
    },
  })

  const [syncCustomers, { loading: syncLoading }] = useMutation(SYNC_CUSTOMERS, {
    onCompleted: () => {
      setSnack({
        messageKey: "page.customerList.sync.success",
        variant: "success",
      })
      refetch()
    },
    onError: (error) => {
      const errorCode = parseGraphQLErrorCode(error)

      if (errorCode.includes("organization-plugin.accounting-plugin")) {
        setSnack({ messageKey: errorCode, variant: "error" })
      } else {
        Sentry.captureException(error)
        setSnack({
          messageKey: "page.customerList.sync.error",
          variant: "error",
        })
      }
    },
  })

  function onGoToFirstPage() {
    setAfter(null)
    setBefore(null)
    setFirst(customerListSettings.rowsPerPage)
    setLast(null)
  }

  function onGoToLastPage() {
    setAfter(null)
    setBefore(null)
    setFirst(null)
    setLast(customerListSettings.rowsPerPage)
  }

  function onGoToPrevious() {
    setAfter(null)
    setBefore(pageInfo.startCursor)
    setFirst(null)
    setLast(customerListSettings.rowsPerPage)
  }

  function onGoToNext() {
    setAfter(pageInfo.endCursor)
    setBefore(null)
    setFirst(customerListSettings.rowsPerPage)
    setLast(null)
  }

  function handleChangeRowsPerPage(event: any) {
    const newPageSize = +event.target.value

    setCustomerListSettings({
      ...customerListSettings,
      rowsPerPage: newPageSize,
      currentPage: 0,
    })

    setAfter(null)
    setBefore(null)
    setFirst(newPageSize)
    setLast(null)
  }

  function sort(propertyName: string) {
    const newSortBy = propertyName
    const newSortDir =
      propertyName !== customerListSettings.sortBy
        ? SortDirectionType.ASC
        : customerListSettings.sortDir === SortDirectionType.ASC
          ? SortDirectionType.DESC
          : SortDirectionType.ASC

    setCustomerListSettings({
      ...customerListSettings,
      sortBy: newSortBy,
      sortDir: newSortDir,
      currentPage: 0,
    })

    setAfter(null)
    setBefore(null)
    setFirst(customerListSettings.rowsPerPage)
    setLast(null)
  }

  const handleSearchChange = (val: string) => {
    setFilter(val)
    setAfter(null)
    setBefore(null)
    setFirst(customerListSettings.rowsPerPage)
    setLast(null)
  }

  function handleRowClick(id: string) {
    if (canViewDetails) {
      setRedirectTo({
        to: `/app/customers/edit/${id}`,
        replace: false,
      })
    }
  }

  const pageInfo = data?.allCustomers?.pageInfo || {}
  const customers = data?.allCustomers?.edges?.filter((edge) => {
    const customer = edge.node
    return !customer.isArchived || (Boolean(includeArchivedCustomersToggle) && customer.isArchived)
  })

  const NUM_COLUMNS = canViewDetails ? 5 : 4

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t(CUSTOMERS.titleKey)} />
      {snack ? (
        <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} testID="snack" />
      ) : null}
      <MainLayout activeSection={CUSTOMERS}>
        <Box
          sx={{
            margin: "0 1.25rem",
            marginRight: "80px", // make sure the intercom chat bubble doesn't block the pagination controls
            paddingBottom: "12.5rem",
            minWidth: "64rem",
          }}
        >
          <PageHeader icon={CUSTOMERS.icon} leafTitleKey={CUSTOMERS.titleKey} />
          <Box
            sx={{
              marginBottom: "20px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
              <Box
                sx={{
                  width: "25rem",
                }}
              >
                <SearchField
                  onChange={handleSearchChange}
                  placeholder={t("searchCustomers")}
                  term={filter}
                  testID="SearchField"
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(includeArchivedCustomersToggle)}
                      onChange={(event, checked) => {
                        setIncludeArchivedCustomersToggle(checked)
                      }}
                      value="includeArchived"
                    />
                  }
                  label={t("includeArchivedCustomers") as string}
                  sx={{
                    marginRight: "1.5rem",
                  }}
                />
              </Box>
            </Box>
            {hasPermissions?.([DefaultPermission.CreateCustomer]) ? (
              <Box>
                {accountingPlugin?.pluginProvider ? (
                  <Button
                    aria-label={t(`syncWithPlugin.${accountingPlugin.pluginProvider.id}`) as string}
                    data-testid="syncCustomersButton"
                    disabled={syncLoading}
                    onClick={() => syncCustomers()}
                    sx={(theme) => ({
                      marginLeft: "1.25rem",
                      [theme.breakpoints.down(700)]: {
                        marginLeft: 0,
                        marginTop: "0.3125rem",
                        marginBottom: "0.3125rem",
                      },
                      fontWeight: "bold",
                      "& svg": {
                        fontSize: "1.0rem",
                      },
                      "& div": {
                        marginLeft: "0.625rem",
                        marginRight: "0.625rem",
                      },
                    })}
                    variant="text"
                  >
                    {syncLoading ? (
                      <CircularProgress color="secondary" size={20} thickness={6.0} />
                    ) : (
                      <>
                        <SyncIcon />
                        <Box>{t(`syncWithPlugin.${accountingPlugin?.pluginProvider?.id}`)}</Box>
                      </>
                    )}
                  </Button>
                ) : null}
                <Button
                  aria-label={t("createCustomer") as string}
                  color="primary"
                  data-testid="createCustomerButton"
                  onClick={() => {
                    setRedirectTo({
                      to: "/app/customers/create",
                      replace: false,
                    })
                  }}
                  sx={{
                    fontWeight: "bold",
                    "& svg": {
                      fontSize: "1.0rem",
                    },
                    "& div": {
                      marginLeft: "0.625rem",
                      marginRight: "0.625rem",
                    },
                  }}
                  variant="contained"
                >
                  <AddIcon fontSize="small" />
                  <Box>{t("createCustomer")}</Box>
                </Button>
              </Box>
            ) : null}
          </Box>
          {!error && !loading && customers?.length === 0 && (
            <Box sx={{ paddingTop: "1.875rem", paddingBottom: "1.875rem" }}>
              {isBlank(filter) && (
                <EmptyState title={t("page.customerList.emptyState.title")}>
                  {hasPermissions?.([DefaultPermission.CreateCustomer]) ? (
                    <Box>{t("page.customerList.emptyState.message")}</Box>
                  ) : null}
                </EmptyState>
              )}
              {!isBlank(filter) && (
                <EmptyState title={t("page.customerList.noMatchingResults.title")}>
                  <Box>{t("page.customerList.noMatchingResults.message")}</Box>
                </EmptyState>
              )}
            </Box>
          )}
          {!error && (loading || customers?.length > 0) ? (
            <Paper
              sx={{
                overflowX: "auto",
              }}
            >
              <TableContainer
                sx={{
                  maxHeight: "calc(75vh)",
                  height: "calc(75vh - 50px)",
                }}
              >
                <Table
                  sx={[
                    {
                      width: "100%",
                      minWidth: "48rem",
                      tableLayout: "fixed",
                      "& th, & td": {
                        padding: "0.375rem 1rem",
                      },
                    },
                    customers?.length == 0 && { height: "100%" },
                  ]}
                >
                  <TableHead>
                    <TableRow>
                      <SortableTableHeader
                        isActiveSort={customerListSettings.sortBy === "name"}
                        label={t("name")}
                        onClick={() => sort("name")}
                        sortDir={customerListSettings.sortDir}
                        sx={classes.tableHead}
                        width="30%"
                      />
                      <SortableTableHeader
                        isActiveSort={customerListSettings.sortBy === "type"}
                        label={t("type")}
                        onClick={() => sort("type")}
                        sortDir={customerListSettings.sortDir}
                        sx={classes.tableHead}
                      />
                      <TableCell sx={[classes.tableHead, { minWidth: "16rem" }]}>
                        {t("address")}
                      </TableCell>
                      <SortableTableHeader
                        isActiveSort={customerListSettings.sortBy === "email"}
                        label={t("email")}
                        onClick={() => sort("email")}
                        sortDir={customerListSettings.sortDir}
                        sx={classes.tableHead}
                      />
                      {canViewDetails ? (
                        <TableCell sx={classes.tableHead} width="10%">
                          {" "}
                        </TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!error &&
                      !loading &&
                      customers?.map((edge) => {
                        const { node } = edge
                        return (
                          <TableRow
                            key={node.id}
                            onClick={() => handleRowClick(node.id)}
                            sx={classes.dataRow}
                          >
                            <TableCell>
                              <Box style={{ display: "flex", flexDirection: "column" }}>
                                <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {node.isArchived ? (
                                      <WarningIcon
                                        sx={{
                                          fontSize: "1.125rem",
                                          color: "#DA1505",
                                          marginRight: "0.625rem",
                                        }}
                                      />
                                    ) : null}{" "}
                                    {node.name}
                                  </Box>
                                  {node.flags?.length > 0 ? (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: "0.25rem",
                                      }}
                                    >
                                      <FlagIcon
                                        style={{
                                          color: node.flags[0].colorCode,
                                          fontSize: "20px",
                                          marginTop: "2px",
                                        }}
                                      />
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: "0",
                                          alignItems: "start",
                                        }}
                                      >
                                        <Box
                                          component="span"
                                          sx={{
                                            color: (theme) => theme.fielderColors.black,
                                            fontWeight: "600",
                                          }}
                                        >
                                          {node.flags[0].name}
                                        </Box>
                                      </Box>
                                    </Box>
                                  ) : null}
                                </Box>
                                {node.isArchived ? (
                                  <Box
                                    style={{
                                      fontStyle: "italic",
                                      fontSize: "0.6875rem",
                                      color: "#DA1505",
                                      marginTop: "0.25rem",
                                    }}
                                  >
                                    {t("thisCustomerHasBeenArchived")}
                                  </Box>
                                ) : null}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box sx={{ whiteSpace: "nowrap" }}>
                                {t(`customerTypeOptions.${node.type}`)}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <AddressDisplay address={node.address} format="long" />
                            </TableCell>
                            <TableCell>
                              <Box sx={{ whiteSpace: "nowrap" }}>{node.email ?? NOT_SPECIFIED}</Box>
                            </TableCell>
                            <TableCell align="right">
                              {canViewDetails ? (
                                <FielderIconButton
                                  aria-label={t("editCustomer") as string}
                                  title={t("editCustomer") as string}
                                >
                                  {hasPermissions?.([DefaultPermission.UpdateCustomer]) ? (
                                    <EditIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </FielderIconButton>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    {loading
                      ? [...Array(customerListSettings.rowsPerPage).keys()].map((i) => (
                          <TableRow key={i} sx={classes.dataRow}>
                            {[...Array(NUM_COLUMNS).keys()].map((j) => (
                              <TableCell key={j}>
                                <Skeleton variant="text" />
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      : null}
                    {error ? (
                      <TableRow>
                        <TableCell align="center" colSpan={NUM_COLUMNS}>
                          <Box
                            style={{
                              color: "rgb(244, 67, 54)",
                              fontWeight: 500,
                              fontSize: "1.25rem",
                              lineHeight: 1.6,
                            }}
                          >
                            {t("errorLabel")}
                            {": "}
                            {error.message}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                ActionsComponent={() => (
                  <TablePaginationActions
                    hasNextPage={pageInfo.hasNextPage ?? false}
                    hasPreviousPage={pageInfo.hasPreviousPage ?? false}
                    onGoToFirstPage={onGoToFirstPage}
                    onGoToLastPage={onGoToLastPage}
                    onGoToNext={onGoToNext}
                    onGoToPrevious={onGoToPrevious}
                  />
                )}
                component="div"
                count={-1}
                labelDisplayedRows={() => ``}
                labelRowsPerPage={t("rowsPerPage") as string}
                onPageChange={() => {}}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={customerListSettings.currentPage}
                rowsPerPage={customerListSettings.rowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              />
            </Paper>
          ) : null}
        </Box>
      </MainLayout>
    </>
  )
}

const classes = {
  tableHead: {
    backgroundColor: "#f9f9f9",
    zIndex: 1000,
    position: "sticky",
    top: 0,
    cursor: "pointer",
  },
  dataRow: {
    height: "3.75rem",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f8f8f8",
    },
  },
} as const

export default CustomerList
