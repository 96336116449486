import React, { useState } from "react"
import Box from "@mui/material/Box"
import { DropzoneState, FileRejection, FileWithPath, useDropzone } from "react-dropzone"
import { useTranslation } from "react-i18next"
import { ONE_MEGABYTE } from "../util"

function getColor({ isDragAccept, isDragReject, isDragActive }: DropzoneState) {
  if (isDragAccept) {
    return "#00e676"
  }
  if (isDragReject) {
    return "#ff1744"
  }
  if (isDragActive) {
    return "#2196f3"
  }
  return "#ccc"
}

interface PendingFile extends FileWithPath {
  preview?: string
}

export interface FileMimeType {
  key: string
  value: string[]
}
interface AcceptedType {
  key: string
  value: string[]
}

export const MP4: AcceptedType = {
  key: "video/mp4",
  value: [".mp4"],
}

export const PNG: AcceptedType = {
  key: "image/png",
  value: [".png"],
}

export const JPEG: AcceptedType = {
  key: "image/jpeg",
  value: [".jpeg", ".jpg"],
}

export const PlainText: AcceptedType = {
  key: "text/plain",
  value: [".txt"],
}

export const CSV: AcceptedType = {
  key: "text/csv",
  value: [".csv"],
}

export const PDF: AcceptedType = {
  key: "application/pdf",
  value: [".pdf"],
}

export const CAD_DWG: AcceptedType = {
  key: "image/vnd.dwg",
  value: [".dwg"],
}

export const CAD_DXF: AcceptedType = {
  key: "image/vnd.dxf",
  value: [".dxf"],
}

export const Excel: AcceptedType = {
  key: "application/vnd.ms-excel",
  value: [".xlsx", ".xlsm"],
}

export const ZIP: AcceptedType = {
  key: "application/zip",
  value: [".zip"],
}

export const WORD_PRE_2007: AcceptedType = {
  key: "application/msword",
  value: [".doc"],
}

export const WORD_POST_2007: AcceptedType = {
  key: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  value: [".docx"],
}

const ALL_ACCEPTED_TYPES = [
  MP4,
  PDF,
  JPEG,
  PNG,
  PlainText,
  CSV,
  CAD_DWG,
  CAD_DXF,
  Excel,
  ZIP,
  WORD_PRE_2007,
  WORD_POST_2007,
]

interface FileSelectorProps {
  readonly allowMultiple?: boolean
  readonly dropZoneLabel?: string
  readonly dropZoneDragActiveLabel?: string
  readonly showFilePolicyMessage?: boolean
  readonly showFileList?: boolean
  readonly showThumbs?: boolean
  readonly onFilesAdded: (files: File[]) => void
  readonly maxSize?: number
  readonly acceptedTypes?: Array<AcceptedType>
}

function FileSelector({
  allowMultiple,
  dropZoneLabel,
  dropZoneDragActiveLabel,
  showFilePolicyMessage,
  showFileList = false,
  showThumbs = true,
  onFilesAdded,
  acceptedTypes = ALL_ACCEPTED_TYPES,
  maxSize,
}: FileSelectorProps) {
  const [files, setFiles] = useState<PendingFile[]>([])
  const maxFileSize = maxSize ? maxSize : 100

  const accept = acceptedTypes?.reduce((acc: any, curr: AcceptedType) => {
    acc[curr.key] = curr.value
    return acc
  }, {})

  const acceptedFileExtensions = acceptedTypes
    .flatMap((t) => t.value)
    .map((e) => e.replace(".", ""))
    .join(", ")

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    minSize: 0,
    maxSize: maxFileSize * ONE_MEGABYTE,
    multiple: typeof allowMultiple === "undefined" ? true : allowMultiple,
    onDropAccepted: (acceptedFiles: Array<File>) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
      setFiles([...files, ...newFiles])
      onFilesAdded(newFiles)
    },
    onDropRejected: (fileRejections: Array<FileRejection>) => {
      console.log("fileRejections: ", fileRejections)
    },
  })

  const { t } = useTranslation()

  function getThumbs() {
    return files
      .filter((file) => {
        return ["image/png", "image/jpeg"].includes(file.type)
      })
      .map((file) => (
        <Box key={file.name} sx={classes.thumb}>
          <Box
            sx={{
              display: "flex",
              minWidth: 0,
              overflow: "hidden",
            }}
          >
            <img
              src={file.preview}
              style={{
                display: "block",
                width: "auto",
                height: "100%",
              }}
            />
          </Box>
        </Box>
      ))
  }

  const megabytesLabel = t("component.fileSelector.megabytes")

  function getFileList() {
    return files.map((file: PendingFile) => {
      const fileSize = Math.round((file.size / ONE_MEGABYTE) * 100) / 100

      return (
        <li key={file.path}>
          <strong>{file.path}</strong> - {fileSize} {megabytesLabel}
        </li>
      )
    })
  }

  const inactiveLabel = dropZoneLabel ?? t("component.fileSelector.dropZoneLabel")
  const activeLabel = dropZoneDragActiveLabel ?? t("component.fileSelector.dropFilesHere")
  const zoneLabel = isDragActive ? activeLabel : inactiveLabel
  const showAcceptMsg = typeof showFilePolicyMessage === "undefined" ? true : showFilePolicyMessage

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        fontFamily: "sans-serif",
      }}
    >
      <Box {...getRootProps({ css: classes.dropzone })}>
        <Box
          sx={{
            fontSize: "1rem",
          }}
        >
          {zoneLabel}
        </Box>
        {showAcceptMsg ? (
          <Box
            sx={{
              fontSize: ".8rem",
              fontStyle: "italic",
              marginTop: "1rem",
            }}
          >
            {t("component.fileSelector.acceptMsg", {
              acceptedFileExtensions,
              maxSize: `${maxFileSize}MB`,
            })}
          </Box>
        ) : null}
        <input {...getInputProps()} />
      </Box>
      {showThumbs ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: "1rem",
          }}
        >
          {getThumbs()}
        </Box>
      ) : null}
      {showFileList ? <ul>{getFileList()}</ul> : null}
    </Box>
  )
}

const classes = {
  dropzone: (props: DropzoneState) => ({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem",
    borderWidth: "2px",
    borderRadius: "4px",
    borderColor: getColor(props),
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#666",
    outline: "none",
    transition: "border .24s ease-in-out",
    boxSizing: "border-box",
    cursor: "pointer",
    lineHeight: 1,
    "&:focus": {
      borderColr: "#2196f3",
    },

    "&:disabled": {
      opacity: 0.6,
    },
  }),
  thumb: {
    display: "inline-flex",
    borderRadius: "2px",
    border: "1px solid #c9c9c9",
    marginBottom: "0.5rem",
    marginRight: "0.5rem",
    maxWidth: "9.375rem",
    width: "auto",
    height: "9.375rem",
    padding: "0.25rem",
    boxSizing: "border-box",
  },
} as const

export default FileSelector
