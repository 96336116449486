/* eslint-disable react/jsx-no-literals */
import React, { useEffect, useState } from "react"
import * as Sentry from "@sentry/react"
import { Formik } from "formik"
import { Navigate, useParams, NavigateProps } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"
import { useMutation } from "@apollo/client"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import FormHelperText from "@mui/material/FormHelperText"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"

import { FielderTextField, HeaderLogo, PoweredByFielder, Seo } from "~/components"
import SnackbarMessage from "~/components/SnackbarMessage"
import { UPDATE_PASSWORD } from "~/queries/updatePassword"
import { VERIFY_PASSWORD_RESET_CODE } from "~/queries/verifyPasswordResetCode"
import { getLandingRoute, parseGraphQLErrorCode } from "~/util"
import { Snack } from "~/types"
import { useAuth } from "~/context/AuthContext"
import { validateForm, isValid } from "~/util/passwordFormValidation"
import { changeLanguage } from "~/i18n"

function VerifyAccount() {
  const { t } = useTranslation()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const { code } = useParams()
  const { login, logout } = useAuth()
  const [snack, setSnack] = useState<Snack>()
  const [username, setUsername] = useState<string>()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [isCodeExpired, setIsCodeExpired] = useState<boolean>(false)
  const [isCodeInvalid, setIsCodeInvalid] = useState<boolean>(false)
  const effectCalled = React.useRef(false)

  const [verifyPasswordResetCode, { loading: verifyLoading }] = useMutation(
    VERIFY_PASSWORD_RESET_CODE,
    {
      onCompleted: (data) => {
        const { verifyPasswordResetCode } = data
        setUsername(verifyPasswordResetCode.username)
        login?.(
          {
            email: verifyPasswordResetCode.username,
            firstName: verifyPasswordResetCode.firstName,
            lastName: verifyPasswordResetCode.lastName,
          },
          verifyPasswordResetCode.authToken
        )
        changeLanguage(verifyPasswordResetCode.languageCode)
      },
      onError: (error) => {
        const errorCode = parseGraphQLErrorCode(error)
        let reportToSentry = true

        if (errorCode.includes("password.reset-code.invalid")) {
          setIsCodeInvalid(true)
          reportToSentry = false
        } else if (errorCode.includes("password.reset-code.expired")) {
          setIsCodeExpired(true)
          reportToSentry = false
        }

        if (reportToSentry) {
          Sentry.captureException(error)
        }
      },
    }
  )

  const [updatePassword, { loading, called }] = useMutation(UPDATE_PASSWORD, {
    onCompleted: (data) => {
      const { updatePassword } = data
      login?.(updatePassword.user, updatePassword.authToken)
      setRedirectTo({
        to: getLandingRoute(updatePassword.user),
        replace: true,
      })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  useEffect(() => {
    if (effectCalled.current) {
      return
    }

    if (logout && code && verifyPasswordResetCode) {
      effectCalled.current = true
      logout()
      verifyPasswordResetCode({ variables: { code } })
    }
  }, [logout, code, verifyPasswordResetCode])

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t("page.activateAccount.title")} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Box
        component="main"
        sx={{
          height: "100vh",
          backgroundColor: (theme) => theme.fielderScreens.login.backgroundColor,
        }}
      >
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validate={validateForm}
        >
          {({ values, errors, touched, handleBlur, handleChange }) => (
            <form data-testid="activateAccountForm">
              <Grid
                container
                sx={{
                  width: "100%",
                  maxWidth: "400px",
                  minWidth: "240px",
                  margin: "0 auto",
                  paddingTop: "10%",
                  background: (theme) => theme.fielderScreens.login.backgroundColor,
                }}
              >
                <Grid
                  data-testid="logo"
                  item
                  sx={{
                    margin: "auto 0.625rem",
                  }}
                  xs={12}
                >
                  <HeaderLogo width="100%" />
                </Grid>
                <Grid
                  item
                  sx={(theme) => ({
                    margin: "auto 0.625rem",
                    padding: "1.375rem",
                    paddingTop: "0.625rem",
                    paddingBottom: "2rem",
                    backgroundColor: "#fff",
                    [theme.breakpoints.down("xs")]: {},
                    border: `1px solid ${theme.fielderScreens.login.backgroundColor}`,
                    borderRadius: "4px",
                  })}
                  xs={12}
                >
                  {verifyLoading ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingBottom: "12.5rem",
                      }}
                    >
                      <h1
                        id="title"
                        style={{
                          margin: 0,
                        }}
                      >
                        {t("page.activateAccount.verifyingCode")}
                      </h1>
                      <CircularProgress />
                    </Box>
                  ) : isCodeExpired ? (
                    <Box>
                      <h1
                        id="title"
                        style={{
                          fontSize: "1.7em",
                          marginBottom: "0.625rem",
                        }}
                      >
                        {t("page.activateAccount.verificationCodeExpired.title")}{" "}
                      </h1>
                      <p id="invalidCodeMessageBody" style={{ lineHeight: "1.7" }}>
                        {t("page.activateAccount.verificationCodeExpired.message")}
                      </p>
                    </Box>
                  ) : isCodeInvalid ? (
                    <Box>
                      <h1
                        id="title"
                        style={{
                          fontSize: "1.7em",
                          marginBottom: "0.625rem",
                        }}
                      >
                        {t("page.activateAccount.verificationCodeInvalid.title")}{" "}
                      </h1>
                      <p id="invalidCodeMessageBody" style={{ lineHeight: "1.7" }}>
                        <Trans
                          components={{
                            login: <a href="/app/login" />,
                            email: <a href="mailto:support@fielderapp.com" />,
                          }}
                          i18nKey="page.activateAccount.verificationCodeInvalid.message"
                        />
                      </p>
                    </Box>
                  ) : (
                    <>
                      <h1
                        id="title"
                        style={{
                          margin: 0,
                        }}
                      >
                        {t("page.activateAccount.title")}
                      </h1>
                      <Box
                        data-testid="messageBody"
                        sx={{
                          marginBottom: "0.625rem",
                          marginTop: "0.625rem",
                        }}
                      >
                        {t("page.activateAccount.prompt")}
                        <ul
                          style={{
                            fontSize: "0.95em",
                            listStyle: "none",
                            paddingLeft: "0.75rem",
                          }}
                        >
                          {["length", "number", "lowercase", "uppercase", "special"].map(
                            (c: string) => (
                              <li
                                key={c}
                                style={{
                                  color: !touched.password
                                    ? "#747474"
                                    : errors.password && !errors.password[c]
                                      ? "green"
                                      : "red",
                                }}
                              >
                                <div>
                                  {errors.password && !errors.password[c] ? (
                                    <span
                                      style={{
                                        position: "absolute",
                                      }}
                                    >
                                      &#10003;
                                    </span>
                                  ) : null}
                                  {touched.password && errors.password && errors.password[c] ? (
                                    <span
                                      style={{
                                        position: "absolute",
                                      }}
                                    >
                                      &#10007;
                                    </span>
                                  ) : null}
                                  <span
                                    style={{
                                      marginLeft: "0.75rem",
                                    }}
                                  >
                                    {t(`page.activateAccount.criteria.${c}`)}
                                  </span>
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                      </Box>
                      <Box sx={classes.fieldContainer}>
                        <FielderTextField
                          InputProps={{
                            endAdornment: (
                              <InputAdornment data-testid="togglePasswordVisibility" position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setShowPassword((val) => !val)
                                  }}
                                  tabIndex={-1}
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          autoComplete="off"
                          autoFocus
                          data-testid="password"
                          fullWidth
                          id="password"
                          inputProps={{ maxLength: 255 }}
                          label={t("page.activateAccount.newPassword")}
                          margin="normal"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          sx={{
                            backgroundColor: "#fff",
                            "& label.Mui-focused": {
                              color: "#000",
                            },
                          }}
                          type={showPassword ? "text" : "password"}
                          value={values.password}
                        />
                      </Box>
                      <Box sx={classes.fieldContainer}>
                        <FielderTextField
                          autoComplete="off"
                          data-testid="confirmPassword"
                          fullWidth
                          id="confirmPassword"
                          inputProps={{ maxLength: 255 }}
                          label={t("page.activateAccount.confirmPassword")}
                          margin="normal"
                          name="confirmPassword"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          sx={{
                            backgroundColor: "#fff",
                            "& label.Mui-focused": {
                              color: "#000",
                            },
                          }}
                          type={showPassword ? "text" : "password"}
                          value={values.confirmPassword}
                        />
                        {errors.confirmPassword && touched.confirmPassword ? (
                          <FormHelperText error id="confirmPasswordError">
                            {t(errors.confirmPassword)}
                          </FormHelperText>
                        ) : null}
                      </Box>
                      <Button
                        color="primary"
                        data-testid="submitButton"
                        disabled={
                          !(values.password && values.confirmPassword) ||
                          !isValid(errors) ||
                          loading
                        }
                        fullWidth
                        id="submitButton"
                        onClick={() => {
                          updatePassword({
                            variables: { username, password: values.password },
                          })
                        }}
                        sx={{
                          marginTop: "1.25rem",
                          backgroundColor: (theme) => theme.palette.primary.main,
                          height: "50px",
                        }}
                        variant="contained"
                      >
                        {called && loading ? (
                          <CircularProgress size={20} thickness={6.0} />
                        ) : (
                          <span>{t("page.activateAccount.submitButton")}</span>
                        )}
                      </Button>
                    </>
                  )}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "2.5rem",
                      marginBottom: "-0.625rem",
                      color: "#747474",
                      "& a": {
                        color: "#747474",
                      },
                    }}
                  >
                    <a href="/terms" target="_blank">
                      {t("termsOfService", "Terms of Service")}
                    </a>
                    <a href="/privacy" target="_blank">
                      {t("privacyPolicy", "Privacy Policy")}
                    </a>
                  </Box>
                </Grid>
              </Grid>
              <PoweredByFielder />
            </form>
          )}
        </Formik>
      </Box>
    </>
  )
}

const classes = {
  fieldContainer: {
    marginTop: "1.25rem",
  },
} as const

export default VerifyAccount
