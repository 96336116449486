import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { gql, useQuery, useMutation } from "@apollo/client"
import Box from "@mui/material/Box"
import Alert from "@mui/material/Alert"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import CircularProgress from "@mui/material/CircularProgress"
import EditIcon from "@mui/icons-material/EditOutlined"

import { GET_MY_PROFILE } from "~/queries/getMyProfile"
import { EDIT_MY_PROFILE } from "~/queries/editMyProfile"
import { UPDATE_PASSWORD } from "~/queries/updatePassword"
import { parseGraphQLErrorCode, SETTINGS } from "~/util"
import { MainLayout, PageHeader, Seo, SnackbarMessage } from "~/components"
import MyProfileBasicInfoForm from "./components/MyProfileBasicInfoForm"
import MyProfileBasicInfoStatic from "./components/MyProfileBasicInfoStatic"
import ChangePasswordForm from "~/components/ChangePasswordForm"
import {
  GetMyProfileData,
  MyProfileFormFields,
  EditMyProfileResponse,
  Snack,
  NotificationCategory,
  NotificationSetting,
  NotificationType,
  EditNotificationSettingInput,
  EditNotificationSettingResponse,
} from "~/types"
import FielderIconButton from "~/components/FielderIconButton"
import SectionHeader from "~/components/SectionHeader"
import SectionContent from "~/components/SectionContent"
import CategorySection from "./components/notifications/CategorySection"
import { changeLanguage } from "~/i18n"
import { useAuth } from "~/context/AuthContext"
import UserAvatarManager from "~/components/UserAvatarManager"

const EDIT_NOTIFICATION_SETTING = gql`
  mutation EditNotificationSetting(
    $notificationTypeId: ID!
    $emailEnabled: Boolean!
    $smsEnabled: Boolean!
    $inboxEnabled: Boolean!
  ) {
    editNotificationSetting(
      input: {
        notificationTypeId: $notificationTypeId
        emailEnabled: $emailEnabled
        smsEnabled: $smsEnabled
        inboxEnabled: $inboxEnabled
      }
    ) {
      notificationSetting {
        id
        notificationType {
          id
          notificationCategory
          name
        }
        emailEnabled
        smsEnabled
        inboxEnabled
      }
    }
  }
`

interface NotificationTypesByCategory {
  category: NotificationCategory
  notificationTypes: NotificationType[]
}

/**
 * This component is used for both creating new and editing existing Customers,
 * since the design is similar enough in either case.
 */
function MyProfile() {
  const { t } = useTranslation()
  const { user, setUser } = useAuth()
  const [inEditMode, setInEditMode] = useState<boolean>(false)
  const [inChangePasswordMode, setInChangePasswordMode] = useState<boolean>(false)
  const [notificationTypesByCategory, setNotificationTypesByCategory] = useState<
    NotificationTypesByCategory[]
  >([])
  const [notificationSettings, setNotificationSettings] = useState<NotificationSetting[]>([])
  const [snack, setSnack] = useState<Snack>()

  const [editMyProfile, { loading: editLoading }] = useMutation<
    EditMyProfileResponse,
    MyProfileFormFields
  >(EDIT_MY_PROFILE, {
    onCompleted: (data) => {
      setSnack({
        messageKey: "messages.changesSaved",
        variant: "success",
      })
      setInEditMode(false)
      changeLanguage(data.editMyProfile.user.languageCode)
      const updatedUser = data.editMyProfile.user
      setUser?.({
        ...user,
        email: updatedUser.email,
        firstName: updatedUser.firstName,
        lastName: updatedUser.lastName,
        jobTitle: updatedUser.jobTitle,
        mobilePhoneNumber: updatedUser.mobilePhoneNumber,
        status: updatedUser.status,
        languageCode: updatedUser.languageCode,
        lastLogin: updatedUser.lastLogin,
        avatar: updatedUser.avatar,
      })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({
        messageKey: errorCode,
        variant: "error",
      })
    },
  })

  const [editNotificationSetting] = useMutation<
    EditNotificationSettingResponse,
    EditNotificationSettingInput
  >(EDIT_NOTIFICATION_SETTING, {
    onCompleted: () => {
      setSnack({
        messageKey: "messages.changesSaved",
        variant: "success",
      })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({
        messageKey: errorCode,
        variant: "error",
      })
    },
  })

  const [updatePassword, { loading: changePasswordLoading }] = useMutation(UPDATE_PASSWORD, {
    onCompleted: () => {
      setSnack({
        messageKey: "messages.changesSaved",
        variant: "success",
      })
      setInEditMode(false)
      setInChangePasswordMode(false)
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({
        messageKey: errorCode,
        variant: "error",
      })
    },
  })

  const {
    data: myProfileData,
    error: myProfileError,
    loading: myProfileLoading,
  } = useQuery<GetMyProfileData>(GET_MY_PROFILE, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const userProfile = data?.getMyProfile
      const allAvailableCatagories =
        userProfile?.availableNotificationTypes?.map((t) => t.notificationCategory) ?? []
      const availableCatagoriesSet = [...new Set(allAvailableCatagories)]
      const groupedNotificationTypesByCategories = availableCatagoriesSet?.map((category) => {
        return {
          category,
          notificationTypes: userProfile?.availableNotificationTypes?.filter(
            (t) => t.notificationCategory === category
          ),
        }
      }) as NotificationTypesByCategory[]
      setNotificationTypesByCategory(groupedNotificationTypesByCategories)

      setNotificationSettings(
        userProfile?.notificationSettings
          ? userProfile.notificationSettings.map((s) => {
              return {
                ...s,
              }
            })
          : []
      )
    },
  })

  function handleSaveProfile(userProfile: MyProfileFormFields): void {
    const payload = { variables: { ...userProfile } }
    editMyProfile(payload)
  }

  function handleCancelEdit() {
    setInEditMode(false)
  }

  function handleChangePassword(password: string): void {
    if (userProfile) {
      updatePassword({
        variables: { username: userProfile.email, password },
      })
    }
  }

  function handleCancelChangePassword() {
    setInChangePasswordMode(false)
  }

  const userProfile = myProfileData?.getMyProfile
  const loading = myProfileLoading || changePasswordLoading

  return (
    <>
      <Seo title={t(SETTINGS.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={SETTINGS}>
        <Box sx={{ margin: "0 1.25rem" }}>
          <PageHeader
            breadcrumbs={[{ to: SETTINGS.path, titleKey: SETTINGS.titleKey }]}
            icon={SETTINGS.icon}
            leafTitleKey="myProfile"
          />
          <Box
            sx={{
              width: "100%",
              flex: 1,
              marginBottom: "4rem",
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            {myProfileError ? (
              <Alert severity="error">
                {`${t("error.general.title")} ${t("error.general.message")}`}
              </Alert>
            ) : null}
            {myProfileLoading ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10%",
                }}
              >
                <CircularProgress color="secondary" size={40} thickness={6.0} />
              </Box>
            ) : (
              <>
                <Paper sx={{ width: "100%", maxWidth: "52rem" }}>
                  <SectionHeader>
                    <label>{t("basicInfo")}</label>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      {!inEditMode ? (
                        <FielderIconButton
                          aria-label={t("editItem") as string}
                          data-testid="editItemButton"
                          disabled={loading}
                          onClick={() => {
                            setInEditMode(true)
                          }}
                          sx={(theme) => ({
                            marginLeft: "1.875rem",
                            [theme.breakpoints.down("xs")]: {
                              marginLeft: 0,
                            },
                          })}
                          title={t("editItem")}
                        >
                          <EditIcon />
                        </FielderIconButton>
                      ) : null}
                    </Box>
                  </SectionHeader>
                  <Divider />
                  <SectionContent>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: "2rem" }}>
                      <Box
                        sx={(theme) => {
                          return {
                            display: "flex",
                            margin: 0,
                            padding: 0,
                            fontSize: "0.875rem",
                            [theme.breakpoints.up("sm")]: {},
                            [theme.breakpoints.up("md")]: {
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              maxWidth: "200px",
                            },
                          }
                        }}
                      >
                        <UserAvatarManager avatar={user?.avatar} userId={user?.id} />
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "column", flex: "1" }}>
                        {inChangePasswordMode ? (
                          <ChangePasswordForm
                            loading={changePasswordLoading}
                            onCancel={handleCancelChangePassword}
                            onSave={handleChangePassword}
                          />
                        ) : (
                          <>
                            {!inEditMode && userProfile ? (
                              <Box
                                sx={{
                                  padding: "0 1rem 0.5rem 1rem",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <MyProfileBasicInfoStatic
                                  onClickChangePassword={() => {
                                    setInChangePasswordMode(true)
                                  }}
                                  userProfile={userProfile}
                                />
                              </Box>
                            ) : null}
                            {inEditMode && userProfile ? (
                              <Box
                                sx={{
                                  padding: "0 1rem 0.5rem 1rem",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <MyProfileBasicInfoForm
                                  loading={editLoading}
                                  onCancel={handleCancelEdit}
                                  onSave={handleSaveProfile}
                                  userProfile={userProfile}
                                />
                              </Box>
                            ) : null}
                          </>
                        )}
                      </Box>
                    </Box>
                  </SectionContent>
                </Paper>
                <Paper sx={{ width: "100%", maxWidth: "52rem" }}>
                  <SectionHeader>
                    <label>{t("component.notificationSettings.title")}</label>
                  </SectionHeader>
                  <Divider />
                  <SectionContent>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(12, 1fr)",
                      }}
                    >
                      {notificationTypesByCategory.map((c) => (
                        <CategorySection
                          category={c.category}
                          key={c.category}
                          notificationSettings={notificationSettings}
                          notificationTypes={c.notificationTypes}
                          onChangeSetting={(input: EditNotificationSettingInput) => {
                            editNotificationSetting({ variables: { ...input } })
                            let idx = notificationSettings.findIndex(
                              (s) => s.notificationType.id === input.notificationTypeId
                            )

                            const currentSetting = notificationSettings[idx] ?? {
                              notificationType: {
                                id: input.notificationTypeId,
                                name: input.notificationTypeId,
                              },
                            }

                            const updatedSetting = {
                              ...currentSetting,
                              emailEnabled: input.emailEnabled,
                              smsEnabled: input.smsEnabled,
                              inboxEnabled: input.inboxEnabled,
                            }

                            if (idx < 0) {
                              // The setting was not found, so we'll append to the end of the list
                              // instead of trying to replace an existing entry
                              idx = notificationSettings.length + 1
                            }

                            notificationSettings.splice(idx, 1, updatedSetting)
                            setNotificationSettings(notificationSettings.concat([]))
                          }}
                        />
                      ))}
                    </Box>
                  </SectionContent>
                </Paper>
              </>
            )}
          </Box>
        </Box>
      </MainLayout>
    </>
  )
}

export default MyProfile
