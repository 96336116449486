import React from "react"
import { Box } from "@mui/material"

import { ItemType, OrganizationItem, OrganizationPlugin } from "~/types"
import BundleForm from "./BundleForm"
import ProductForm from "./ProductForm"
import ServiceForm from "./ServiceForm"
import { ItemFormProvider } from "./ItemFormContext"

interface Props {
  readonly accountingPlugin?: OrganizationPlugin
  readonly currencyCode: string
  readonly item: OrganizationItem
  readonly isLoading: boolean
  readonly onCancel: () => void
  readonly onSave: (payload: any) => void
  readonly onImageUpload: () => void
}

function ItemForm({
  accountingPlugin,
  currencyCode,
  item,
  isLoading,
  onCancel,
  onSave,
  onImageUpload,
}: Props) {
  return (
    <ItemFormProvider organizationItem={item}>
      {item.type === ItemType.BUNDLE ? (
        <BundleForm
          currencyCode={currencyCode}
          loading={isLoading}
          onCancel={onCancel}
          onImageUpload={onImageUpload}
          onSave={onSave}
        />
      ) : item.type === ItemType.PRODUCT ? (
        <ProductForm
          accountingPlugin={accountingPlugin}
          currencyCode={currencyCode}
          loading={isLoading}
          onCancel={onCancel}
          onImageUpload={onImageUpload}
          onSave={onSave}
        />
      ) : item.type === ItemType.SERVICE ? (
        <ServiceForm
          currencyCode={currencyCode}
          loading={isLoading}
          onCancel={onCancel}
          onImageUpload={onImageUpload}
          onSave={onSave}
        />
      ) : (
        <Box>Invalid item type: {item?.type}</Box>
      )}
    </ItemFormProvider>
  )
}

export default ItemForm
