import React from "react"
import { useTranslation } from "react-i18next"
import TextField from "@mui/material/TextField"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import WarningIcon from "@mui/icons-material/Warning"
import ImageIcon from "@mui/icons-material/Image"
import Box from "@mui/material/Box"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"

import { formatMoney } from "~/util"
import PositiveIntegerInput from "./PositiveIntegerInput"
import OrganizationItemSelect from "./OrganizationItemSelect"
import MoneyInput from "./MoneyInput"
import {
  ProductOrderLineItem as IProductOrderLineItem,
  ProductOrder,
  TransactionLineItemFormInput,
} from "~/types"
import { useAuth } from "~/context/AuthContext"
import FielderIconButton from "./FielderIconButton"

interface Props {
  readonly currencyCode: string
  readonly isEditable?: boolean
  readonly productOrder?: ProductOrder
  readonly lineItem: IProductOrderLineItem | TransactionLineItemFormInput
  readonly onChange: (lineItem: IProductOrderLineItem | TransactionLineItemFormInput) => void
  readonly onDelete: () => void
}

function ProductOrderLineItem({
  currencyCode,
  isEditable,
  productOrder,
  lineItem,
  onChange,
  onDelete,
}: Props) {
  const { t } = useTranslation()
  const { user } = useAuth()

  const isLevel1User = user?.organization?.level === 1

  const quantityReceived = lineItem.deliveryLineItems
    ?.map((d) => d.quantity)
    .reduce((acc, curr) => {
      return acc + curr
    }, 0)

  return (
    <TableRow>
      <TableCell sx={classes.tableCell}>
        {lineItem.id ? (
          <Box sx={classes.itemInfoContainer}>
            <Box sx={classes.itemImageContainer}>
              {lineItem.organizationItem?.attachments?.[0] ? (
                <img src={lineItem.organizationItem.attachments?.[0]?.signedUrl} />
              ) : (
                <ImageIcon sx={classes.itemImagePlaceholderIcon} />
              )}
            </Box>
            <Box sx={classes.itemTextInfoContainer}>
              <Box
                sx={{
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                }}
              >
                {lineItem.organizationItem?.code}
              </Box>
              <Box>{lineItem.organizationItem?.name}</Box>
              {lineItem.organizationItem?.packSize > 1 &&
              !lineItem.organizationItem?.isPackBreakable ? (
                <Box sx={{ fontSize: "0.75rem" }}>
                  <Box component="span" sx={{ fontWeight: "600" }}>
                    Pack size:
                  </Box>{" "}
                  {lineItem.organizationItem?.packSize}
                </Box>
              ) : null}
              {lineItem.organizationItem?.isCancelable === false ? (
                <Box
                  sx={{
                    marginRight: "0.5rem",
                    marginBottom: "0.25rem",
                    marginTop: "0.5rem",
                    borderRadius: "4px",
                    padding: "0.125rem",
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                    backgroundColor: "hsla(38, 97%, 96%, 1)",
                    color: "hsla(38, 100%, 43%, 1)",
                    fontWeight: 600,
                    fontSize: "0.7rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <WarningIcon
                    sx={{
                      fontSize: "0.9375rem",
                      color: "hsla(38, 100%, 43%, 1)",
                    }}
                  />{" "}
                  {t("itemOrdersNotCancelable")}
                </Box>
              ) : null}
            </Box>
          </Box>
        ) : (
          <Box>
            <OrganizationItemSelect
              error={lineItem.errors?.organizationItemId}
              includeInactiveItems={isLevel1User}
              onChange={(value, reason) => {
                if (reason === "selectOption") {
                  onChange({
                    ...lineItem,
                    organizationItem: value,
                    organizationItemId: value?.id,
                    quantity: value?.reorderQty ?? value?.palletQty ?? "",
                    unitPrice: value?.wholesalePrice,
                  } as TransactionLineItemFormInput)
                } else if (reason === "clear") {
                  onChange({
                    ...lineItem,
                    organizationItem: undefined,
                    organizationItemId: undefined,
                    unitPrice: undefined,
                  } as TransactionLineItemFormInput)
                }
              }}
              onlyAvailableItems={!isLevel1User}
              organizationId={isLevel1User ? productOrder?.organization?.id : undefined}
              showQtyOnHand={!isLevel1User}
              style={{
                margin: 0,
                fontSize: "0.9rem",
                minWidth: "250px",
              }}
              value={lineItem.organizationItem}
            />
          </Box>
        )}
      </TableCell>
      <TableCell align="right" sx={classes.tableCell}>
        {isEditable && isLevel1User ? (
          <MoneyInput
            aria-label={t("unitPrice")}
            currencyCode={currencyCode}
            customInput={TextField}
            error={lineItem.errors?.showErrors ? Boolean(lineItem.errors?.unitPrice) : null}
            fullWidth={false}
            max={999999999999}
            min={0}
            onChange={(e) =>
              onChange({
                ...lineItem,
                unitPrice: e.target.value,
              })
            }
            placeholder="0"
            required
            size="small"
            sx={{ minWidth: "100px", margin: 0, width: "100%" }}
            value={lineItem.unitPrice}
            variant="outlined"
          />
        ) : (
          formatMoney(currencyCode, lineItem.unitPrice)
        )}
        {isEditable &&
        lineItem.organizationItem?.packSize > 1 &&
        !lineItem.organizationItem?.isPackBreakable ? (
          <Box sx={{ fontSize: "0.625rem", color: "transparent" }}>placeholder</Box>
        ) : null}
      </TableCell>
      <TableCell align="right" sx={classes.tableCell}>
        {isEditable ? (
          <PositiveIntegerInput
            aria-label={t("quantity")}
            customInput={TextField}
            error={lineItem.errors?.showErrors ? Boolean(true) : null}
            fullWidth={false}
            inputProps={{ min: 0, minLength: 16, maxLength: 24, style: { textAlign: "right" } }}
            onBlur={(e) => {
              let qty = Number(lineItem.quantity)
              const packSize = Number(lineItem.organizationItem?.packSize ?? 1)
              if (
                packSize > 1 &&
                !lineItem.organizationItem?.isPackBreakable &&
                qty % packSize !== 0
              ) {
                // round up qty to the nearest multiple of packSize
                qty = Math.ceil(qty / packSize) * packSize
                onChange({
                  ...lineItem,
                  quantity: qty,
                })
              }
            }}
            onChange={(e) => {
              onChange({
                ...lineItem,
                quantity: e.target.value,
              })
            }}
            placeholder="0"
            required
            size="small"
            value={lineItem.quantity}
            variant="outlined"
          />
        ) : (
          lineItem.quantity
        )}
        {isEditable &&
        lineItem.organizationItem?.packSize > 1 &&
        !lineItem.organizationItem?.isPackBreakable ? (
          <Box sx={{ fontSize: "0.625rem", color: (theme) => theme.fielderColors.mutedText }}>
            {t("component.productOrderLineItemTableRow.mustBeMultipleOfPackSize")}
          </Box>
        ) : null}
      </TableCell>
      <TableCell align="right" sx={classes.tableCell}>
        {quantityReceived}
      </TableCell>
      <TableCell align="right" sx={classes.tableCell}>
        {formatMoney(currencyCode, lineItem.total)}
      </TableCell>
      {isEditable ? (
        <TableCell align="right" sx={classes.tableCell}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <FielderIconButton onClick={onDelete}>
              <DeleteIcon />
            </FielderIconButton>
          </Box>
        </TableCell>
      ) : null}
    </TableRow>
  )
}

const classes = {
  tableCell: {
    padding: "0.5rem",
  },
  itemInfoContainer: {
    display: "flex",
    flexDirection: "row",
  },
  itemImageContainer: {
    marginRight: "0.625rem",
    width: "3.75rem",
    height: "3.75rem",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    objectFit: "cover",
    "& img": {
      width: "auto",
      height: "auto",
    },
  },
  itemImagePlaceholderIcon: {
    fontSize: "3.75rem",
    maxWidth: "60px",
    color: "#747474",
  },
  itemTextInfoContainer: {
    marginTop: "0.5rem",
  },
}

export default ProductOrderLineItem
