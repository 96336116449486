import React from "react"
import Box from "@mui/material/Box"

interface Props {
  readonly label: string
  readonly description?: string
  readonly style?: any
}

function RadioOption({ label, description, style }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "25rem",
        marginTop: "0.75rem",
        ...style,
      }}
    >
      <Box
        sx={{
          marginLeft: "0.75rem",
          fontSize: "0.875rem",
          fontWeight: "bold",
        }}
      >
        {label}
      </Box>
      {description ? (
        <Box
          sx={{
            marginLeft: "0.75rem",
            fontSize: "0.75rem",
          }}
        >
          {description}
        </Box>
      ) : null}
    </Box>
  )
}

export default RadioOption
