import gql from "graphql-tag"

export const DELETE_JOB_NOTE = gql`
  mutation DeleteJobNote($id: ID!) {
    deleteJobNote(id: $id) {
      jobNote {
        id
        text
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`

