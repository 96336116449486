/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useState } from "react"
import Cookies from "js-cookie"
import { useTranslation } from "react-i18next"
import { useDropzone } from "react-dropzone"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import PersonIcon from "@mui/icons-material/Person"
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { ONE_MEGABYTE } from "../util"
import { Attachment, DefaultPermission } from "../types"
import { useAuth } from "../context/AuthContext"

/**
 * This component can handle uploading an avatar image for users.
 */

interface UserAvatarManagerProps {
  readonly userId?: string | null
  readonly avatar?: Attachment | null
  readonly disabled?: boolean
  readonly onUploadComplete?: (avatar: Attachment | null) => void
}

function UserAvatarManager({ userId, avatar, disabled, onUploadComplete }: UserAvatarManagerProps) {
  const { t } = useTranslation()
  const { user: currentUser, hasPermissions, setUser } = useAuth()

  const [isFileUploading, setIsFileUploading] = useState<boolean>(false)
  const [imageObject, setImageObject] = useState<Attachment | null | undefined>(avatar)
  const isEditable =
    !disabled &&
    ((currentUser && currentUser.id === userId) || hasPermissions?.([DefaultPermission.UpdateUser]))

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
      "image/webp": [".webp"],
    },
    disabled: Boolean(disabled),
    minSize: 0,
    maxSize: ONE_MEGABYTE * 5,
    multiple: false,
    onDrop: (acceptedFiles) => {
      const newImage = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )?.[0]
      uploadFile(newImage)
    },
  })

  const uploadFile = async (file: File) => {
    if (!userId) {
      return
    }
    setIsFileUploading(true)
    const data = new FormData()
    data.append("file", file)
    return fetch(`${process.env.GATSBY_API_SERVER_URL}/attachment/user/${userId}/avatar`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`,
      },
      body: data,
    })
      .then((response) => response.json())
      .then((avatarJson) => {
        setImageObject(avatarJson.attachment)
        if (currentUser?.id === userId) {
          setUser?.({
            ...currentUser,
            avatar: avatarJson.attachment,
          })
        }
        onUploadComplete?.(avatarJson.attachment)
      })
      .catch((err) => {
        console.error("ERROR: ", err)
      })
      .finally(() => {
        setIsFileUploading(false)
      })
  }

  function renderImage() {
    if (!userId) {
      return (
        <Box sx={classes.imagePlaceholder}>
          <PersonIcon sx={classes.imagePlaceholderIcon} />
        </Box>
      )
    } else if (isDragActive) {
      return <span>{t("component.fileSelector.dropFilesHere")}</span>
    } else if (isFileUploading) {
      return (
        <Box
          sx={{
            width: "8rem",
            height: "8rem",
            borderRadius: "50%",
            backgroundColor: "#efefef",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )
    } else if (imageObject?.signedUrl) {
      return (
        <img
          src={imageObject.signedUrl}
          style={{
            width: "8rem",
            height: "8rem",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      )
    } else {
      if (isEditable) {
        return (
          <Box sx={classes.imagePlaceholder}>
            <AddAPhotoIcon sx={classes.imagePlaceholderIcon} />
          </Box>
        )
      } else {
        return <AccountCircleIcon sx={{ width: "8rem", height: "8rem", color: "#999" }} />
      }
    }
  }

  return (
    <>
      {/* Can't upload an image without a user ID */}
      {userId ? (
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            [theme.breakpoints.up("md")]: {
              maxWidth: "200px",
            },
          })}
        >
          <div
            {...getRootProps({
              css: [classes.dropzone, { cursor: "pointer" }],
            })}
            id="image-container"
          >
            <Box
              sx={{
                fontSize: "1rem",
                marginBottom: "1rem",
              }}
            >
              {renderImage()}
            </Box>
            {isEditable ? (
              <>
                <Box
                  sx={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {avatar ? t("changeImage") : t("addImage")}
                </Box>
                <input {...getInputProps()} />
              </>
            ) : null}
          </div>
        </Box>
      ) : null}
    </>
  )
}

const classes = {
  dropzone: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  imagePlaceholder: {
    width: "8rem",
    height: "8rem",
    borderRadius: "50%",
    backgroundColor: "#efefef",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#333",
  },
  imagePlaceholderIcon: {
    fontSize: "5rem",
    color: "#747474",
  },
} as const

export default UserAvatarManager
